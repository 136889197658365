import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { Title } from '../../atoms/Title';
import { Button } from '../../atoms/Button';
import { Url } from '../../../constants/Url';
import { TITLE } from '../../../constants/Title';
import { Alert } from '../../atoms/Alert';
import { FloatingRewardUploadConfirmTable } from '../../organisms/Table/FloatingRewardUploadConfirmTable';
import {
  FloatingRewardFileCheckOutputResponse,
  FloatingRewardUploadApi,
  IncResultOutputResponse,
} from '../../../api-client';

export const FloatingRewardUploadConfirmPage: React.VFC = () => {
  const location = useLocation<{ uploadResult: FloatingRewardFileCheckOutputResponse }>();
  const history = useHistory();
  const { uploadResult } = location.state;

  const [isSaving, setIsSaving] = useState(false);

  const uploadApi = new FloatingRewardUploadApi();

  const handleSave = (): void => {
    setIsSaving(false);
    uploadApi
      .floatingRewardUpload({ list: uploadResult.list })
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        setIsSaving(true);
      })
      .catch(() => {
        history.push(Url.COMMON_ERROR);
      });
  };

  return (
    <>
      <Title className="mb-4">{TITLE.KEISAI.FLOATING_REWARD_FILE_UPLOAD}</Title>
      {isSaving && <Alert variant="success">アップロードが完了しました。</Alert>}
      {!uploadResult?.result && (
        <Alert variant="danger">
          <ul>
            {uploadResult.resultMessageList.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        </Alert>
      )}

      <div className="d-flex justify-content-end mb-4">
        <Button variant="link" onClick={() => history.push(Url.KEISAI.CLIENT_OF_REQUIRED_NUMBER)}>
          戻る
        </Button>
        <Button onClick={handleSave} disabled={!uploadResult.result || isSaving}>
          保存
        </Button>
      </div>
      <FloatingRewardUploadConfirmTable list={uploadResult.list} />
    </>
  );
};
