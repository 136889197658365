import * as React from 'react';
import {
  MonitorRuleSetInfoMonitorRuleOutputResponse,
  MonitorRuleSettingAttributeDtoResponse,
} from '../../../api-client';
import sass from '../../../scss/organisms/calendarTable.module.scss';
import { DayOfWeek } from '../../../Constants';

export const CalenderTable = ({
  monitorRuleSettingAttribute,
}: {
  monitorRuleSettingAttribute?: MonitorRuleSettingAttributeDtoResponse | MonitorRuleSetInfoMonitorRuleOutputResponse;
}) => {
  const isAllDayVisit = () => {
    return (
      monitorRuleSettingAttribute?.mondayVisitsFlg &&
      monitorRuleSettingAttribute?.tuesdayVisitsFlg &&
      monitorRuleSettingAttribute?.wednesdayVisitsFlg &&
      monitorRuleSettingAttribute?.thursdayVisitsFlg &&
      monitorRuleSettingAttribute?.fridayVisitsFlg &&
      monitorRuleSettingAttribute?.saturdayVisitsFlg &&
      monitorRuleSettingAttribute?.sundayVisitsFlg &&
      monitorRuleSettingAttribute?.publicHolidayVisitsFlg
    );
  };
  return (
    <>
      <table className={sass.calender_table}>
        <thead>
          <tr>
            {DayOfWeek.map((day, index) => {
              if (
                (typeof monitorRuleSettingAttribute?.publicHolidayVisitsFlg === 'boolean' && !isAllDayVisit()) ||
                day !== '祝'
              ) {
                return <th key={`${day}_${index}`}>{day}</th>;
              }
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{monitorRuleSettingAttribute?.mondayVisitsFlg ? '○' : '×'}</td>
            <td>{monitorRuleSettingAttribute?.tuesdayVisitsFlg ? '○' : '×'}</td>
            <td>{monitorRuleSettingAttribute?.wednesdayVisitsFlg ? '○' : '×'}</td>
            <td>{monitorRuleSettingAttribute?.thursdayVisitsFlg ? '○' : '×'}</td>
            <td>{monitorRuleSettingAttribute?.fridayVisitsFlg ? '○' : '×'}</td>
            <td>{monitorRuleSettingAttribute?.saturdayVisitsFlg ? '○' : '×'}</td>
            <td>{monitorRuleSettingAttribute?.sundayVisitsFlg ? '○' : '×'}</td>
            {typeof monitorRuleSettingAttribute?.publicHolidayVisitsFlg === 'boolean' && !isAllDayVisit() && (
              <td>{monitorRuleSettingAttribute?.publicHolidayVisitsFlg ? '○' : '×'}</td>
            )}
          </tr>
        </tbody>
      </table>
      <div className={sass.supplement}>
        <span>※○の曜日が来店対象です。</span>
        <span>※定休日は各店舗情報をご確認ください。</span>
        {monitorRuleSettingAttribute?.publicHolidayVisitsFlg === true && !isAllDayVisit() && (
          <span>※ただし、×の曜日が祝日の場合は〇になります。</span>
        )}
        {monitorRuleSettingAttribute?.publicHolidayVisitsFlg === false && !isAllDayVisit() && (
          <span>※ただし、〇の曜日が祝日の場合は×になります。</span>
        )}
      </div>
    </>
  );
};
