import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Url } from '../../constants/Url';
// eslint-disable-next-line import/no-cycle
import { getUser } from '../../services/AuthService';
import { currentUserState } from '../../states/currentUser';
import { OemLayout } from '../templates/OemLayout';

const OemPrivateRoute: React.FC<React.ComponentProps<typeof Route>> = (props) => {
  const setCurrentUser = useSetRecoilState(currentUserState);
  const history = useHistory();
  useEffect(() => {
    // /api/userへアクセス
    getUser()
      .then((result) => {
        if (typeof result === 'object') {
          setCurrentUser(result);
        } else {
          setCurrentUser(null);
        }
      })
      .catch(() => {
        history.push(Url.COMMON_ERROR);
      });
  }, [history, setCurrentUser]);

  return (
    <>
      <OemLayout>
        <Route {...props} />
      </OemLayout>
    </>
  );
};

export default OemPrivateRoute;
