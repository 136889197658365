import React, { useCallback } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId, formatISODate } from '../../../utils/functions';
import { Button } from '../../atoms/Button';
// eslint-disable-next-line import/no-cycle
import { ApplySearchApi, QcCrewApplySearchOutputResponse } from '../../../api-client';
import { Url } from '../../../constants/Url';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
import { QcCrewApplySearchForms } from '../../organisms/Forms/QcCrewApplySearchForms';
import { DATETIME_DISPLAY_FORMAT } from '../../../Constants';
import { Title } from '../../atoms/Title';
import { getSurveyProofCorrectPageUrl } from './utils';
import { PaginationWithEllipsisBilling } from '../../molecules/PaginationWithEllipsisBilling';

interface FormsState {
  searchType: string;
  searchValue?: number;
  targetStatus: number;
}
const initialForms: FormsState = {
  searchType: 'applyId',
  searchValue: undefined,
  targetStatus: 0,
};

export type State = {
  api: ApplySearchApi;
  isLoading: boolean;
  forms: FormsState;
  data: QcCrewApplySearchOutputResponse;
};

export const QcCrewApplySearchPage: React.FC = () => {
  const testid = createTestId(QcCrewApplySearchPage);
  const MAX_RECORD_PER_PAGE = 20;
  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<State>({
    api: new ApplySearchApi(),
    isLoading: false,
    forms: initialForms,
    data: {
      totalCount: 0,
      qcCrewApplySearchList: [],
    },
  });

  const history = useHistory();

  const onSearch = useCallback(() => {
    const searchType = String($.forms.searchType);
    const { searchValue } = $.forms;
    const targetStatus = Number($.forms.targetStatus);
    const applyId = searchType === 'applyId' && searchValue !== 0 ? searchValue : undefined;
    const contractShopId = searchType === 'contractShopId' ? searchValue : undefined;
    const clientId = searchType === 'clientId' ? searchValue : undefined;
    mergeState({ isLoading: true });
    $.api
      .qcCrewApplySearch(targetStatus, 1, MAX_RECORD_PER_PAGE, applyId, contractShopId, clientId)
      .then((res) => {
        mergeState({ data: res.data, isLoading: false });
      })
      .catch(() => {
        history.push(Url.COMMON_ERROR);
      });
  }, [$.forms, $.api, mergeState, history]);

  const onClickPageNation = (clickPage: number) => {
    const searchType = String($.forms.searchType);
    const { searchValue } = $.forms;
    const targetStatus = Number($.forms.targetStatus);
    const applyId = searchType === 'applyId' ? searchValue : undefined;
    const contractShopId = searchType === 'contractShopId' ? searchValue : undefined;
    const clientId = searchType === 'clientId' ? searchValue : undefined;
    $.api
      .qcCrewApplySearch(targetStatus, clickPage, MAX_RECORD_PER_PAGE, applyId, contractShopId, clientId)
      .then((res) => {
        mergeState({ data: res.data, isLoading: false });
      });
  };
  const setForms = useBindSet('forms');

  return (
    <LoadingSpinner isLoading={$.isLoading}>
      <Title>QCクルー案件検索</Title>
      <Form>
        <Row className="mb-3">
          <QcCrewApplySearchForms setForms={setForms} />
          <Col>
            <Button data-testid={testid('search-button')} onClick={() => onSearch()}>
              検索
            </Button>
          </Col>
        </Row>
      </Form>
      <hr />
      {$.data.totalCount !== 0 ? (
        <Table data-testid={testid('table')}>
          <thead>
            <tr className="align-middle">
              <th data-testid={testid('label-1')}>
                <span>応募ID</span>
                <br />
                <span>当選ステータス</span>
              </th>
              <th data-testid={testid('label-2')}>
                <span>自身の最終処理日時</span>
                <br />
                <span>内容</span>
              </th>
              <th data-testid={testid('label-3')}>
                <span>クライアントID</span>
                <br />
                <span>クライアント名</span>
              </th>
              <th data-testid={testid('label-4')}>
                <span>店舗ID</span>
                <br />
                <span>店舗・モニター名</span>
              </th>
              <th data-testid={testid('label-5')}>調査証明</th>
              <th data-testid={testid('label-6')}>アンケート</th>
            </tr>
          </thead>
          <tbody>
            {$.data.qcCrewApplySearchList.map((row, i) => (
              <tr key={row.applyId} className="align-middle">
                <td data-testid={testid('applyInfo', i)}>
                  <span>{row.applyId}</span>
                  <br />
                  <span>{row.applyStatusName}</span>
                </td>
                <td data-testid={testid('dateInfo', i)}>
                  <span>{row.maxExecutionDate}</span>
                  <br />
                  <span>{row.operationTypeName}</span>
                </td>
                <td data-testid={testid('clientInfo', i)}>
                  <span>{row.clientId}</span>
                  <br />
                  <span>{row.clientName}</span>
                </td>
                <td data-testid={testid('contractShopInfo', i)}>
                  <span>{row.contractShopId}</span>
                  <br />
                  <span>{`${row.contractShopName}・${row.monitorBaseName}`}</span>
                </td>
                <td>
                  <Button
                    variant="link"
                    data-testid={testid('surveyProofCorrect-button', i)}
                    onClick={() => {
                      history.push(getSurveyProofCorrectPageUrl(row.surveyProofCorrectType, row.applyId));
                    }}
                  >
                    {row.surveyProofStatusName}
                  </Button>
                </td>
                <td>
                  <Button
                    variant="link"
                    data-testid={testid('enqueteCorrect-button', i)}
                    onClick={() => {
                      history.push(`${Url.TENSAKU.CORRECT_ENQUETE}/${row.applyId}`);
                    }}
                  >
                    {row.enqueteStatusName}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <></>
      )}
      {$.data.totalCount !== undefined && (
        <div className="d-flex justify-content-center">
          <PaginationWithEllipsisBilling
            maxRecordPerPage={MAX_RECORD_PER_PAGE}
            totalDataNum={$.data.totalCount}
            handleClick={(e) => onClickPageNation(e.page)}
          />
        </div>
      )}
    </LoadingSpinner>
  );
};
