import React, { useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import {
  IdNameOutputResponse,
  MonitorRuleApi,
  MonitorRuleSetCopyApi,
  MonitorRuleSetCopyOutputResponse,
  MonitorRuleSetListOutputResponse,
  RoleListApi,
} from '../../../api-client';
import { Url } from '../../../constants/Url';
import { TITLE } from '../../../constants/Title';
import { Title } from '../../atoms/Title';
import { Button } from '../../atoms/Button';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import { MonitorRuleSetTable } from '../../organisms/Table/MonitorRuleSetTable';

const MAX_RECORD_PER_PAGE = 20;

export const MonitorRuleSetPage: React.VFC = () => {
  const history = useHistory();
  const monitorRuleApi = new MonitorRuleApi();
  const monitorRuleSetCopy = new MonitorRuleSetCopyApi();
  const [data, setData] = useState<MonitorRuleSetListOutputResponse[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  const [salesFlg, setSalesFlg] = useState<boolean>(false);
  const roleListApi = new RoleListApi();

  //  ページ関連変数
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [listPerPage, setListPerPage] = useState<MonitorRuleSetListOutputResponse[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);

  useEffect(() => {
    monitorRuleApi.monitorRuleSetList().then((res: AxiosResponse<MonitorRuleSetListOutputResponse[]>) => {
      const sortData = res.data.sort((a, b) => b.monitorRuleSetId - a.monitorRuleSetId);
      setData(sortData);
    });
  }, []);

  // 画面初期表示時に一回だけロール一覧を取得する。
  useEffect(() => {
    roleListApi.roleList().then((res: AxiosResponse<IdNameOutputResponse[]>) => {
      setSalesFlg(res.data.some((role) => role.name === '営業'));
    });
  }, []);

  useEffect(() => {
    setListPerPage(data?.slice((currentPage - 1) * MAX_RECORD_PER_PAGE, currentPage * MAX_RECORD_PER_PAGE));
    setTotalPage(Math.ceil(Number(data?.length) / MAX_RECORD_PER_PAGE));
  }, [data, currentPage]);

  // 検索ボタンイベント
  const handleClickSearchBtn = () => {
    monitorRuleApi
      .monitorRuleSetList(searchText === '' ? undefined : searchText)
      .then((res: AxiosResponse<MonitorRuleSetListOutputResponse[]>) => {
        const sortData = res.data.sort((a, b) => b.monitorRuleSetId - a.monitorRuleSetId);
        setData(sortData);
        setCurrentPage(1);
      });
  };

  const onCopy = (id: number) => {
    monitorRuleSetCopy
      .monitorRuleSetCopy({ ruleSetId: id })
      .then((res: AxiosResponse<MonitorRuleSetCopyOutputResponse>) => {
        if (res.data.ruleSetId && res.data.ruleSetName) {
          setData(
            [
              ...data,
              { monitorRuleSetId: res.data.ruleSetId, monitorRuleSetName: res.data.ruleSetName, clientList: [] },
            ].sort((a, b) => b.monitorRuleSetId - a.monitorRuleSetId)
          );
        }
      });
  };

  return (
    <>
      <Title className="mb-4">{TITLE.KEISAI.MONITOR_RULE_SET}</Title>

      <Form.Group className="mb-4">
        <Row className="mb-3">
          <FloatingLabel controlId="name" label="フリーワード検索">
            <Form.Control
              type="text"
              placeholder="フリーワード検索"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </FloatingLabel>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col className="col-4">
            <Button className="w-100 py-3" onClick={handleClickSearchBtn}>
              検索
            </Button>
          </Col>
        </Row>
      </Form.Group>

      {!salesFlg && (
        <div className="d-flex justify-content-end mb-4">
          <Button variant="link" className="text-secondary">
            <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
            <Link to={`${Url.KEISAI.MONITOR_RULE_SET_MODIFY}`} target="_blank">
              追加
            </Link>
          </Button>
        </div>
      )}

      <MonitorRuleSetTable listPerPage={listPerPage} onCopy={onCopy} salesFlg={salesFlg} />

      <div className="mb-4">
        <PaginationWithEllipsis
          currentPage={currentPage}
          totalPage={totalPage}
          handleClick={(page) => {
            if (!page || page > totalPage) return;
            setListPerPage(data?.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE));
            setCurrentPage(page);
          }}
        />
      </div>
    </>
  );
};
