import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { Alert } from '../atoms/Alert';
import { BillingApi, JobExecutionHistoryOutputResponse } from '../../api-client';
import { BILLING_JOB_LIST } from '../../BillingConstants';

export const JobHistoryPage: React.VFC = () => {
  const [list, setList] = useState<JobExecutionHistoryOutputResponse[]>();
  const [searchText, setSearchText] = useState<string>();
  const billingApi = new BillingApi();

  useEffect(() => {
    // 初回データ取得
    billingApi.jobExecutionHistory().then((res) => {
      setList(res.data);
    });
  }, []);

  const onChangeText = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchText(event.target.value);
  };

  const handleClickSearch = () => {
    console.log(searchText);
    billingApi.jobExecutionHistory(searchText).then((res) => {
      setList(res.data);
    });
  };

  return (
    <>
      <h3>請求ジョブ実行履歴(最新の100件)</h3>
      <Row className="g-2 mb-4">
        <Col>
          <Form.Group controlId="searchText">
            <Form.Label>ジョブ種別</Form.Label>
            <Form.Select onChange={(e) => onChangeText(e)}>
              <option value="">{}</option>
              <option value={BILLING_JOB_LIST.RECREATE_BILLING_DATA_BATCH}>請求書再作成</option>
              <option value={BILLING_JOB_LIST.SEND_BILLING_MAIL_JOB}>請求メール作成</option>
              <option value={BILLING_JOB_LIST.GET_HOLIDAY_JOB}>祝祭日管理</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Button style={{ marginTop: '2rem' }} onClick={handleClickSearch}>
            検索
          </Button>
        </Col>
      </Row>
      {list?.length === 0 && <Alert variant="danger">該当するジョブ履歴がありません</Alert>}
      <Table style={{ tableLayout: 'fixed', wordWrap: 'break-word' }}>
        <thead>
          <tr className="text-nowrap">
            <th style={{ width: '130px' }}>ジョブ名</th>
            <th style={{ width: '120px' }}>登録日</th>
            <th style={{ width: '120px' }}>スタート日時</th>
            <th style={{ width: '120px' }}>終了日時</th>
            <th style={{ width: '110px' }}>ステータス</th>
            <th>メッセージ</th>
          </tr>
        </thead>
        <tbody>
          {list &&
            list.map((item, idx) => {
              return (
                <tr key={idx}>
                  <td>{item.jobName}</td>
                  <td>{item.createTime}</td>
                  <td>{item.startTime}</td>
                  <td>{item.endTime}</td>
                  <td>{item.status}</td>
                  <td>{item.exitMessage}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </>
  );
};
