import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';
/* eslint-disable import/no-cycle */
import { AxiosResponse } from 'axios';
import { IncResultOutputResponse, OemBalanceApi, OemBalanceInfoOutputResponse } from '../../../api-client';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId } from '../../../utils/functions';

import { Title } from '../../atoms/Title';
import { TITLE } from '../../../constants/Title';
import { Url } from '../../../constants/Url';
import { Modal } from '../../molecules/Modal';
import { Alert } from '../../atoms/Alert';

/* eslint-enable import/no-cycle */

export interface State {
  oemBalanceApi: OemBalanceApi;
  updResult: IncResultOutputResponse;
}

export const OemBalancePage: React.VFC = () => {
  const testid = createTestId(OemBalancePage);
  const history = useHistory();

  const { id } = useParams<{
    id: string | undefined;
  }>();

  const { state: $, mergeState } = useLargeState<State>({
    oemBalanceApi: new OemBalanceApi(),
    updResult: { result: false },
  });

  const [data, setData] = useState<OemBalanceInfoOutputResponse>();
  const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = useState<boolean>(false);

  const [isDeleteMsg, setIsDeleteMsg] = useState<boolean>(false);

  const [slideDays, setSlideDays] = useState<number | undefined>();
  const [monitorBaseIds, setMonitorBaseIds] = useState<string>('');

  const openDeleteConfirmModal = () => setIsOpenDeleteConfirm(true);
  const closeDeleteConfirmModal = () => setIsOpenDeleteConfirm(false);

  useEffect(() => {
    if (id) {
      $.oemBalanceApi.oemBalanceInfo(Number(id)).then((res: AxiosResponse<OemBalanceInfoOutputResponse>) => {
        setData(res.data);
        setSlideDays(res.data.slideDays || undefined);
        setMonitorBaseIds(res.data.monitorBaseIds || '');
      });
    }
  }, [id]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const payload = {
      id: id ? Number(id) : undefined,
      monitorBaseIds,
      slideDays: Number(slideDays),
    };

    $.oemBalanceApi
      .oemBalanceUpsert(payload)
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        mergeState({ updResult: res.data });
      })
      .catch((err: IncResultOutputResponse) => {
        mergeState({ updResult: err });
      });
  };

  const handleDeleteYes = () => {
    closeDeleteConfirmModal();

    // 削除本処理
    handleDelete();
  };

  const handleDelete = () => {
    $.oemBalanceApi
      .oemBalanceDelete(Number(id))
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        setIsDeleteMsg(true);
      })
      .catch((err: IncResultOutputResponse) => {
        mergeState({ updResult: err });
      });
  };

  const rowStyle: React.CSSProperties = {
    border: '1px solid #ddd',
    backgroundColor: '#FFECD5',
    padding: '10px',
    borderRadius: '4px',
    width: '300px',
  };

  const noteStyle: React.CSSProperties = {
    marginLeft: '7px',
    display: 'block',
  };

  const textAreaStyle: React.CSSProperties = {
    width: '200px',
    height: '300px',
    resize: 'none',
  };

  return (
    <>
      <Modal
        centered
        isModal={isOpenDeleteConfirm}
        body={
          <div>
            <p>OEM露出設定を削除します。問題ないですか？</p>
            <div className="d-flex justify-content-center mb-2 gap-2">
              <Button onClick={handleDeleteYes}>はい</Button>
              <Button onClick={closeDeleteConfirmModal}>いいえ</Button>
            </div>
          </div>
        }
      />

      <Title className="mb-4" data-testid={testid('title')}>
        {TITLE.KEISAI.OEM_BALANCE}
      </Title>

      <div>
        <Link to={`${Url.KEISAI.OEM_BALANCE_LIST}`} className="btn btn-outline-secondary mb-3">
          一覧ページへ戻る
        </Link>
        <br />
        <br />
        <Form onSubmit={handleSubmit}>
          {isDeleteMsg && (
            <Alert variant="success" data-testid={testid('success-alert')}>
              削除しました。
            </Alert>
          )}
          {$.updResult?.result && (
            <Alert variant="success" data-testid={testid('success-alert')}>
              更新しました。
            </Alert>
          )}
          {$.updResult?.errorMessage && (
            <div style={{ whiteSpace: 'pre-wrap' }}>
              <Alert variant="danger" data-testid={testid('failure-alert')}>
                {`${$.updResult.errorMessage}`}
              </Alert>
            </div>
          )}
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="shifDays">
                <Row className="align-items-center" style={rowStyle}>
                  <Col xs="auto" style={{ width: '100px' }}>
                    <Form.Label className="fw-bold mb-0">ずらす日数</Form.Label>
                  </Col>
                  <Col xs="auto">
                    <Form.Control
                      type="number"
                      min="1"
                      style={{ width: '70px' }}
                      value={slideDays}
                      onChange={(e) => setSlideDays(Number(e.target.value))}
                      required
                    />
                  </Col>
                  <Col xs="auto" style={{ width: '10px' }}>
                    <Form.Label className="fw-bold mb-0">日</Form.Label>
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3 align-items-center" style={rowStyle}>
            <Col>
              <Form.Label className="fw-bold mb-0">掲載日をずらすモニターベースID</Form.Label>
            </Col>
          </Row>
          <p>
            ・複数指定の場合は1行1件で改行してください
            <br />
            ・削除ボタンは、本設定が全削除されます。
            <br />
            <span style={noteStyle}>
              ずらす日数やモニターベースIDはそのままで問題ありませんので
              <br />
              削除ボタンを押してください。
              <br />
              ずらす日数を変更して削除を押しても、その日数の設定が削除されるわけでは
              <br />
              ありません。元々の設定が削除されますので、ご注意ください。
            </span>
          </p>

          <Form.Group>
            <Form.Control
              as="textarea"
              style={textAreaStyle}
              value={monitorBaseIds}
              onChange={(e) => setMonitorBaseIds(e.target.value)}
              required
            />
          </Form.Group>

          <br />
          <Button variant="primary" type="submit" className="me-lg-5">
            更新
          </Button>
          <Button variant="danger" onClick={openDeleteConfirmModal} disabled={id == null}>
            削除
          </Button>
        </Form>
      </div>
    </>
  );
};
