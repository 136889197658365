import React from 'react';
import { useHistory } from 'react-router-dom';
import { parseSearchQuery } from '../../utils/functions';
import { ImageLens } from '../organisms/ImageLens';

export const ImagePreviewPage: React.FC = React.memo(() => {
  const history = useHistory();
  const { url } = parseSearchQuery(history.location.search);
  return <ImageLens list={[{ imageId: 0, imageUrl: decodeURIComponent(url) }]} height="none" linkType="DIRECT" />;
});
