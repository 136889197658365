/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FloatingRewardFileCheckFormResponse } from '../domains';
// @ts-ignore
import { FloatingRewardFileCheckOutputResponse } from '../domains';
/**
 * FloatingRewardFileCheckApi - axios parameter creator
 * @export
 */
export const FloatingRewardFileCheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ファイルチェック
         * @summary ファイルチェック
         * @param {FloatingRewardFileCheckFormResponse} floatingRewardFileCheckFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        floatingRewardFileCheck: async (floatingRewardFileCheckFormResponse: FloatingRewardFileCheckFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'floatingRewardFileCheckFormResponse' is not null or undefined
            assertParamExists('floatingRewardFileCheck', 'floatingRewardFileCheckFormResponse', floatingRewardFileCheckFormResponse)
            const localVarPath = `/api/floating-reward-file-check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(floatingRewardFileCheckFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FloatingRewardFileCheckApi - functional programming interface
 * @export
 */
export const FloatingRewardFileCheckApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FloatingRewardFileCheckApiAxiosParamCreator(configuration)
    return {
        /**
         * ファイルチェック
         * @summary ファイルチェック
         * @param {FloatingRewardFileCheckFormResponse} floatingRewardFileCheckFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async floatingRewardFileCheck(floatingRewardFileCheckFormResponse: FloatingRewardFileCheckFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FloatingRewardFileCheckOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.floatingRewardFileCheck(floatingRewardFileCheckFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FloatingRewardFileCheckApi - factory interface
 * @export
 */
export const FloatingRewardFileCheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FloatingRewardFileCheckApiFp(configuration)
    return {
        /**
         * ファイルチェック
         * @summary ファイルチェック
         * @param {FloatingRewardFileCheckFormResponse} floatingRewardFileCheckFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        floatingRewardFileCheck(floatingRewardFileCheckFormResponse: FloatingRewardFileCheckFormResponse, options?: any): AxiosPromise<FloatingRewardFileCheckOutputResponse> {
            return localVarFp.floatingRewardFileCheck(floatingRewardFileCheckFormResponse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FloatingRewardFileCheckApi - object-oriented interface
 * @export
 * @class FloatingRewardFileCheckApi
 * @extends {BaseAPI}
 */
export class FloatingRewardFileCheckApi extends BaseAPI {
    /**
     * ファイルチェック
     * @summary ファイルチェック
     * @param {FloatingRewardFileCheckFormResponse} floatingRewardFileCheckFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloatingRewardFileCheckApi
     */
    public floatingRewardFileCheck(floatingRewardFileCheckFormResponse: FloatingRewardFileCheckFormResponse, options?: AxiosRequestConfig) {
        return FloatingRewardFileCheckApiFp(this.configuration).floatingRewardFileCheck(floatingRewardFileCheckFormResponse, options).then((request) => request(this.axios, this.basePath));
    }
}
