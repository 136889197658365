import React, { useEffect } from 'react';
import { Form, Row, Col, FloatingLabel } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import { useLargeState } from '../../../hooks/useLargeState';
import {
  EnqueteReceiptFileApi,
  CommonMasterListApi,
  AffiliationOrganizationListApi,
  AffiliationContractShopListApi,
  CommonMasterListOutputResponse,
  IdNameOutputResponse,
} from '../../../api-client';
import { Url } from '../../../constants/Url';
import { TITLE } from '../../../constants/Title';
import { createTestId, execDownload } from '../../../utils/functions';
import { Title } from '../../atoms/Title';
import { Button } from '../../atoms/Button';
import { RecommendFloatingLabel } from '../../molecules/RecommendFloatingLabel';

export const PostEnqueteFilePage: React.VFC = () => {
  const testid = createTestId(PostEnqueteFilePage);
  const { state: $, mergeState } = useLargeState<{
    api: EnqueteReceiptFileApi;
    commonApi: CommonMasterListApi;
    organizationApi: AffiliationOrganizationListApi;
    shopApi: AffiliationContractShopListApi;
    clientOpts: CommonMasterListOutputResponse[];
    organizationOpts: CommonMasterListOutputResponse[];
    shopOpts: CommonMasterListOutputResponse[];
    clientTxt?: string;
    organizationTxt?: string;
    shopTxt?: string;
  }>({
    api: new EnqueteReceiptFileApi(),
    commonApi: new CommonMasterListApi(),
    organizationApi: new AffiliationOrganizationListApi(),
    shopApi: new AffiliationContractShopListApi(),
    clientOpts: [],
    organizationOpts: [],
    shopOpts: [],
  });

  const {
    state: f,
    mergeState: fMergeState,
    onChangeSet: fOnChangeSet,
    onChangeCheckbox: fOnChangeCheckbox,
  } = useLargeState<{
    clientId?: number;
    ymFrom?: string;
    ymTo?: string;
    divideFlg: boolean;
    organizationId?: number;
    shopId?: number;
    deadlineType?: string;
  }>({ divideFlg: false, deadlineType: '完了月' });

  const history = useHistory();

  useEffect(() => {
    mergeState({ organizationTxt: undefined, shopTxt: undefined });
    fMergeState({ organizationId: undefined, shopId: undefined });
  }, [f.clientId, mergeState, fMergeState]);

  useEffect(() => {
    mergeState({ shopTxt: undefined });
    fMergeState({ shopId: undefined });
  }, [f.organizationId, mergeState, fMergeState]);

  useEffect(() => {
    $.commonApi
      .commonMasterList('client')
      .then((res: AxiosResponse<CommonMasterListOutputResponse[]>) => mergeState({ clientOpts: res.data }));
  }, [$.commonApi, mergeState]);

  useEffect(() => {
    if (!f.clientId) return;
    $.organizationApi
      .affiliationOrganizationList(f.clientId)
      .then((res: AxiosResponse<IdNameOutputResponse[]>) => mergeState({ organizationOpts: res.data }));
  }, [$.organizationApi, f.clientId, mergeState]);

  useEffect(() => {
    if (!f.clientId) return;
    $.shopApi
      .affiliationContractShopList(f.clientId, f.organizationId)
      .then((res: AxiosResponse<IdNameOutputResponse[]>) => mergeState({ shopOpts: res.data }));
  }, [$.shopApi, f.clientId, f.organizationId, mergeState]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!f.clientId || !f.ymFrom || !f.ymTo) return;
    $.api
      .postEnqueteDL(f.clientId, f.ymFrom, f.ymTo, f.divideFlg, f.organizationId, f.shopId, f.deadlineType)
      .then((res: AxiosResponse<string>) => {
        execDownload(
          res.data,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          '【事後アンケート】ダウンロード.xlsx'
        ).catch(() => {
          history.push(Url.COMMON_ERROR);
        });
      });
  };

  return (
    <>
      <Title data-testid={testid('title')} className="mb-4">
        {TITLE.KEISAI.POST_ENQUETE_FILE}
      </Title>

      <Form onSubmit={onSubmit}>
        <div className="d-flex justify-content-end mb-4">
          <Button data-testid={testid('download-button')} className="ms-2" type="submit">
            ダウンロード
          </Button>
        </div>

        <RecommendFloatingLabel
          required
          value={$.clientTxt || ''}
          label="本社"
          className="mb-4"
          options={$.clientOpts.map(({ id, name }) => `${id}：${name}`)}
          onChange={(e) => mergeState({ clientTxt: e.target.value })}
          onDelete={() => {
            fMergeState({ clientId: undefined });
            mergeState({ clientTxt: undefined });
          }}
          onClickItem={(e) => {
            const { innerHTML: val } = e.currentTarget;
            fMergeState({ clientId: Number(val.substring(0, val.indexOf('：'))) });
            mergeState({ clientTxt: val });
          }}
          onBlur={() => {
            if (!$.clientOpts.find(({ id, name }) => `${id}：${name}` === $.clientTxt)) {
              mergeState({ clientTxt: undefined });
            }
          }}
        />

        <RecommendFloatingLabel
          value={$.organizationTxt || ''}
          label="組織"
          className="mb-4"
          options={$.organizationOpts.map(({ id, name }) => `${id}：${name}`)}
          onChange={(e) => mergeState({ organizationTxt: e.target.value })}
          onDelete={() => {
            fMergeState({ organizationId: undefined });
            mergeState({ organizationTxt: undefined });
          }}
          onClickItem={(e) => {
            const { innerHTML: val } = e.currentTarget;
            fMergeState({ organizationId: Number(val.substring(0, val.indexOf('：'))) });
            mergeState({ organizationTxt: val });
          }}
          onBlur={() => {
            if (!$.organizationOpts.find(({ id, name }) => `${id}：${name}` === $.organizationTxt)) {
              mergeState({ organizationTxt: undefined });
            }
          }}
        />

        <RecommendFloatingLabel
          value={$.shopTxt || ''}
          label="契約店舗"
          className="mb-4"
          options={$.shopOpts.map(({ id, name }) => `${id}：${name}`)}
          onChange={(e) => mergeState({ shopTxt: e.target.value })}
          onDelete={() => {
            fMergeState({ shopId: undefined });
            mergeState({ shopTxt: undefined });
          }}
          onClickItem={(e) => {
            const { innerHTML: val } = e.currentTarget;
            fMergeState({ shopId: Number(val.substring(0, val.indexOf('：'))) });
            mergeState({ shopTxt: val });
          }}
          onBlur={() => {
            if (!$.shopOpts.find(({ id, name }) => `${id}：${name}` === $.shopTxt)) {
              mergeState({ shopTxt: undefined });
            }
          }}
        />

        <FloatingLabel label="期限" className="mb-4" data-testid={testid('deadlineType')}>
          <Form.Select value={f.deadlineType} onChange={fOnChangeSet('deadlineType')}>
            {['完了月', '来店月', '管理画面集計月'].map((v) => (
              <option key={v} value={v}>
                {v}
              </option>
            ))}
          </Form.Select>
        </FloatingLabel>

        <Row className="d-flex align-items-center mb-4">
          <Col>
            <FloatingLabel label="From" data-testid={testid('ymFrom')}>
              <Form.Control
                required
                type="month"
                placeholder="From"
                value={f.ymFrom || ''}
                onChange={fOnChangeSet('ymFrom')}
              />
            </FloatingLabel>
          </Col>
          {' ~ '}
          <Col>
            <FloatingLabel label="To" data-testid={testid('ymTo')}>
              <Form.Control
                required
                type="month"
                placeholder="To"
                value={f.ymTo || ''}
                onChange={fOnChangeSet('ymTo')}
              />
            </FloatingLabel>
          </Col>
        </Row>

        <Form.Check id="divideFlg">
          <Form.Check.Input
            className="me-2"
            type="checkbox"
            data-testid={testid('divideFlg')}
            checked={f.divideFlg}
            onChange={fOnChangeCheckbox('divideFlg')}
          />
          <Form.Check.Label>アンケートIDでシート分ける</Form.Check.Label>
        </Form.Check>
      </Form>
    </>
  );
};
