import React from 'react';
import { Form, Table, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPen, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { createTestId } from '../../../utils/functions';
import type { DispatchSetState } from '../../../interfaces/utils';
import { Button } from '../../atoms/Button';
// eslint-disable-next-line import/no-cycle
import { ModalConfig, ContractShop, PRODUCT_TYPE } from '../../pages/PostingRequest/PostingRequestPage';

export interface Props {
  contractShops: ContractShop[];
  setContractShops: DispatchSetState<ContractShop[]>;
  setIsMonitorModal: DispatchSetState<boolean>;
  setModalConfig: DispatchSetState<ModalConfig>;
  disabled: boolean;
  applicationFormFlg: boolean;
}

export const STATUS = ['', '掲載準備中', '公開中', '限定公開中', '非公開中'];

export const PostingRequestShopTable: React.VFC<Props> = ({
  contractShops,
  setContractShops,
  setIsMonitorModal,
  setModalConfig,
  disabled,
  applicationFormFlg,
}) => {
  const testid = createTestId(PostingRequestShopTable);

  const history = useHistory();
  return (
    <>
      {contractShops.map((shop, si) => (
        <Table key={shop.uuid} className="w-100 mb-4" data-testid={testid('shopTable', si)}>
          <thead>
            <tr>
              <th style={{ width: '500px' }}>契約店舗名</th>
              <th>店舗SPOT</th>
              <th>店舗STOCK</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border-0" data-testid={testid('shopName', si)} style={{ width: '500px' }}>
                <Form.Control
                  type="text"
                  value={shop.name}
                  onChange={(e) =>
                    setContractShops(contractShops.map((v, i) => (si === i ? { ...v, name: e.target.value } : v)))
                  }
                  disabled={disabled || !applicationFormFlg}
                />
              </td>

              <td className="border-0" data-testid={testid('storeSpotAmount', si)}>
                <Form.Control
                  type="number"
                  className="w-auto"
                  value={shop.storeSpotAmount || ''}
                  onChange={(e) =>
                    setContractShops(
                      contractShops.map((v, i) => (si === i ? { ...v, storeSpotAmount: Number(e.target.value) } : v))
                    )
                  }
                  disabled={disabled || !applicationFormFlg}
                />
              </td>
              <td className="border-0" data-testid={testid('storeStockAmount', si)}>
                <Form.Control
                  type="number"
                  className="w-auto"
                  value={shop.storeStockAmount || ''}
                  onChange={(e) =>
                    setContractShops(
                      contractShops.map((v, i) => (si === i ? { ...v, storeStockAmount: Number(e.target.value) } : v))
                    )
                  }
                  disabled={disabled || !applicationFormFlg}
                />
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td colSpan={3}>
                <div className="px-2 mb-4">
                  <Table className="mb-0" data-testid={testid('monitorTable', si)}>
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>モニター名</th>
                        <th>契約枠数</th>
                        <th>商品タイプ</th>
                        <th>謝礼値</th>
                        <th>表示状態</th>
                        <th>非表示理由</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody className="py-4">
                      {shop.monitorBases.map((mb, mi) => (
                        <React.Fragment key={mb.uuid}>
                          <tr className="border-0">
                            <td className="border-0">
                              <Form.Check.Input
                                type="checkbox"
                                name={`checkbox${mb.id}`}
                                data-testid={testid(`checkbox-${si}`, mi)}
                                checked={mb.checked}
                                onChange={() =>
                                  setContractShops(
                                    contractShops.map((v, i) =>
                                      si === i
                                        ? {
                                            ...v,
                                            monitorBases: v.monitorBases.map((m, idx) =>
                                              mi === idx ? { ...m, checked: !mb.checked } : m
                                            ),
                                          }
                                        : v
                                    )
                                  )
                                }
                              />
                            </td>
                            <td className="border-0">{mb.name}</td>
                            <td className="border-0">{mb.monitorContractRsv?.contractBorderCount}</td>
                            <td className="border-0">
                              {Number.isInteger(mb.productType) ? PRODUCT_TYPE[mb.productType] : ''}
                            </td>
                            <td className="border-0">
                              {mb.monitorContractRsv?.giftTicketRewardValue || mb.monitor?.userRewardValue}
                            </td>
                            <td className="border-0">
                              {mb.monitor?.status && Number.isInteger(mb.monitor?.status)
                                ? STATUS[mb.monitor?.status]
                                : ''}
                            </td>
                            <td className="border-0">{mb.reasonForHiding}</td>
                            <td className="border-0">
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Popover id="popover-basic">
                                    <Popover.Body className="p-1">モニター編集</Popover.Body>
                                  </Popover>
                                }
                              >
                                <button
                                  type="button"
                                  className="ms-2"
                                  data-testid={testid(`monitor-icon-${si}`, mi)}
                                  onClick={() => {
                                    setModalConfig({ mode: 'edit', shopIndex: si, monitorIndex: mi });
                                    setIsMonitorModal(true);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faPen}
                                    className="text-secondary flex-shrink-0 me-1"
                                    fixedWidth
                                  />
                                </button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                      <tr>
                        <td colSpan={11} className="text-end">
                          <Button
                            variant="link"
                            className="text-secondary"
                            data-testid={testid('add-button', si)}
                            onClick={() => {
                              setModalConfig({ mode: 'add', shopIndex: si });
                              setIsMonitorModal(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                            モニター追加
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      ))}
    </>
  );
};
