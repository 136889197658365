/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AutoCorrectMonitorOutputResponse } from '../domains';
// @ts-ignore
import { ChainStoreMasterListDisplayParentOutputResponse } from '../domains';
// @ts-ignore
import { ChainStoreMasterRegisterEditFormResponse } from '../domains';
// @ts-ignore
import { DataEntryDisplayOutputResponse } from '../domains';
// @ts-ignore
import { DataInputApplyDistributionOutputResponse } from '../domains';
// @ts-ignore
import { DataInputShopPhoneNumberSearchFormResponse } from '../domains';
// @ts-ignore
import { DataInputShopPhoneNumberSearchOutputResponse } from '../domains';
// @ts-ignore
import { DataInputSubmitFormResponse } from '../domains';
// @ts-ignore
import { DataInputWCheckApplyDistributionOutputResponse } from '../domains';
// @ts-ignore
import { DataInputWCheckApproveConfirmFormResponse } from '../domains';
// @ts-ignore
import { DataInputWCheckApproveConfirmOutputResponse } from '../domains';
// @ts-ignore
import { DataInputWCheckApproveFormResponse } from '../domains';
// @ts-ignore
import { DataInputWCheckDisplayOutputResponse } from '../domains';
// @ts-ignore
import { DataInputWCheckEscalationAnswerFormResponse } from '../domains';
// @ts-ignore
import { DataInputWCheckEscalationFormResponse } from '../domains';
// @ts-ignore
import { DataInputWCheckRejectFormResponse } from '../domains';
// @ts-ignore
import { DataInputWCheckResubmitFormResponse } from '../domains';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
// @ts-ignore
import { MonitorRuleForCorrectionPageDtoResponse } from '../domains';
// @ts-ignore
import { ShoppingDisplayControlOutputResponse } from '../domains';
// @ts-ignore
import { TaxRateListOutputResponse } from '../domains';
/**
 * ShoppingApi - axios parameter creator
 * @export
 */
export const ShoppingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 案件選択一覧
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoCorrectMonitorList: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/correct/data-input/target-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チェーン名マスタ
         * @summary チェーン名マスタ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chainNameList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shopping-master/chain-name-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チェーンストアマスタ削除実行
         * @summary チェーンストアマスタ削除実行
         * @param {number} chainStoreMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chainStoreMasterDelete: async (chainStoreMasterId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chainStoreMasterId' is not null or undefined
            assertParamExists('chainStoreMasterDelete', 'chainStoreMasterId', chainStoreMasterId)
            const localVarPath = `/api/shopping-master/chain-store-master/delete/{chain-store-master-id}`
                .replace(`{${"chain-store-master-id"}}`, encodeURIComponent(String(chainStoreMasterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チェーンストアマスタ一覧表示
         * @summary チェーンストアマスタ一覧表示
         * @param {string} [chainName] 
         * @param {string} [storeName] 
         * @param {string} [phoneNumber] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chainStoreMasterListDisplay: async (chainName?: string, storeName?: string, phoneNumber?: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shopping-master/chain-store-master/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (chainName !== undefined) {
                localVarQueryParameter['chainName'] = chainName;
            }

            if (storeName !== undefined) {
                localVarQueryParameter['storeName'] = storeName;
            }

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チェーンストアマスタ登録編集保存実行
         * @summary チェーンストアマスタ登録編集保存実行
         * @param {ChainStoreMasterRegisterEditFormResponse} chainStoreMasterRegisterEditFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chainStoreMasterRegisterEdit: async (chainStoreMasterRegisterEditFormResponse: ChainStoreMasterRegisterEditFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chainStoreMasterRegisterEditFormResponse' is not null or undefined
            assertParamExists('chainStoreMasterRegisterEdit', 'chainStoreMasterRegisterEditFormResponse', chainStoreMasterRegisterEditFormResponse)
            const localVarPath = `/api/shopping-master/chain-store-master/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chainStoreMasterRegisterEditFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * データ入力画面表示
         * @summary データ入力画面表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataEntryDisplay: async (applyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyId' is not null or undefined
            assertParamExists('dataEntryDisplay', 'applyId', applyId)
            const localVarPath = `/api/correct/data-input/{apply-id}`
                .replace(`{${"apply-id"}}`, encodeURIComponent(String(applyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary データ入力案件分配
         * @param {number} monitorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataInputApplyDistribution: async (monitorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'monitorId' is not null or undefined
            assertParamExists('dataInputApplyDistribution', 'monitorId', monitorId)
            const localVarPath = `/api/correct/data-input/distribute/{monitor-id}`
                .replace(`{${"monitor-id"}}`, encodeURIComponent(String(monitorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * データ入力用モニタールール表示
         * @summary データ入力用モニタールール表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataInputMonitorRuleDisplay: async (applyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyId' is not null or undefined
            assertParamExists('dataInputMonitorRuleDisplay', 'applyId', applyId)
            const localVarPath = `/api/shopping-monitor-rule/{apply-id}`
                .replace(`{${"apply-id"}}`, encodeURIComponent(String(applyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * データ入力電話番号検索
         * @summary データ入力電話番号検索
         * @param {DataInputShopPhoneNumberSearchFormResponse} dataInputShopPhoneNumberSearchFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataInputShopPhoneNumberSearch: async (dataInputShopPhoneNumberSearchFormResponse: DataInputShopPhoneNumberSearchFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataInputShopPhoneNumberSearchFormResponse' is not null or undefined
            assertParamExists('dataInputShopPhoneNumberSearch', 'dataInputShopPhoneNumberSearchFormResponse', dataInputShopPhoneNumberSearchFormResponse)
            const localVarPath = `/api/shopping-master/chain-store-master/phone-number-search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataInputShopPhoneNumberSearchFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * データ入力送信
         * @summary データ入力送信
         * @param {DataInputSubmitFormResponse} dataInputSubmitFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataInputSubmit: async (dataInputSubmitFormResponse: DataInputSubmitFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataInputSubmitFormResponse' is not null or undefined
            assertParamExists('dataInputSubmit', 'dataInputSubmitFormResponse', dataInputSubmitFormResponse)
            const localVarPath = `/api/correct/data-input/complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataInputSubmitFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * データ入力Wチェック案件分配
         * @summary データ入力Wチェック案件分配
         * @param {number} monitorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataInputWCheckApplyDistribution: async (monitorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'monitorId' is not null or undefined
            assertParamExists('dataInputWCheckApplyDistribution', 'monitorId', monitorId)
            const localVarPath = `/api/correct/data-input/distribute/wCheck/{monitor-id}`
                .replace(`{${"monitor-id"}}`, encodeURIComponent(String(monitorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * データ入力Wチェック　承認実行
         * @summary データ入力Wチェック　承認実行
         * @param {DataInputWCheckApproveFormResponse} dataInputWCheckApproveFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataInputWCheckApprove: async (dataInputWCheckApproveFormResponse: DataInputWCheckApproveFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataInputWCheckApproveFormResponse' is not null or undefined
            assertParamExists('dataInputWCheckApprove', 'dataInputWCheckApproveFormResponse', dataInputWCheckApproveFormResponse)
            const localVarPath = `/api/correct/data-input/correct/approve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataInputWCheckApproveFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自動添削ルール照合結果表示を行う
         * @summary データ入力Wチェック承認時確認
         * @param {DataInputWCheckApproveConfirmFormResponse} dataInputWCheckApproveConfirmFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataInputWCheckApproveConfirm: async (dataInputWCheckApproveConfirmFormResponse: DataInputWCheckApproveConfirmFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataInputWCheckApproveConfirmFormResponse' is not null or undefined
            assertParamExists('dataInputWCheckApproveConfirm', 'dataInputWCheckApproveConfirmFormResponse', dataInputWCheckApproveConfirmFormResponse)
            const localVarPath = `/api/correct/data-input/correct/confirm-approve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataInputWCheckApproveConfirmFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * データ入力Wチェック　画面表示
         * @summary データ入力Wチェック　画面表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataInputWCheckDisplay: async (applyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyId' is not null or undefined
            assertParamExists('dataInputWCheckDisplay', 'applyId', applyId)
            const localVarPath = `/api/correct/data-input/correct/{apply-id}`
                .replace(`{${"apply-id"}}`, encodeURIComponent(String(applyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * データ入力Wチェック　エスカレ実行
         * @summary データ入力Wチェック　エスカレ実行
         * @param {DataInputWCheckEscalationFormResponse} dataInputWCheckEscalationFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataInputWCheckEscalation: async (dataInputWCheckEscalationFormResponse: DataInputWCheckEscalationFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataInputWCheckEscalationFormResponse' is not null or undefined
            assertParamExists('dataInputWCheckEscalation', 'dataInputWCheckEscalationFormResponse', dataInputWCheckEscalationFormResponse)
            const localVarPath = `/api/correct/data-input/correct/escalation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataInputWCheckEscalationFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * データ入力Wチェックエスカレ回答
         * @summary データ入力Wチェックエスカレ回答
         * @param {DataInputWCheckEscalationAnswerFormResponse} dataInputWCheckEscalationAnswerFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataInputWCheckEscalationAnswer: async (dataInputWCheckEscalationAnswerFormResponse: DataInputWCheckEscalationAnswerFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataInputWCheckEscalationAnswerFormResponse' is not null or undefined
            assertParamExists('dataInputWCheckEscalationAnswer', 'dataInputWCheckEscalationAnswerFormResponse', dataInputWCheckEscalationAnswerFormResponse)
            const localVarPath = `/api/correct/data-input/correct/escalation-answer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataInputWCheckEscalationAnswerFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * データ入力Wチェック　却下実行
         * @summary データ入力Wチェック　却下実行
         * @param {DataInputWCheckRejectFormResponse} dataInputWCheckRejectFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataInputWCheckReject: async (dataInputWCheckRejectFormResponse: DataInputWCheckRejectFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataInputWCheckRejectFormResponse' is not null or undefined
            assertParamExists('dataInputWCheckReject', 'dataInputWCheckRejectFormResponse', dataInputWCheckRejectFormResponse)
            const localVarPath = `/api/correct/data-input/correct/reject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataInputWCheckRejectFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * データ入力Wチェック　再提出実行
         * @summary データ入力Wチェック　再提出実行
         * @param {DataInputWCheckResubmitFormResponse} dataInputWCheckResubmitFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataInputWCheckResubmit: async (dataInputWCheckResubmitFormResponse: DataInputWCheckResubmitFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataInputWCheckResubmitFormResponse' is not null or undefined
            assertParamExists('dataInputWCheckResubmit', 'dataInputWCheckResubmitFormResponse', dataInputWCheckResubmitFormResponse)
            const localVarPath = `/api/correct/data-input/correct/resubmit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataInputWCheckResubmitFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ショッピング表示制御
         * @summary ショッピング表示制御
         * @param {number} applyId 
         * @param {boolean} isDataInputEscalationAnswer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingDisplayControl: async (applyId: number, isDataInputEscalationAnswer: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyId' is not null or undefined
            assertParamExists('shoppingDisplayControl', 'applyId', applyId)
            // verify required parameter 'isDataInputEscalationAnswer' is not null or undefined
            assertParamExists('shoppingDisplayControl', 'isDataInputEscalationAnswer', isDataInputEscalationAnswer)
            const localVarPath = `/api/shopping-display-control/{apply-id}/{is-data-input-escalation-answer}`
                .replace(`{${"apply-id"}}`, encodeURIComponent(String(applyId)))
                .replace(`{${"is-data-input-escalation-answer"}}`, encodeURIComponent(String(isDataInputEscalationAnswer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 税率コードマスタ
         * @summary 税率コードマスタ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxRateList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shopping-master/taxRate-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShoppingApi - functional programming interface
 * @export
 */
export const ShoppingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShoppingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 案件選択一覧
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoCorrectMonitorList(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutoCorrectMonitorOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoCorrectMonitorList(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チェーン名マスタ
         * @summary チェーン名マスタ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chainNameList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chainNameList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チェーンストアマスタ削除実行
         * @summary チェーンストアマスタ削除実行
         * @param {number} chainStoreMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chainStoreMasterDelete(chainStoreMasterId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chainStoreMasterDelete(chainStoreMasterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チェーンストアマスタ一覧表示
         * @summary チェーンストアマスタ一覧表示
         * @param {string} [chainName] 
         * @param {string} [storeName] 
         * @param {string} [phoneNumber] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chainStoreMasterListDisplay(chainName?: string, storeName?: string, phoneNumber?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChainStoreMasterListDisplayParentOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chainStoreMasterListDisplay(chainName, storeName, phoneNumber, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チェーンストアマスタ登録編集保存実行
         * @summary チェーンストアマスタ登録編集保存実行
         * @param {ChainStoreMasterRegisterEditFormResponse} chainStoreMasterRegisterEditFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chainStoreMasterRegisterEdit(chainStoreMasterRegisterEditFormResponse: ChainStoreMasterRegisterEditFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chainStoreMasterRegisterEdit(chainStoreMasterRegisterEditFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * データ入力画面表示
         * @summary データ入力画面表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataEntryDisplay(applyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataEntryDisplayOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataEntryDisplay(applyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary データ入力案件分配
         * @param {number} monitorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataInputApplyDistribution(monitorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataInputApplyDistributionOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataInputApplyDistribution(monitorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * データ入力用モニタールール表示
         * @summary データ入力用モニタールール表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataInputMonitorRuleDisplay(applyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MonitorRuleForCorrectionPageDtoResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataInputMonitorRuleDisplay(applyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * データ入力電話番号検索
         * @summary データ入力電話番号検索
         * @param {DataInputShopPhoneNumberSearchFormResponse} dataInputShopPhoneNumberSearchFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataInputShopPhoneNumberSearch(dataInputShopPhoneNumberSearchFormResponse: DataInputShopPhoneNumberSearchFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataInputShopPhoneNumberSearchOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataInputShopPhoneNumberSearch(dataInputShopPhoneNumberSearchFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * データ入力送信
         * @summary データ入力送信
         * @param {DataInputSubmitFormResponse} dataInputSubmitFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataInputSubmit(dataInputSubmitFormResponse: DataInputSubmitFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataInputSubmit(dataInputSubmitFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * データ入力Wチェック案件分配
         * @summary データ入力Wチェック案件分配
         * @param {number} monitorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataInputWCheckApplyDistribution(monitorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataInputWCheckApplyDistributionOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataInputWCheckApplyDistribution(monitorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * データ入力Wチェック　承認実行
         * @summary データ入力Wチェック　承認実行
         * @param {DataInputWCheckApproveFormResponse} dataInputWCheckApproveFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataInputWCheckApprove(dataInputWCheckApproveFormResponse: DataInputWCheckApproveFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataInputWCheckApprove(dataInputWCheckApproveFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自動添削ルール照合結果表示を行う
         * @summary データ入力Wチェック承認時確認
         * @param {DataInputWCheckApproveConfirmFormResponse} dataInputWCheckApproveConfirmFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataInputWCheckApproveConfirm(dataInputWCheckApproveConfirmFormResponse: DataInputWCheckApproveConfirmFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataInputWCheckApproveConfirmOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataInputWCheckApproveConfirm(dataInputWCheckApproveConfirmFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * データ入力Wチェック　画面表示
         * @summary データ入力Wチェック　画面表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataInputWCheckDisplay(applyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataInputWCheckDisplayOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataInputWCheckDisplay(applyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * データ入力Wチェック　エスカレ実行
         * @summary データ入力Wチェック　エスカレ実行
         * @param {DataInputWCheckEscalationFormResponse} dataInputWCheckEscalationFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataInputWCheckEscalation(dataInputWCheckEscalationFormResponse: DataInputWCheckEscalationFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataInputWCheckEscalation(dataInputWCheckEscalationFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * データ入力Wチェックエスカレ回答
         * @summary データ入力Wチェックエスカレ回答
         * @param {DataInputWCheckEscalationAnswerFormResponse} dataInputWCheckEscalationAnswerFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataInputWCheckEscalationAnswer(dataInputWCheckEscalationAnswerFormResponse: DataInputWCheckEscalationAnswerFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataInputWCheckEscalationAnswer(dataInputWCheckEscalationAnswerFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * データ入力Wチェック　却下実行
         * @summary データ入力Wチェック　却下実行
         * @param {DataInputWCheckRejectFormResponse} dataInputWCheckRejectFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataInputWCheckReject(dataInputWCheckRejectFormResponse: DataInputWCheckRejectFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataInputWCheckReject(dataInputWCheckRejectFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * データ入力Wチェック　再提出実行
         * @summary データ入力Wチェック　再提出実行
         * @param {DataInputWCheckResubmitFormResponse} dataInputWCheckResubmitFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataInputWCheckResubmit(dataInputWCheckResubmitFormResponse: DataInputWCheckResubmitFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataInputWCheckResubmit(dataInputWCheckResubmitFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ショッピング表示制御
         * @summary ショッピング表示制御
         * @param {number} applyId 
         * @param {boolean} isDataInputEscalationAnswer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingDisplayControl(applyId: number, isDataInputEscalationAnswer: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingDisplayControlOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingDisplayControl(applyId, isDataInputEscalationAnswer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 税率コードマスタ
         * @summary 税率コードマスタ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxRateList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaxRateListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxRateList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShoppingApi - factory interface
 * @export
 */
export const ShoppingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShoppingApiFp(configuration)
    return {
        /**
         * 
         * @summary 案件選択一覧
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoCorrectMonitorList(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<AutoCorrectMonitorOutputResponse> {
            return localVarFp.autoCorrectMonitorList(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * チェーン名マスタ
         * @summary チェーン名マスタ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chainNameList(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.chainNameList(options).then((request) => request(axios, basePath));
        },
        /**
         * チェーンストアマスタ削除実行
         * @summary チェーンストアマスタ削除実行
         * @param {number} chainStoreMasterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chainStoreMasterDelete(chainStoreMasterId: number, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.chainStoreMasterDelete(chainStoreMasterId, options).then((request) => request(axios, basePath));
        },
        /**
         * チェーンストアマスタ一覧表示
         * @summary チェーンストアマスタ一覧表示
         * @param {string} [chainName] 
         * @param {string} [storeName] 
         * @param {string} [phoneNumber] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chainStoreMasterListDisplay(chainName?: string, storeName?: string, phoneNumber?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<ChainStoreMasterListDisplayParentOutputResponse> {
            return localVarFp.chainStoreMasterListDisplay(chainName, storeName, phoneNumber, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * チェーンストアマスタ登録編集保存実行
         * @summary チェーンストアマスタ登録編集保存実行
         * @param {ChainStoreMasterRegisterEditFormResponse} chainStoreMasterRegisterEditFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chainStoreMasterRegisterEdit(chainStoreMasterRegisterEditFormResponse: ChainStoreMasterRegisterEditFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.chainStoreMasterRegisterEdit(chainStoreMasterRegisterEditFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * データ入力画面表示
         * @summary データ入力画面表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataEntryDisplay(applyId: number, options?: any): AxiosPromise<DataEntryDisplayOutputResponse> {
            return localVarFp.dataEntryDisplay(applyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary データ入力案件分配
         * @param {number} monitorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataInputApplyDistribution(monitorId: number, options?: any): AxiosPromise<DataInputApplyDistributionOutputResponse> {
            return localVarFp.dataInputApplyDistribution(monitorId, options).then((request) => request(axios, basePath));
        },
        /**
         * データ入力用モニタールール表示
         * @summary データ入力用モニタールール表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataInputMonitorRuleDisplay(applyId: number, options?: any): AxiosPromise<Array<MonitorRuleForCorrectionPageDtoResponse>> {
            return localVarFp.dataInputMonitorRuleDisplay(applyId, options).then((request) => request(axios, basePath));
        },
        /**
         * データ入力電話番号検索
         * @summary データ入力電話番号検索
         * @param {DataInputShopPhoneNumberSearchFormResponse} dataInputShopPhoneNumberSearchFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataInputShopPhoneNumberSearch(dataInputShopPhoneNumberSearchFormResponse: DataInputShopPhoneNumberSearchFormResponse, options?: any): AxiosPromise<DataInputShopPhoneNumberSearchOutputResponse> {
            return localVarFp.dataInputShopPhoneNumberSearch(dataInputShopPhoneNumberSearchFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * データ入力送信
         * @summary データ入力送信
         * @param {DataInputSubmitFormResponse} dataInputSubmitFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataInputSubmit(dataInputSubmitFormResponse: DataInputSubmitFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.dataInputSubmit(dataInputSubmitFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * データ入力Wチェック案件分配
         * @summary データ入力Wチェック案件分配
         * @param {number} monitorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataInputWCheckApplyDistribution(monitorId: number, options?: any): AxiosPromise<DataInputWCheckApplyDistributionOutputResponse> {
            return localVarFp.dataInputWCheckApplyDistribution(monitorId, options).then((request) => request(axios, basePath));
        },
        /**
         * データ入力Wチェック　承認実行
         * @summary データ入力Wチェック　承認実行
         * @param {DataInputWCheckApproveFormResponse} dataInputWCheckApproveFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataInputWCheckApprove(dataInputWCheckApproveFormResponse: DataInputWCheckApproveFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.dataInputWCheckApprove(dataInputWCheckApproveFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 自動添削ルール照合結果表示を行う
         * @summary データ入力Wチェック承認時確認
         * @param {DataInputWCheckApproveConfirmFormResponse} dataInputWCheckApproveConfirmFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataInputWCheckApproveConfirm(dataInputWCheckApproveConfirmFormResponse: DataInputWCheckApproveConfirmFormResponse, options?: any): AxiosPromise<DataInputWCheckApproveConfirmOutputResponse> {
            return localVarFp.dataInputWCheckApproveConfirm(dataInputWCheckApproveConfirmFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * データ入力Wチェック　画面表示
         * @summary データ入力Wチェック　画面表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataInputWCheckDisplay(applyId: number, options?: any): AxiosPromise<DataInputWCheckDisplayOutputResponse> {
            return localVarFp.dataInputWCheckDisplay(applyId, options).then((request) => request(axios, basePath));
        },
        /**
         * データ入力Wチェック　エスカレ実行
         * @summary データ入力Wチェック　エスカレ実行
         * @param {DataInputWCheckEscalationFormResponse} dataInputWCheckEscalationFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataInputWCheckEscalation(dataInputWCheckEscalationFormResponse: DataInputWCheckEscalationFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.dataInputWCheckEscalation(dataInputWCheckEscalationFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * データ入力Wチェックエスカレ回答
         * @summary データ入力Wチェックエスカレ回答
         * @param {DataInputWCheckEscalationAnswerFormResponse} dataInputWCheckEscalationAnswerFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataInputWCheckEscalationAnswer(dataInputWCheckEscalationAnswerFormResponse: DataInputWCheckEscalationAnswerFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.dataInputWCheckEscalationAnswer(dataInputWCheckEscalationAnswerFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * データ入力Wチェック　却下実行
         * @summary データ入力Wチェック　却下実行
         * @param {DataInputWCheckRejectFormResponse} dataInputWCheckRejectFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataInputWCheckReject(dataInputWCheckRejectFormResponse: DataInputWCheckRejectFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.dataInputWCheckReject(dataInputWCheckRejectFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * データ入力Wチェック　再提出実行
         * @summary データ入力Wチェック　再提出実行
         * @param {DataInputWCheckResubmitFormResponse} dataInputWCheckResubmitFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataInputWCheckResubmit(dataInputWCheckResubmitFormResponse: DataInputWCheckResubmitFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.dataInputWCheckResubmit(dataInputWCheckResubmitFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * ショッピング表示制御
         * @summary ショッピング表示制御
         * @param {number} applyId 
         * @param {boolean} isDataInputEscalationAnswer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingDisplayControl(applyId: number, isDataInputEscalationAnswer: boolean, options?: any): AxiosPromise<ShoppingDisplayControlOutputResponse> {
            return localVarFp.shoppingDisplayControl(applyId, isDataInputEscalationAnswer, options).then((request) => request(axios, basePath));
        },
        /**
         * 税率コードマスタ
         * @summary 税率コードマスタ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxRateList(options?: any): AxiosPromise<Array<TaxRateListOutputResponse>> {
            return localVarFp.taxRateList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShoppingApi - object-oriented interface
 * @export
 * @class ShoppingApi
 * @extends {BaseAPI}
 */
export class ShoppingApi extends BaseAPI {
    /**
     * 
     * @summary 案件選択一覧
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingApi
     */
    public autoCorrectMonitorList(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return ShoppingApiFp(this.configuration).autoCorrectMonitorList(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * チェーン名マスタ
     * @summary チェーン名マスタ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingApi
     */
    public chainNameList(options?: AxiosRequestConfig) {
        return ShoppingApiFp(this.configuration).chainNameList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * チェーンストアマスタ削除実行
     * @summary チェーンストアマスタ削除実行
     * @param {number} chainStoreMasterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingApi
     */
    public chainStoreMasterDelete(chainStoreMasterId: number, options?: AxiosRequestConfig) {
        return ShoppingApiFp(this.configuration).chainStoreMasterDelete(chainStoreMasterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * チェーンストアマスタ一覧表示
     * @summary チェーンストアマスタ一覧表示
     * @param {string} [chainName] 
     * @param {string} [storeName] 
     * @param {string} [phoneNumber] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingApi
     */
    public chainStoreMasterListDisplay(chainName?: string, storeName?: string, phoneNumber?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return ShoppingApiFp(this.configuration).chainStoreMasterListDisplay(chainName, storeName, phoneNumber, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * チェーンストアマスタ登録編集保存実行
     * @summary チェーンストアマスタ登録編集保存実行
     * @param {ChainStoreMasterRegisterEditFormResponse} chainStoreMasterRegisterEditFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingApi
     */
    public chainStoreMasterRegisterEdit(chainStoreMasterRegisterEditFormResponse: ChainStoreMasterRegisterEditFormResponse, options?: AxiosRequestConfig) {
        return ShoppingApiFp(this.configuration).chainStoreMasterRegisterEdit(chainStoreMasterRegisterEditFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * データ入力画面表示
     * @summary データ入力画面表示
     * @param {number} applyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingApi
     */
    public dataEntryDisplay(applyId: number, options?: AxiosRequestConfig) {
        return ShoppingApiFp(this.configuration).dataEntryDisplay(applyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary データ入力案件分配
     * @param {number} monitorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingApi
     */
    public dataInputApplyDistribution(monitorId: number, options?: AxiosRequestConfig) {
        return ShoppingApiFp(this.configuration).dataInputApplyDistribution(monitorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * データ入力用モニタールール表示
     * @summary データ入力用モニタールール表示
     * @param {number} applyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingApi
     */
    public dataInputMonitorRuleDisplay(applyId: number, options?: AxiosRequestConfig) {
        return ShoppingApiFp(this.configuration).dataInputMonitorRuleDisplay(applyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * データ入力電話番号検索
     * @summary データ入力電話番号検索
     * @param {DataInputShopPhoneNumberSearchFormResponse} dataInputShopPhoneNumberSearchFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingApi
     */
    public dataInputShopPhoneNumberSearch(dataInputShopPhoneNumberSearchFormResponse: DataInputShopPhoneNumberSearchFormResponse, options?: AxiosRequestConfig) {
        return ShoppingApiFp(this.configuration).dataInputShopPhoneNumberSearch(dataInputShopPhoneNumberSearchFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * データ入力送信
     * @summary データ入力送信
     * @param {DataInputSubmitFormResponse} dataInputSubmitFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingApi
     */
    public dataInputSubmit(dataInputSubmitFormResponse: DataInputSubmitFormResponse, options?: AxiosRequestConfig) {
        return ShoppingApiFp(this.configuration).dataInputSubmit(dataInputSubmitFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * データ入力Wチェック案件分配
     * @summary データ入力Wチェック案件分配
     * @param {number} monitorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingApi
     */
    public dataInputWCheckApplyDistribution(monitorId: number, options?: AxiosRequestConfig) {
        return ShoppingApiFp(this.configuration).dataInputWCheckApplyDistribution(monitorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * データ入力Wチェック　承認実行
     * @summary データ入力Wチェック　承認実行
     * @param {DataInputWCheckApproveFormResponse} dataInputWCheckApproveFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingApi
     */
    public dataInputWCheckApprove(dataInputWCheckApproveFormResponse: DataInputWCheckApproveFormResponse, options?: AxiosRequestConfig) {
        return ShoppingApiFp(this.configuration).dataInputWCheckApprove(dataInputWCheckApproveFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 自動添削ルール照合結果表示を行う
     * @summary データ入力Wチェック承認時確認
     * @param {DataInputWCheckApproveConfirmFormResponse} dataInputWCheckApproveConfirmFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingApi
     */
    public dataInputWCheckApproveConfirm(dataInputWCheckApproveConfirmFormResponse: DataInputWCheckApproveConfirmFormResponse, options?: AxiosRequestConfig) {
        return ShoppingApiFp(this.configuration).dataInputWCheckApproveConfirm(dataInputWCheckApproveConfirmFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * データ入力Wチェック　画面表示
     * @summary データ入力Wチェック　画面表示
     * @param {number} applyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingApi
     */
    public dataInputWCheckDisplay(applyId: number, options?: AxiosRequestConfig) {
        return ShoppingApiFp(this.configuration).dataInputWCheckDisplay(applyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * データ入力Wチェック　エスカレ実行
     * @summary データ入力Wチェック　エスカレ実行
     * @param {DataInputWCheckEscalationFormResponse} dataInputWCheckEscalationFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingApi
     */
    public dataInputWCheckEscalation(dataInputWCheckEscalationFormResponse: DataInputWCheckEscalationFormResponse, options?: AxiosRequestConfig) {
        return ShoppingApiFp(this.configuration).dataInputWCheckEscalation(dataInputWCheckEscalationFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * データ入力Wチェックエスカレ回答
     * @summary データ入力Wチェックエスカレ回答
     * @param {DataInputWCheckEscalationAnswerFormResponse} dataInputWCheckEscalationAnswerFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingApi
     */
    public dataInputWCheckEscalationAnswer(dataInputWCheckEscalationAnswerFormResponse: DataInputWCheckEscalationAnswerFormResponse, options?: AxiosRequestConfig) {
        return ShoppingApiFp(this.configuration).dataInputWCheckEscalationAnswer(dataInputWCheckEscalationAnswerFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * データ入力Wチェック　却下実行
     * @summary データ入力Wチェック　却下実行
     * @param {DataInputWCheckRejectFormResponse} dataInputWCheckRejectFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingApi
     */
    public dataInputWCheckReject(dataInputWCheckRejectFormResponse: DataInputWCheckRejectFormResponse, options?: AxiosRequestConfig) {
        return ShoppingApiFp(this.configuration).dataInputWCheckReject(dataInputWCheckRejectFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * データ入力Wチェック　再提出実行
     * @summary データ入力Wチェック　再提出実行
     * @param {DataInputWCheckResubmitFormResponse} dataInputWCheckResubmitFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingApi
     */
    public dataInputWCheckResubmit(dataInputWCheckResubmitFormResponse: DataInputWCheckResubmitFormResponse, options?: AxiosRequestConfig) {
        return ShoppingApiFp(this.configuration).dataInputWCheckResubmit(dataInputWCheckResubmitFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ショッピング表示制御
     * @summary ショッピング表示制御
     * @param {number} applyId 
     * @param {boolean} isDataInputEscalationAnswer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingApi
     */
    public shoppingDisplayControl(applyId: number, isDataInputEscalationAnswer: boolean, options?: AxiosRequestConfig) {
        return ShoppingApiFp(this.configuration).shoppingDisplayControl(applyId, isDataInputEscalationAnswer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 税率コードマスタ
     * @summary 税率コードマスタ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingApi
     */
    public taxRateList(options?: AxiosRequestConfig) {
        return ShoppingApiFp(this.configuration).taxRateList(options).then((request) => request(this.axios, this.basePath));
    }
}
