import React, { useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { Form, Row, Col, Table } from 'react-bootstrap';
import { createTestId, uuid } from '../../../utils/functions';
import { useLargeState } from '../../../hooks/useLargeState';
import { Button } from '../../atoms/Button';
import { Modal } from '../../molecules/Modal';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import {
  MonitorRuleApi,
  MonitorRuleSetListOutputResponse,
  CommonMasterListApi,
  CommonMasterListOutputResponse,
} from '../../../api-client';
import { RecommendFloatingLabel } from '../../molecules/RecommendFloatingLabel';

const sampleAllClient = [...Array(5)].map((_, i) => {
  return { id: i + 1, name: `クライアント${i + 1}` };
});

const getSampleSetList = (length: number) => {
  return [...Array(length)].map((_, i) => {
    return {
      monitorRuleSetId: i + 1,
      monitorRuleSetName: `モニタールールセット名${i + 1}`,
      clientList: ['株式会社テストクライアント1', '株式会社テストクライアント2', '株式会社テストクライアント3'],
    };
  });
};

const sampleGetData = {
  allClient: sampleAllClient,
  setList: getSampleSetList(51),
};

export interface Props {
  isModal: boolean;
  onSave: (id: number, name: string) => void;
  onHide: () => void;
}

export interface State {
  api: MonitorRuleApi;
  commonApi: CommonMasterListApi;
  currentRuleSetId: number | null;
  currentRuleSetName: string;
  clientInput: string;
  monitorRuleInput: string;
  clientOptions: CommonMasterListOutputResponse[];
  monitorRuleOptions: string[];
  list: MonitorRuleSetListOutputResponse[];
  listPerPage: MonitorRuleSetListOutputResponse[];
  currentPage: number;
  totalPage: number;
}

const MAX_RECORD_PER_PAGE = 100;

export const MonitorRuleSetModal: React.VFC<Props> = ({ isModal, onSave, onHide }) => {
  const testid = createTestId(MonitorRuleSetModal);

  const {
    state: $,
    mergeState,
    onChangeSet,
  } = useLargeState<State>({
    api: new MonitorRuleApi(),
    commonApi: new CommonMasterListApi(),
    currentRuleSetId: null,
    currentRuleSetName: '',
    clientInput: '',
    monitorRuleInput: '',
    clientOptions: [],
    monitorRuleOptions: [],
    list: [],
    listPerPage: [],
    currentPage: 1,
    totalPage: 1,
  });

  const callApi = useCallback(
    (clientId?: number, monitorRuleSetId?: number, monitorRuleName?: string) => {
      $.api.monitorRuleSetList(monitorRuleName).then((res: AxiosResponse<MonitorRuleSetListOutputResponse[]>) => {
        // const { setList: list } = sampleGetData;
        const list = res.data.sort((a, b) => b.monitorRuleSetId - a.monitorRuleSetId);
        mergeState({
          list,
          listPerPage: list.slice(0, MAX_RECORD_PER_PAGE),
          currentPage: 1,
          totalPage: Math.ceil(Number(list?.length) / MAX_RECORD_PER_PAGE),
        });
        if ($.monitorRuleOptions.length === 0) {
          mergeState({ monitorRuleOptions: list.map(({ monitorRuleSetName }) => monitorRuleSetName) });
        }
      });
    },
    [mergeState, $.api]
  );

  useEffect(() => {
    if (!isModal || $.list.length !== 0) return;

    if ($.clientOptions.length === 0) {
      $.commonApi.commonMasterList('client').then((res: AxiosResponse<CommonMasterListOutputResponse[]>) => {
        mergeState({ clientOptions: res.data });
      });
    }
    callApi();
  }, [isModal, mergeState, $.commonApi, callApi]);

  // useEffect(() => {
  //   console.log(state);
  // }, [state]);

  const onSearch = () => {
    let clientId;
    if ($.clientInput) {
      if (Number.isInteger(Number($.clientInput))) {
        clientId = Number($.clientInput);
      } else {
        clientId = $.clientOptions.find(({ name }) => name === $.clientInput)?.id;
      }
    }

    let monitorRuleSetId;
    let monitorRuleName;
    if ($.monitorRuleInput) {
      if (Number.isInteger(Number($.monitorRuleInput))) {
        monitorRuleSetId = Number($.monitorRuleInput);
      } else {
        monitorRuleName = $.monitorRuleInput;
      }
    }

    callApi(clientId, monitorRuleSetId, monitorRuleName);
  };

  return (
    <Modal
      onHide={onHide}
      isModal={isModal}
      size="lg"
      closeButton
      centered
      scrollable
      body={
        <>
          <div className="d-flex justify-content-end mb-4">
            <Button variant="link" className="ms-2" onClick={onHide} data-testid={testid('cancel')}>
              キャンセル
            </Button>
            <Button
              className="ms-2"
              data-testid={testid('save-button')}
              disabled={!$.currentRuleSetId}
              onClick={() => onSave($.currentRuleSetId as number, $.currentRuleSetName)}
            >
              保存
            </Button>
          </div>

          <Form.Group className="bg-light p-4 mb-4">
            <Row className="mb-3">
              <Col>
                <RecommendFloatingLabel
                  value={$.clientInput}
                  label="クライアント"
                  options={$.clientOptions.map(({ name }) => name)}
                  onChange={onChangeSet('clientInput')}
                  onDelete={() => mergeState({ clientInput: '' })}
                  onClickItem={(e) => mergeState({ clientInput: e.currentTarget.innerHTML })}
                />
              </Col>
              <Col>
                <RecommendFloatingLabel
                  value={$.monitorRuleInput}
                  label="モニタールールセット"
                  options={$.monitorRuleOptions}
                  onChange={onChangeSet('monitorRuleInput')}
                  onDelete={() => mergeState({ monitorRuleInput: '' })}
                  onClickItem={(e) => mergeState({ monitorRuleInput: e.currentTarget.innerHTML })}
                />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col className="col-4">
                <Button className="w-100 py-2" onClick={onSearch} data-testid={testid('search-button')}>
                  検索
                </Button>
              </Col>
            </Row>
          </Form.Group>

          <div className="mb-4" data-testid={testid('pagination')}>
            <PaginationWithEllipsis
              currentPage={$.currentPage}
              totalPage={$.totalPage}
              handleClick={(page) => {
                if (!page || page > $.totalPage) return;
                mergeState({
                  listPerPage: $.list.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE),
                  currentPage: page,
                });
              }}
            />
          </div>

          <Table className="mb-4" data-testid={testid('table')}>
            <thead>
              <tr className="align-middle">
                <th>&nbsp;</th>
                <th>モニタールールセット名</th>
                <th>クライアント</th>
              </tr>
            </thead>
            <tbody>
              {$.listPerPage.map(({ monitorRuleSetId, monitorRuleSetName, clientList }, i) => (
                <tr key={uuid()} className="align-middle">
                  <td>
                    <Form.Check
                      id={String(monitorRuleSetId)}
                      type="radio"
                      name="radioGroup01"
                      data-testid={testid('radio', i)}
                      checked={$.currentRuleSetId === monitorRuleSetId}
                      onChange={() =>
                        mergeState({ currentRuleSetId: monitorRuleSetId, currentRuleSetName: monitorRuleSetName })
                      }
                    />
                  </td>
                  <td>{monitorRuleSetName}</td>
                  <td>
                    {clientList.map((v) => (
                      <span key={v}>
                        {v}
                        <br />
                      </span>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      }
    />
  );
};
