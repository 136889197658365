import { faEdit, faPlus, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { useLargeState } from '../../../../../hooks/useLargeState';
import { CommentFormState } from '../../../../../store/correctionStore';
import { debugLog, dial, escapeRegexLiterals, uuid, when } from '../../../../../utils/functions';
import { Button, ButtonVariant } from '../../../../atoms/Button';
import { SideBy } from '../../../../atoms/SideBy';
import { AnswerMethod, TemplateFormBodyState } from '../../../../pages/Correction/constants';
import { nativeAlert } from '../../../../pages/Correction/utils';
import { CommonBody, TemplateList } from '../../TemplateForm/bodies/CommonBody';

// TODO: [添削] テスト未実装
export const CommentEditor: React.FC<{
  title: string;
  templateName?: string;
  templateList?: TemplateList;
  onChange: (state?: TemplateFormBodyState) => void;
  // linkColor?: string;
  buttonVariant?: ButtonVariant;
  styleClassName?: string;
  formState?: CommentFormState;
  newForm?: CommentFormState;
  placeholder?: string;
  contentCharLengthMin?: number;
  contentCharLengthMax?: number;
  isInputOnlyNumber?: boolean;
  isEnquete?: boolean;
  answerMethodCode?: number;
}> = ({
  title,
  templateName,
  templateList,
  onChange,
  // linkColor,
  buttonVariant,
  styleClassName,
  formState,
  newForm,
  placeholder,
  contentCharLengthMin,
  contentCharLengthMax,
  isInputOnlyNumber,
  isEnquete,
  answerMethodCode,
}) => {
  const {
    state: f,
    mergeState,
    setState,
  } = useLargeState<{
    phase: 'INIT' | 'EDIT' | 'SHOW';
    form?: TemplateFormBodyState;
  }>({
    phase: formState ? 'SHOW' : 'INIT',
    form: formState
      ? {
          content: formState.content ?? '',
          templateId: formState.tempId,
          templateContent: (templateList ?? []).find((t) => t.templateId === formState.tempId)?.templateText ?? '',
        }
      : undefined,
  });
  const onChangeNgForm = useCallback(
    (state) => {
      debugLog(state);
      mergeState({ form: state });
    },
    [mergeState]
  );
  const [contentMin, contentMax] = useMemo(
    () => [contentCharLengthMin ?? 0, contentCharLengthMax ?? Number.MAX_SAFE_INTEGER],
    [contentCharLengthMin, contentCharLengthMax]
  );

  const highlightStringDifferences = (base: string | undefined, comparison: string | undefined) => {
    const notDiff = [];
    let nextCharAlsoSame = false;

    if (!base || !comparison) return;
    // 差分箇所のインデックスを抽出する
    for (let i = 0; i < comparison.length; i += 1) {
      const twoChars = comparison[i] + comparison[i + 1];
      if (base.match(escapeRegexLiterals(twoChars))) {
        notDiff.push(i);
        nextCharAlsoSame = true;
      } else if (nextCharAlsoSame) {
        notDiff.push(i);
        nextCharAlsoSame = false;
      }
    }

    const markedComparison = [];
    for (let i = 0; i < comparison.length; i += 1) {
      if (!notDiff.includes(i)) {
        markedComparison.push(<span style={{ backgroundColor: '#ff7f7f' }}>{comparison[i]}</span>);
      } else {
        markedComparison.push(comparison[i]);
      }
    }

    return markedComparison;
  };

  /**
   * 入力された文字から不要なもの(空白と改行)を省いてカウントする
   * @param v
   */
  const inputStrCount = (v?: string) => {
    if (v === undefined) return 0;
    return v.replace(/\r?\n/g, '').replace(/\s/g, '').length;
  };

  const getCounterColor = () => {
    const counter = f.form?.content.length ?? 0;
    if (counter >= contentCharLengthMin!) return 'green';
    return 'red';
  };
  return (
    <>
      {dial(f.phase, {
        INIT: (
          <Button
            variant={buttonVariant ?? 'link'}
            style={{ display: 'block', width: '200px' }}
            className={buttonVariant ? undefined : styleClassName}
            onClick={() => {
              // mergeState({ phase: 'EDIT' });
              setState(({ form }) => {
                const phase = 'EDIT';
                if (newForm)
                  return {
                    phase,
                    form: {
                      content: newForm.content,
                      templateContent:
                        (templateList ?? []).find((t) => t.templateId === newForm.tempId)?.templateText ?? '',
                    },
                  };
                return { phase, form };
              });
            }}
          >
            <span>
              <FontAwesomeIcon icon={faPlus} /> {title}
            </span>
          </Button>
        ),
        EDIT: (
          <>
            <Card body className={styleClassName}>
              {when(
                contentCharLengthMin != null,
                <pre style={{ color: getCounterColor() }}>
                  {inputStrCount(f.form?.content)}／{contentCharLengthMin}
                </pre>
              )}
              <CommonBody
                name={templateName}
                templateList={templateList ?? []}
                onChange={onChangeNgForm}
                // linkColor={linkColor}
                buttonVariant="light"
                initContent={f.form?.content}
                initTempId={f.form?.templateId}
                placeholder={placeholder}
                answerMethodCode={answerMethodCode}
              />
              <SideBy>
                <Button
                  variant="light"
                  onClick={() => {
                    if (!inputStrCount(f.form?.content)) {
                      nativeAlert('文字を入力してください');
                      return;
                    }
                    if (inputStrCount(f.form?.content) < contentMin) {
                      nativeAlert('文字列が短すぎます');
                      return;
                    }
                    if (inputStrCount(f.form?.content) > contentMax) {
                      nativeAlert('文字列が長すぎます');
                      return;
                    }
                    if (
                      answerMethodCode === AnswerMethod.TIME &&
                      !f.form?.content.match(/^([01][0-9]|2[0-3]):[03]0$/)
                    ) {
                      nativeAlert('時刻の入力は30分刻みでhh:mm形式です');
                      return;
                    }
                    if (answerMethodCode === AnswerMethod.DATE && !f.form?.content.match(/^\d{4}-\d{2}-\d{2}$/)) {
                      nativeAlert('日付の入力はyyyy-mm-dd形式です');
                      return;
                    }
                    if (isInputOnlyNumber && Number.isNaN(Number(f.form?.content))) {
                      nativeAlert('半角数字のみ入力出来ます');
                      return;
                    }
                    mergeState({ phase: 'SHOW' });
                    onChange(f.form);
                  }}
                  disabled={!f.form?.content}
                >
                  <FontAwesomeIcon icon={faSave} /> 保存
                </Button>
                <Button
                  variant="light"
                  onClick={() => {
                    mergeState({ phase: 'INIT', form: undefined });
                    onChange(undefined);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </SideBy>
            </Card>
          </>
        ),
        SHOW: (
          <>
            <Card body className={styleClassName}>
              <div>
                {isEnquete !== undefined && isEnquete ? (
                  <div>
                    <p>{highlightStringDifferences(newForm?.content, f.form?.content)}</p>
                  </div>
                ) : (
                  <>
                    {(f.form?.content ?? '').split(/\n/).map((line, i, { length }) => (
                      <span key={uuid()}>
                        {line}
                        {i !== length - 1 ? <br /> : <></>}
                      </span>
                    ))}
                  </>
                )}
              </div>
              <Button
                variant="light"
                onClick={() => {
                  mergeState({ phase: 'EDIT' });
                }}
              >
                <FontAwesomeIcon icon={faEdit} /> 編集
              </Button>
            </Card>
          </>
        ),
      })}
    </>
  );
};
