import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
/* eslint-disable import/no-cycle */
import { AxiosResponse } from 'axios';
import { OemBalanceApi, OemBalanceListOutputResponse } from '../../../api-client';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId } from '../../../utils/functions';

import { Title } from '../../atoms/Title';
import { TITLE } from '../../../constants/Title';
import { Url } from '../../../constants/Url';

/* eslint-enable import/no-cycle */

export interface State {
  oemBalanceApi: OemBalanceApi;
}

export const OemBalanceListPage: React.VFC = () => {
  const testid = createTestId(OemBalanceListPage);
  const history = useHistory();

  const [data, setData] = useState<OemBalanceListOutputResponse[]>([]);

  const { state: $ } = useLargeState<State>({
    oemBalanceApi: new OemBalanceApi(),
  });

  useEffect(() => {
    $.oemBalanceApi.oemBalanceList().then((res: AxiosResponse<OemBalanceListOutputResponse[]>) => {
      setData(res.data);
    });
  }, []);

  const appStyle: React.CSSProperties = {
    textAlign: 'left',
  };

  const childrenContainerStyle: React.CSSProperties = {
    height: '200px',
    overflowY: 'auto',
    backgroundColor: '#fff',
    padding: '5px',
    border: '1px solid #ddd',
    borderRadius: '4px',
  };

  const idColumnStyle: React.CSSProperties = {
    width: '100px',
    textAlign: 'center',
  };

  const childrenColumnStyle: React.CSSProperties = {
    width: '400px',
  };

  const tableStyle: React.CSSProperties = {
    width: '1000px',
    border: '1px solid',
  };

  const tableHeaderStyle: React.CSSProperties = {
    backgroundColor: '#FFECD5',
  };

  return (
    <>
      <Title className="mb-4" data-testid={testid('title')}>
        {TITLE.KEISAI.OEM_BALANCE}
      </Title>

      <div style={appStyle}>
        <Link to={`${Url.KEISAI.OEM_BALANCE}`} className="btn btn-primary mb-3">
          新規設定
        </Link>
        <Table bordered style={tableStyle}>
          <thead style={tableHeaderStyle}>
            <tr>
              <th style={idColumnStyle}>掲載ずらし日数</th>
              <th style={childrenColumnStyle}>対象店舗</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                <td style={idColumnStyle}>
                  {item.slideDay}日<br />
                  <Link to={`${Url.KEISAI.OEM_BALANCE}/${item.id}`}>編集</Link>
                </td>
                <td>
                  <div style={childrenContainerStyle}>
                    {item.bases.map((child) => (
                      <div key={child.id}>
                        {child.id}: {child.name}
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};
