import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import AccordionItem from 'react-bootstrap/AccordionItem';
import { useSafeContext } from '../../../../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../../../../store/correctionStore';
import { uuid } from '../../../../../../../utils/functions';
import { TinyHead } from '../../../../../../atoms/TinyHead';
import { MONITOR_RULE_TYPES } from '../../../../../../pages/Correction/constants';
import sass from '../../../../../../../scss/templates/CorrectionTemplate.module.scss';
import { ImageRuleDtoResponse } from '../../../../../../../api-client';

export const EvidenceList: React.FC = () => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);

  const getMonitorRuleImage = (imageRules: ImageRuleDtoResponse[]) => {
    const images: JSX.Element[] = [];
    imageRules.map((v) => {
      v.imageRuleImages?.forEach((image) => {
        images.push(
          <div>
            {image.image.path1 && (
              <img key={uuid()} src={image.image.path1} className={sass.monitor_rule_image} alt="" />
            )}
          </div>
        );
      });
    });
    return images;
  };
  return (
    <>
      {$.res_correctionCheckDisplay?.monitorRuleList
        .filter(({ type }) => type === MONITOR_RULE_TYPES.INVESTIGATION_EVIDENCE.code)
        .map((v) => (
          <Card body key={uuid()}>
            <TinyHead>調査証明</TinyHead>
            <div>
              <input
                type="checkbox"
                id="switch_display_survey_proof_monitor_rule"
                className={sass.switch_display_survey_proof_monitor_rule}
              />
              <label htmlFor="switch_display_survey_proof_monitor_rule" className={sass.survey_proof_monitor_rule}>
                {v.afterApplyContent?.split(/\n/).map((line, i, { length }) => (
                  <span key={uuid()}>
                    {line}
                    {i < length ? <br /> : <></>}
                  </span>
                ))}
                {getMonitorRuleImage(v.imageRules)}
              </label>
            </div>
          </Card>
        ))}
    </>
  );
};
