import React, { useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import { TITLE } from '../../../constants/Title';
import { Title } from '../../atoms/Title';
import { createTestId, execDownload } from '../../../utils/functions';
import { Dropzone } from '../../molecules/Dropzone';
import { useLargeState } from '../../../hooks/useLargeState';
import {
  BulkStatusRsvFileCheckApi,
  BulkStatusRsvFileCheckOutputResponse,
  BulkStatusRsvListItemOutputResponse,
  BulkStatusRsvTemplateFileDownloadApi,
  IncResultOutputResponse,
  MonitorStatusRsvCreateApi,
  MonitorStatusRsvCreateFormResponse,
  MonitorStatusRsvFormResponse,
} from '../../../api-client';
import { Alert } from '../../atoms/Alert';
import { Url } from '../../../constants/Url';

export interface State {
  createApi: MonitorStatusRsvCreateApi;
  templateDownloadApi: BulkStatusRsvTemplateFileDownloadApi;
  fileCheckApi: BulkStatusRsvFileCheckApi;
  checkResult: boolean;
  resultMessages: string[];
  list: {
    monitorBaseId?: number;
    monitorName?: string;
    shopName?: string;
    clientName?: string;
    date?: string;
    reservationStatus?: Status;
    hidingReasonType?: HidingReason;
    nowReservationStatus?: Status;
    nowHidingReasonType?: HidingReason;
    nextReservationDate?: string;
    nextReservationStatus?: Status;
    errors: string[];
    row: number;
  }[];
  updResult: IncResultOutputResponse;
}

const STATUS = {
  preparation: 1,
  release: 2,
  limitedRelease: 3,
  private: 4,
} as const;

type Status = typeof STATUS[keyof typeof STATUS];

const HIDING_REASON_TYPE = {
  userContact: 0,
  pause: 1,
  modification: 2,
  fancrewDecision: 3,
  spotContractExpiration: 4,
  cancellation: 5,
  closed: 6,
  batch: 7,
  releaseOk: 8,
} as const;

type HidingReason = typeof HIDING_REASON_TYPE[keyof typeof HIDING_REASON_TYPE];

export const BulkStatusReservationPage: React.VFC = () => {
  const testid = createTestId(BulkStatusReservationPage);
  const history = useHistory();

  const { state: $, mergeState } = useLargeState<State>({
    createApi: new MonitorStatusRsvCreateApi(),
    templateDownloadApi: new BulkStatusRsvTemplateFileDownloadApi(),
    fileCheckApi: new BulkStatusRsvFileCheckApi(),
    checkResult: false,
    resultMessages: [],
    list: [],
    updResult: { result: false },
  });

  const [checkErrors, setCheckErrors] = useState<string[]>([]);

  const onTemplateDownload = () => {
    $.templateDownloadApi.bulkStatusRsvTemplateFileDownload().then((res: AxiosResponse<string>) => {
      execDownload(
        res.data,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        '【一括ステータス予約】テンプレートダウンロード.xlsx'
      ).catch(() => {
        history.push(Url.COMMON_ERROR);
      });
    });
  };

  const onDrop = (files: File[]) => {
    if (files === null || files.length === 0) return;
    const file = files[0];

    setCheckErrors([]);

    const reader = new FileReader();

    reader.onload = () => {
      $.fileCheckApi
        .bulkStatusRsvFileCheck({ file: reader.result?.toString().replace(/data:.*\/.*;base64,/, '') || '' })
        .then((res: AxiosResponse<BulkStatusRsvFileCheckOutputResponse>) => {
          const { result, resultMessageList, list } = res.data;

          mergeState({
            checkResult: result,
            resultMessages: resultMessageList,
            list: list.map((item) => {
              return {
                ...item,
                reservationStatus: item.reservationStatus as Status,
                hidingReasonType: item.hidingReasonType as HidingReason,
                nowReservationStatus: item.nowReservationStatus as Status,
                nowHidingReasonType: item.nowHidingReasonType as HidingReason,
                nextReservationStatus: item.nextReservationStatus as Status,
              };
            }),
          });
          if (list.some(({ errors }) => errors.length)) {
            setCheckResultErrors(list);
          }
        });
    };
    reader.readAsDataURL(file);
  };

  const onSubmit = () => {
    // 一括ステータス予約は専用の掲載依頼に紐づける。(id=0)
    const bulkStatusRsvPostingRequestId = 0;
    const createParam: MonitorStatusRsvCreateFormResponse = {
      monitorStatusRsvs: $.list.map((item) => {
        return {
          monitorBaseId: item.monitorBaseId,
          postingRequestId: bulkStatusRsvPostingRequestId,
          applyingAt: item.date?.replace(/[/]/g, '-'),
          status: item.reservationStatus,
          hidingReasonType: item.hidingReasonType,
        } as MonitorStatusRsvFormResponse;
      }),
    };

    $.createApi
      .monitorStatusRsvCreate(createParam)
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        mergeState({ updResult: res.data });
      })
      .catch((error: IncResultOutputResponse) => {
        mergeState({ updResult: error });
      });
  };

  const setCheckResultErrors = (list: BulkStatusRsvListItemOutputResponse[]) => {
    let errorMessages: string[] = [];
    list.forEach(({ errors, row }) => {
      if (errors.length) {
        let message = `${row}行目：`;
        errors.forEach((error) => {
          message += `${error}`;
        });
        errorMessages = [...errorMessages, message];
      }
    });
    setCheckErrors(errorMessages);
  };

  const toStatusDisplayName = (status: Status): string => {
    switch (status) {
      case STATUS.preparation:
        return '掲載準備中';
      case STATUS.release:
        return '公開中';
      case STATUS.limitedRelease:
        return '限定公開中';
      case STATUS.private:
        return '非公開中';
      default:
        return '';
    }
  };

  const toHidingReasonDisplayName = (hidingReason: HidingReason): string => {
    switch (hidingReason) {
      case HIDING_REASON_TYPE.userContact:
        return 'ユーザ連絡';
      case HIDING_REASON_TYPE.pause:
        return '休止';
      case HIDING_REASON_TYPE.modification:
        return '修正_隔月管理';
      case HIDING_REASON_TYPE.fancrewDecision:
        return 'ファンくる判断（つるっと）';
      case HIDING_REASON_TYPE.spotContractExpiration:
        return 'スポット契約満了（自動更新なし契約）';
      case HIDING_REASON_TYPE.cancellation:
        return '解約（自動更新あり契約）';
      case HIDING_REASON_TYPE.closed:
        return '閉店';
      case HIDING_REASON_TYPE.batch:
        return '非表示バッチ';
      case HIDING_REASON_TYPE.releaseOk:
        return '公開OK';
      default:
        return '';
    }
  };

  return (
    <>
      {$.list.length && $.checkResult ? (
        <Title className="mb-4" data-testid={testid('title-confirm')}>
          {TITLE.KEISAI.BULK_STATUS_RESERVATION_CONFIRM}
        </Title>
      ) : (
        <Title className="mb-4" data-testid={testid('title')}>
          {TITLE.KEISAI.BULK_STATUS_RESERVATION}
        </Title>
      )}

      {$.updResult.result && (
        <Alert testId={testid('success-alert')} variant="success">
          保存しました。
        </Alert>
      )}

      {$.resultMessages.length > 0 && (
        <Alert testId={testid('check-alert')} variant="danger">
          {$.resultMessages?.map((m, i) => (
            <div key={i}>{m}</div>
          ))}
        </Alert>
      )}

      {checkErrors.length > 0 && (
        <Alert testId={testid('error-messages')} variant="danger">
          {checkErrors.map((error, i) => (
            <div key={i}>{error}</div>
          ))}
        </Alert>
      )}

      {$.updResult.errorMessage && !$.updResult.result && (
        <Alert testId={testid('failure-alert')} variant="danger">
          <pre
            style={{ whiteSpace: 'pre-wrap' }}
          >{`${$.updResult.errorMessage} (エラーコード：${$.updResult.errorCode})`}</pre>
        </Alert>
      )}

      <div className="d-flex justify-content-end mb-4">
        {$.list.length && $.checkResult ? (
          <>
            <Button
              variant="link"
              className="ms-2"
              data-testid={testid('cancel-button')}
              onClick={() => {
                mergeState({
                  list: [],
                  checkResult: false,
                  resultMessages: [],
                  updResult: { result: false },
                });
                setCheckErrors([]);
              }}
            >
              キャンセル
            </Button>
            <Button data-testid={testid('save-button')} className="ms-2" onClick={onSubmit}>
              保存
            </Button>
          </>
        ) : (
          <Button
            variant="outline-secondary"
            data-testid={testid('template-download-button')}
            className="ms-2"
            onClick={() => onTemplateDownload()}
          >
            テンプレートダウンロード
          </Button>
        )}
      </div>
      {$.list.length && $.checkResult ? (
        <div style={{ width: 'calc(100vw - 20rem)', overflowX: 'scroll' }} className="mb-4">
          <Table bordered className="mt-4 text-nowrap" data-testid={testid('table')}>
            <thead>
              <tr>
                <th>モニターベースID</th>
                <th>モニター名</th>
                <th>実店舗名</th>
                <th>クライアント名</th>
                <th>日時</th>
                <th>予約ステータス</th>
                <th>予約の非公開理由</th>
                <th>現在のステータス</th>
                <th>非表示理由</th>
                <th>未来の予約（直近1件）</th>
              </tr>
            </thead>
            <tbody>
              {$.list.map(
                ({
                  monitorBaseId,
                  monitorName,
                  shopName,
                  clientName,
                  date,
                  reservationStatus,
                  hidingReasonType,
                  nowReservationStatus,
                  nowHidingReasonType,
                  nextReservationDate,
                  nextReservationStatus,
                }) => (
                  <tr key={monitorBaseId} className="align-middle">
                    <td>{monitorBaseId}</td>
                    <td>{monitorName}</td>
                    <td>{shopName}</td>
                    <td>{clientName}</td>
                    <td>{date?.replace('T', ' ')}</td>
                    <td>{reservationStatus ? toStatusDisplayName(reservationStatus) : ''}</td>
                    <td>
                      {hidingReasonType || hidingReasonType === 0 ? toHidingReasonDisplayName(hidingReasonType) : ''}
                    </td>
                    <td>{nowReservationStatus ? toStatusDisplayName(nowReservationStatus) : ''}</td>
                    <td>
                      {nowHidingReasonType || nowHidingReasonType === 0
                        ? toHidingReasonDisplayName(nowHidingReasonType)
                        : ''}
                    </td>
                    <td>
                      {nextReservationDate?.replace('T', ' ')}{' '}
                      {nextReservationStatus ? toStatusDisplayName(nextReservationStatus) : ''}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </div>
      ) : (
        <Dropzone onDrop={onDrop} type="excel" />
      )}
    </>
  );
};
