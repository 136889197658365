/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ExecutiveStaffApplySearchOutputResponse } from '../domains';
// @ts-ignore
import { QcCrewApplySearchOutputResponse } from '../domains';
/**
 * ApplySearchApi - axios parameter creator
 * @export
 */
export const ApplySearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 事務局用案件検索画面を表示する
         * @summary 事務局用案件検索画面表示
         * @param {number} targetStatus 
         * @param {number} pageNum 
         * @param {number} pagePerItems 
         * @param {number} [applyId] 
         * @param {number} [contractShopId] 
         * @param {number} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executiveStaffApplySearch: async (targetStatus: number, pageNum: number, pagePerItems: number, applyId?: number, contractShopId?: number, clientId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'targetStatus' is not null or undefined
            assertParamExists('executiveStaffApplySearch', 'targetStatus', targetStatus)
            // verify required parameter 'pageNum' is not null or undefined
            assertParamExists('executiveStaffApplySearch', 'pageNum', pageNum)
            // verify required parameter 'pagePerItems' is not null or undefined
            assertParamExists('executiveStaffApplySearch', 'pagePerItems', pagePerItems)
            const localVarPath = `/api/correct/apply-search/executive-staff`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (applyId !== undefined) {
                localVarQueryParameter['apply-id'] = applyId;
            }

            if (contractShopId !== undefined) {
                localVarQueryParameter['contract-shop-id'] = contractShopId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['client-id'] = clientId;
            }

            if (targetStatus !== undefined) {
                localVarQueryParameter['target-status'] = targetStatus;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pagePerItems !== undefined) {
                localVarQueryParameter['pagePerItems'] = pagePerItems;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * QCクルー用案件検索画面を表示する
         * @summary QCクルー用案件検索画面表示
         * @param {number} targetStatus 
         * @param {number} pageNum 
         * @param {number} pagePerItems 
         * @param {number} [applyId] 
         * @param {number} [contractShopId] 
         * @param {number} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        qcCrewApplySearch: async (targetStatus: number, pageNum: number, pagePerItems: number, applyId?: number, contractShopId?: number, clientId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'targetStatus' is not null or undefined
            assertParamExists('qcCrewApplySearch', 'targetStatus', targetStatus)
            // verify required parameter 'pageNum' is not null or undefined
            assertParamExists('qcCrewApplySearch', 'pageNum', pageNum)
            // verify required parameter 'pagePerItems' is not null or undefined
            assertParamExists('qcCrewApplySearch', 'pagePerItems', pagePerItems)
            const localVarPath = `/api/correct/apply-search/qc-crew`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (applyId !== undefined) {
                localVarQueryParameter['apply-id'] = applyId;
            }

            if (contractShopId !== undefined) {
                localVarQueryParameter['contract-shop-id'] = contractShopId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['client-id'] = clientId;
            }

            if (targetStatus !== undefined) {
                localVarQueryParameter['target-status'] = targetStatus;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pagePerItems !== undefined) {
                localVarQueryParameter['pagePerItems'] = pagePerItems;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplySearchApi - functional programming interface
 * @export
 */
export const ApplySearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplySearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 事務局用案件検索画面を表示する
         * @summary 事務局用案件検索画面表示
         * @param {number} targetStatus 
         * @param {number} pageNum 
         * @param {number} pagePerItems 
         * @param {number} [applyId] 
         * @param {number} [contractShopId] 
         * @param {number} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async executiveStaffApplySearch(targetStatus: number, pageNum: number, pagePerItems: number, applyId?: number, contractShopId?: number, clientId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExecutiveStaffApplySearchOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.executiveStaffApplySearch(targetStatus, pageNum, pagePerItems, applyId, contractShopId, clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * QCクルー用案件検索画面を表示する
         * @summary QCクルー用案件検索画面表示
         * @param {number} targetStatus 
         * @param {number} pageNum 
         * @param {number} pagePerItems 
         * @param {number} [applyId] 
         * @param {number} [contractShopId] 
         * @param {number} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async qcCrewApplySearch(targetStatus: number, pageNum: number, pagePerItems: number, applyId?: number, contractShopId?: number, clientId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QcCrewApplySearchOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.qcCrewApplySearch(targetStatus, pageNum, pagePerItems, applyId, contractShopId, clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplySearchApi - factory interface
 * @export
 */
export const ApplySearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplySearchApiFp(configuration)
    return {
        /**
         * 事務局用案件検索画面を表示する
         * @summary 事務局用案件検索画面表示
         * @param {number} targetStatus 
         * @param {number} pageNum 
         * @param {number} pagePerItems 
         * @param {number} [applyId] 
         * @param {number} [contractShopId] 
         * @param {number} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executiveStaffApplySearch(targetStatus: number, pageNum: number, pagePerItems: number, applyId?: number, contractShopId?: number, clientId?: number, options?: any): AxiosPromise<ExecutiveStaffApplySearchOutputResponse> {
            return localVarFp.executiveStaffApplySearch(targetStatus, pageNum, pagePerItems, applyId, contractShopId, clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * QCクルー用案件検索画面を表示する
         * @summary QCクルー用案件検索画面表示
         * @param {number} targetStatus 
         * @param {number} pageNum 
         * @param {number} pagePerItems 
         * @param {number} [applyId] 
         * @param {number} [contractShopId] 
         * @param {number} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        qcCrewApplySearch(targetStatus: number, pageNum: number, pagePerItems: number, applyId?: number, contractShopId?: number, clientId?: number, options?: any): AxiosPromise<QcCrewApplySearchOutputResponse> {
            return localVarFp.qcCrewApplySearch(targetStatus, pageNum, pagePerItems, applyId, contractShopId, clientId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplySearchApi - object-oriented interface
 * @export
 * @class ApplySearchApi
 * @extends {BaseAPI}
 */
export class ApplySearchApi extends BaseAPI {
    /**
     * 事務局用案件検索画面を表示する
     * @summary 事務局用案件検索画面表示
     * @param {number} targetStatus 
     * @param {number} pageNum 
     * @param {number} pagePerItems 
     * @param {number} [applyId] 
     * @param {number} [contractShopId] 
     * @param {number} [clientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplySearchApi
     */
    public executiveStaffApplySearch(targetStatus: number, pageNum: number, pagePerItems: number, applyId?: number, contractShopId?: number, clientId?: number, options?: AxiosRequestConfig) {
        return ApplySearchApiFp(this.configuration).executiveStaffApplySearch(targetStatus, pageNum, pagePerItems, applyId, contractShopId, clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * QCクルー用案件検索画面を表示する
     * @summary QCクルー用案件検索画面表示
     * @param {number} targetStatus 
     * @param {number} pageNum 
     * @param {number} pagePerItems 
     * @param {number} [applyId] 
     * @param {number} [contractShopId] 
     * @param {number} [clientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplySearchApi
     */
    public qcCrewApplySearch(targetStatus: number, pageNum: number, pagePerItems: number, applyId?: number, contractShopId?: number, clientId?: number, options?: AxiosRequestConfig) {
        return ApplySearchApiFp(this.configuration).qcCrewApplySearch(targetStatus, pageNum, pagePerItems, applyId, contractShopId, clientId, options).then((request) => request(this.axios, this.basePath));
    }
}
