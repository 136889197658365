import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Accordion, Button, Card } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import { formatISODate, uuid, when } from '../../../../utils/functions';
import { BadgeType } from '../../../atoms/BadgeHead';
import { EscalationRejectStatus } from '../../../pages/Correction/constants';
// eslint-disable-next-line import/no-cycle
import { CorrectApi, ShoppingApi } from '../../../../api-client';
import type {
  CorrectNgCommentOutputResponse,
  CorrectionTemplateListOutputResponse,
  DataInputWCheckEscalationAnswerFormResponse,
  ErrorObjectResponse,
  EscalationInfoDisplayOutputResponse,
  IncResultOutputResponse,
  InfoForApplyOutputResponse,
  MonitorRuleForCorrectionPageDtoResponse,
  ReceiptReadResultFormResponse,
  RejectReasonOutputResponse,
} from '../../../../api-client';
import { DATETIME_DISPLAY_FORMAT } from '../../../../Constants';
import { Thread } from '../Escalation/parts/Thread';
import { Post } from '../Escalation/parts/Post';
import { Situator } from '../Escalation/parts/Situator';
import { TemplateSelectContent } from '../../../molecules/TemplateSelectContent';
import { useLargeState } from '../../../../hooks/useLargeState';
import { HTTPStatusCode } from '../../../../constants/HTTPStatusCode';
import { Url } from '../../../../constants/Url';
import { Modal } from '../../../molecules/Modal';
import { MonitorRule } from '../Main/parts/MonitorRule';
import * as CONSTANTS from '../../../pages/Correction/constants';

interface PageState {
  shoppingApi: ShoppingApi;
  correctApi: CorrectApi;
  answerForm: DataInputWCheckEscalationAnswerFormResponse;
  messages: string[];
  isModal: boolean;
  escalationInfo?: EscalationInfoDisplayOutputResponse[];
}

export const ApplyNgInfoCard: React.FC<{
  infoForApply?: InfoForApplyOutputResponse;
  escalationTemplateList: CorrectionTemplateListOutputResponse[];
  isAnswer: boolean;
  applyId: number;
  receiptReadResultFormList: ReceiptReadResultFormResponse[];
  totalAmountIncludeTax?: number;
  monitorRuleList: MonitorRuleForCorrectionPageDtoResponse[];
}> = React.memo(
  ({
    infoForApply,
    escalationTemplateList,
    isAnswer,
    applyId,
    receiptReadResultFormList,
    totalAmountIncludeTax,
    monitorRuleList,
  }) => {
    const history = useHistory();

    const { state: $, mergeState } = useLargeState<PageState>({
      shoppingApi: new ShoppingApi(),
      correctApi: new CorrectApi(),
      answerForm: {
        applyId,
        receiptReadResultFormList,
        totalAmountIncludeTax,
        escalationInfo: { correctionTemplateId: 0, escalationId: 0, ticketId: 0, answer: '' },
      },
      messages: [],
      isModal: false,
      escalationInfo: [],
    });

    const fetchEscalationData = () => {
      $.correctApi
        .escalationInfoDisplay(Number(applyId), CONSTANTS.CorrectionTargetType.RECEIPT_DATA_INPUT)
        .then((escalationRes: AxiosResponse<Array<EscalationInfoDisplayOutputResponse>>) => {
          if (Object.prototype.hasOwnProperty.call(escalationRes.data, 'errorMessage')) {
            history.push(Url.COMMON_ERROR);
          } else {
            mergeState({ escalationInfo: escalationRes.data });
          }
        })
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        });
    };

    useEffect(() => {
      fetchEscalationData();
    }, []);

    useEffect(() => {
      mergeState({ answerForm: { ...$.answerForm, receiptReadResultFormList, totalAmountIncludeTax } });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [receiptReadResultFormList, totalAmountIncludeTax]);

    const renderNgComment = (correctNgCommentList: CorrectNgCommentOutputResponse[]) => {
      return correctNgCommentList.length > 0 ? (
        correctNgCommentList.map(({ comment, execDateTime, executorName }) => {
          return (
            <div className="mt-2" key={uuid()}>
              <p style={{ whiteSpace: 'pre-wrap' }}>{comment}</p>
              <p className="text-muted">
                {formatISODate(execDateTime, DATETIME_DISPLAY_FORMAT)} {executorName}
              </p>
            </div>
          );
        })
      ) : (
        <>NG履歴はありません</>
      );
    };

    const renderRejectReason = (rejectReason?: RejectReasonOutputResponse) => {
      return rejectReason ? (
        <div className="mt-2">
          <p style={{ whiteSpace: 'pre-wrap' }}>{rejectReason.comment}</p>
          <p className="text-muted">
            {formatISODate(rejectReason.execDateTime, DATETIME_DISPLAY_FORMAT)} {rejectReason.executorName}
          </p>
        </div>
      ) : (
        <>却下理由はありません</>
      );
    };
    const onSubmitAnswer = (escalationId: number, ticketId: number) => {
      const body: DataInputWCheckEscalationAnswerFormResponse = {
        ...$.answerForm,
        escalationInfo: { ...$.answerForm.escalationInfo, escalationId, ticketId },
      };
      $.shoppingApi
        .dataInputWCheckEscalationAnswer(body)
        .then((res: AxiosResponse<IncResultOutputResponse>) => {
          if (res.data.result) {
            mergeState({ isModal: true, messages: ['回答を送信しました'] });
            fetchEscalationData();
          } else {
            history.push(Url.COMMON_ERROR);
          }
        })
        .catch((e) => {
          if (e.response?.status !== HTTPStatusCode.UnprocessableEntity) {
            const errors = [...e.response?.data.errors];
            const messages = errors.map((value: ErrorObjectResponse) => value.message);
            mergeState({ messages, isModal: true });
            return;
          }
          history.push(Url.COMMON_ERROR);
        });
    };

    const renderEscalation = () => {
      return (
        <Card style={{ marginTop: '10px' }}>
          {($.escalationInfo ?? []).map((escalationItem) => {
            const {
              status,
              createAt,
              inquiry: text,
              inquiryPerson,
              inquiryAnswerHistoryList,
              escalationId,
              answer,
              answerPerson,
              answeredAt,
              ticketId,
            } = escalationItem;
            const { roleName = '', id = NaN, name = '' } = inquiryPerson ?? {};
            // エスカレの場合かつ完了じゃないときはステータスが目立つようにしたいので、そのためのBadgeTypeを設定する
            const badgeType = status?.id !== EscalationRejectStatus.COMPLETE ? BadgeType.MUST_ACTION : BadgeType.INFO;
            return (
              <React.Fragment key={escalationId}>
                {/* TODO:B 本当はidでページ内遷移したいが動かないので直す */}
                <div id={escalationId ? `escalationId${escalationId.toString()}` : ''}>
                  <Thread
                    mark={status?.name ?? ''}
                    badgeType={badgeType}
                    posts={
                      <>
                        <Post
                          header="質問"
                          indent={0}
                          posts={[
                            {
                              text,
                              createAt,
                              roleName,
                              id,
                              name,
                            },
                          ]}
                        />
                        <hr />
                        {when(
                          !!answer,
                          <Post
                            header="回答"
                            indent={1}
                            posts={[
                              {
                                text: answer,
                                roleName: answerPerson?.roleName || '',
                                id: answerPerson?.id || 0,
                                name: answerPerson?.name || '',
                                createAt: answeredAt || '',
                              },
                            ]}
                          />
                        )}
                        {when(
                          isAnswer && !answer,
                          <>
                            <TemplateSelectContent
                              templateList={escalationTemplateList.map(({ templateId, templateName, templateText }) => {
                                return { id: templateId, name: templateName, text: templateText };
                              })}
                              templateCode={$.answerForm.escalationInfo.correctionTemplateId}
                              templateText={$.answerForm.escalationInfo.answer}
                              onChangeTemplateCode={(value?: number) =>
                                mergeState({
                                  answerForm: {
                                    ...$.answerForm,
                                    escalationInfo: {
                                      ...$.answerForm.escalationInfo,
                                      correctionTemplateId: value || 0,
                                    },
                                  },
                                })
                              }
                              onChangeTemplateText={(value?: string) =>
                                mergeState({
                                  answerForm: {
                                    ...$.answerForm,
                                    escalationInfo: { ...$.answerForm.escalationInfo, answer: value },
                                  },
                                })
                              }
                            />
                            <Button
                              className="mt-2"
                              onClick={() => {
                                console.log('escalationId', escalationId);
                                onSubmitAnswer(escalationId, ticketId);
                              }}
                            >
                              送信
                            </Button>
                          </>
                        )}
                      </>
                    }
                  />
                  <Situator list={inquiryAnswerHistoryList || []} />
                </div>
              </React.Fragment>
            );
          })}
        </Card>
      );
    };

    return (
      <>
        <Modal
          isModal={$.isModal}
          centered
          closeButton
          onHide={() => mergeState({ isModal: false })}
          body={
            <>
              {$.messages.map((message) => {
                return <div key={uuid()}>{message}</div>;
              })}
              <div className="d-flex justify-content-center m-2">
                <Button onClick={() => mergeState({ isModal: false })}>閉じる</Button>
              </div>
            </>
          }
        />
        <Card className="ms-2 mt-2">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>この応募について</Accordion.Header>
              <Accordion.Body>
                {when(
                  !!infoForApply,
                  <Accordion defaultActiveKey={['monitorRule', 'correctNg', 'correctReject', 'escalation']} alwaysOpen>
                    <Accordion.Item eventKey="monitorRule">
                      <Accordion.Header>モニタールール</Accordion.Header>
                      <Accordion.Body>
                        {monitorRuleList.map((monitorRule) => (
                          <MonitorRule monitorRule={monitorRule} isReadOnly key={uuid()} />
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="correctNg">
                      <Accordion.Header>NG履歴</Accordion.Header>
                      <Accordion.Body>{renderNgComment(infoForApply?.correctNgCommentList || [])}</Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="correctReject">
                      <Accordion.Header>却下理由</Accordion.Header>
                      <Accordion.Body>{renderRejectReason(infoForApply?.rejectReason || undefined)}</Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="escalation">
                      <Accordion.Header>エスカレ履歴</Accordion.Header>
                      <Accordion.Body>{renderEscalation()}</Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card>
      </>
    );
  }
);
