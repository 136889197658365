import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useLargeState } from '../../../hooks/useLargeState';
import { Button } from '../../atoms/Button';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
import { DATETIME_DISPLAY_FORMAT } from '../../../Constants';
// eslint-disable-next-line import/no-cycle
import { CorrectSettingApi } from '../../../api-client';
import type {
  IncResultOutputResponse,
  ErrorObjectResponse,
  ClientCorrectSettingInfoOutputResponse,
  ClientCorrectSettingUpdateFormResponse,
  IncAccountOutputResponse,
} from '../../../api-client';
import { formatISODate, uuid } from '../../../utils/functions';
import { Url } from '../../../constants/Url';
import { CorrectSettingEachClientForms } from '../../organisms/Forms/CorrectSettingEachClientForms';
import { Modal } from '../../molecules/Modal';

type EachClientForm = {
  clientId: number;
  correctionExecutionFlg: boolean;
  doubleCheckFlg: boolean;
  electedPersonList: Array<IncAccountOutputResponse>;
};

export interface CorrectSettingEachClientState {
  api: CorrectSettingApi;
  isLoading: boolean;
  clientInfo: ClientCorrectSettingInfoOutputResponse;
  correctSettingForm: EachClientForm;
  isModal: boolean;
  modalMessage: string[];
  isSubmitting: boolean;
}

export const CorrectSettingEachClientPage: React.FC = () => {
  const { clientId } = useParams<{ clientId: string | undefined }>();
  const history = useHistory();
  const locationState = history.location.state as { prevPage: string };
  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<CorrectSettingEachClientState>({
    api: new CorrectSettingApi(),
    isLoading: false,
    clientInfo: { clientId: 0, clientName: '', electedPersonList: [], selectablePersonList: [], doubleCheckFlg: false },
    correctSettingForm: {
      clientId: Number(clientId),
      correctionExecutionFlg: true,
      doubleCheckFlg: false,
      electedPersonList: [],
    },
    isModal: false,
    modalMessage: [],
    isSubmitting: false,
  });

  const setForms = useBindSet('correctSettingForm');
  const initData = (isInitModalMessage: boolean) => {
    $.api
      .clientCorrectSettingInfo(Number(clientId))
      .then((res: AxiosResponse<ClientCorrectSettingInfoOutputResponse>) => {
        if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
          mergeState({ modalMessage: ['エラーが発生しました。'], isModal: true });
        } else {
          mergeState({ clientInfo: res.data });
          if (isInitModalMessage) {
            mergeState({ modalMessage: [] });
          }
        }
      });
  };
  useEffect(() => {
    mergeState({ isLoading: true });
    if (clientId && Number(clientId) > 0) {
      initData(true);
    }

    mergeState({ isLoading: false });
  }, [$.api, clientId, mergeState]);

  const createUpdateBody = (form: EachClientForm) => {
    const body: ClientCorrectSettingUpdateFormResponse = {
      ...form,
      electedPersonList: form.electedPersonList.map(({ id }) => id),
    };
    return body;
  };

  // 保存
  const onSave = (correctSettingForm: EachClientForm) => {
    mergeState({ isSubmitting: true });
    const body: ClientCorrectSettingUpdateFormResponse = createUpdateBody(correctSettingForm);
    $.api
      .clientCorrectSettingUpdate(body)
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        if (!res.data.result) {
          mergeState({ modalMessage: ['エラーが発生しました。'], isModal: true });
        } else {
          mergeState({ modalMessage: ['保存しました'], isModal: true });
          initData(false);
        }
      })
      .catch((error) => {
        const errors = [...error.response?.data.errors];
        const errorMessages = errors.map((value: ErrorObjectResponse) => value.message);
        mergeState({ modalMessage: errorMessages, isModal: true });
      })
      .finally(() => {
        mergeState({ isSubmitting: false });
      });
  };

  const onClickCancel = () => {
    // eslint-disable-next-line no-prototype-builtins
    if (locationState.hasOwnProperty('prevPage') && locationState.prevPage !== undefined) {
      history.push(locationState.prevPage);
    } else {
      history.push(Url.TENSAKU.CORRECT_SETTING_SEARCH);
    }
  };
  return (
    <>
      {$.isModal && (
        <>
          <p>{$.modalMessage}</p>
          <Modal
            isModal={$.isModal}
            centered
            closeButton
            onHide={() => mergeState({ isModal: false })}
            body={
              <div>
                {$.modalMessage.map((message) => (
                  <div key={uuid()}>{message}</div>
                ))}
              </div>
            }
          />
        </>
      )}
      <LoadingSpinner isLoading={$.isLoading}>
        <div className="d-flex justify-content-end">
          <Button variant="link" onClick={onClickCancel}>
            キャンセル
          </Button>
          <Button
            disabled={$.isSubmitting}
            className="ms-2"
            variant="primary"
            onClick={() => onSave($.correctSettingForm)}
          >
            保存
          </Button>
        </div>
        <div className="mb-4">
          <h4>{$.clientInfo.clientName}</h4>
          <div>
            クライアントID：{$.clientInfo.clientId}{' '}
            {$.clientInfo.updateAt
              ? `最終更新日時 ${formatISODate($.clientInfo.updateAt, DATETIME_DISPLAY_FORMAT)} ${
                  $.clientInfo.updateIncName
                }`
              : ''}
          </div>
        </div>
        <CorrectSettingEachClientForms clientInfo={$.clientInfo} setForms={setForms} />
      </LoadingSpinner>
    </>
  );
};
