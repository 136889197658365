import React from 'react';
import { Form } from 'react-bootstrap';
import type { DisplayPointOutputResponse } from '../../../../../../../api-client';
import { when } from '../../../../../../../utils/functions';
import { TextAnswerEditor } from '../../../commentEditors/TextAnswerEditor';
import { TextHighlighter } from '../parts/TextHighlighter';
import { useSafeContext } from '../../../../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../../../../store/correctionStore';
import { useMergeStateOfCommentEditors } from '../../../../../../pages/Correction/utils';

export const TextQuestion: React.FC<{
  /** 送信に使用するDisplayPoint */
  sendingDisplayPoint: Partial<DisplayPointOutputResponse>;
  isEditable?: boolean;
  answerContent?: string;
  ngWordList: string[];
  contentCharLengthMin?: number;
  contentCharLengthMax?: number;
  isInputOnlyNumber?: boolean | undefined;
  isEnquete?: boolean;
  showChangeShopReviewCheckBoxFlg: boolean;
  shopReviewDisplayFlg: boolean;
  answerMethodCode: number;
  tobSatisfaction?: number;
}> = ({
  sendingDisplayPoint: sdp,
  isEditable,
  answerContent,
  ngWordList,
  contentCharLengthMin,
  contentCharLengthMax,
  isInputOnlyNumber,
  isEnquete,
  showChangeShopReviewCheckBoxFlg,
  shopReviewDisplayFlg,
  answerMethodCode,
  tobSatisfaction,
}) => {
  const {
    largeState: { state: $, setState },
  } = useSafeContext(CorrectionContext);
  const mergeArray = useMergeStateOfCommentEditors(sdp, setState);

  const setDefaultShopReviewFlg = () => {
    const ans = $.commentForms_enqueteText?.find((v) => v.tempId === sdp.targetId);
    if (ans === undefined) {
      return shopReviewDisplayFlg;
    }
    return ans.shopReviewDisplayFlg ?? true;
  };

  const handleClickReviewState = (e: React.ChangeEvent<HTMLInputElement>) => {
    mergeArray(
      {
        content: answerContent || '',
        templateId: sdp.targetId!,
        templateContent: '',
        shopReviewDisplayFlg: e.target.checked,
      },
      'commentForms_enqueteText'
    );
  };

  return (
    <>
      <TextHighlighter answerContent={answerContent} ngWordList={ngWordList} />
      {when(
        !!isEditable,
        <TextAnswerEditor
          sendingDisplayPoint={sdp}
          answerContent={answerContent}
          contentCharLengthMin={contentCharLengthMin}
          contentCharLengthMax={contentCharLengthMax}
          isInputOnlyNumber={isInputOnlyNumber}
          isEnquete={isEnquete}
          answerMethodCode={answerMethodCode}
        />
      )}
      {showChangeShopReviewCheckBoxFlg && (
        <>
          <Form.Group>
            <Form.Check>
              <Form.Check.Label htmlFor="shopReviewQuestion">
                問題ある回答であればチェックボックスのチェックを外してください。
              </Form.Check.Label>
              <Form.Check.Input
                id="shopReviewQuestion"
                type="checkbox"
                checked={setDefaultShopReviewFlg()}
                onChange={(e) => handleClickReviewState(e)}
              />
            </Form.Check>
          </Form.Group>
        </>
      )}
    </>
  );
};
