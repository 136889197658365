/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
// @ts-ignore
import { OemBalanceInfoOutputResponse } from '../domains';
// @ts-ignore
import { OemBalanceListOutputResponse } from '../domains';
// @ts-ignore
import { OemBalanceUpsertFormResponse } from '../domains';
/**
 * OemBalanceApi - axios parameter creator
 * @export
 */
export const OemBalanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * OEM露出を削除する。
         * @summary OEM露出の削除
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oemBalanceDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('oemBalanceDelete', 'id', id)
            const localVarPath = `/api/oem-balance/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * OEM露出情報を取得する
         * @summary OEM露出情報取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oemBalanceInfo: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('oemBalanceInfo', 'id', id)
            const localVarPath = `/api/oem-balance/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * OEM露出一覧を取得する
         * @summary OEM露出一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oemBalanceList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oem-balance-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * OEM露出を登録更新する。
         * @summary OEM露出の登録更新
         * @param {OemBalanceUpsertFormResponse} oemBalanceUpsertFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oemBalanceUpsert: async (oemBalanceUpsertFormResponse: OemBalanceUpsertFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oemBalanceUpsertFormResponse' is not null or undefined
            assertParamExists('oemBalanceUpsert', 'oemBalanceUpsertFormResponse', oemBalanceUpsertFormResponse)
            const localVarPath = `/api/oem-balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oemBalanceUpsertFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OemBalanceApi - functional programming interface
 * @export
 */
export const OemBalanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OemBalanceApiAxiosParamCreator(configuration)
    return {
        /**
         * OEM露出を削除する。
         * @summary OEM露出の削除
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oemBalanceDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oemBalanceDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * OEM露出情報を取得する
         * @summary OEM露出情報取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oemBalanceInfo(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OemBalanceInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oemBalanceInfo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * OEM露出一覧を取得する
         * @summary OEM露出一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oemBalanceList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OemBalanceListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oemBalanceList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * OEM露出を登録更新する。
         * @summary OEM露出の登録更新
         * @param {OemBalanceUpsertFormResponse} oemBalanceUpsertFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oemBalanceUpsert(oemBalanceUpsertFormResponse: OemBalanceUpsertFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oemBalanceUpsert(oemBalanceUpsertFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OemBalanceApi - factory interface
 * @export
 */
export const OemBalanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OemBalanceApiFp(configuration)
    return {
        /**
         * OEM露出を削除する。
         * @summary OEM露出の削除
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oemBalanceDelete(id: number, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.oemBalanceDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * OEM露出情報を取得する
         * @summary OEM露出情報取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oemBalanceInfo(id: number, options?: any): AxiosPromise<OemBalanceInfoOutputResponse> {
            return localVarFp.oemBalanceInfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * OEM露出一覧を取得する
         * @summary OEM露出一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oemBalanceList(options?: any): AxiosPromise<Array<OemBalanceListOutputResponse>> {
            return localVarFp.oemBalanceList(options).then((request) => request(axios, basePath));
        },
        /**
         * OEM露出を登録更新する。
         * @summary OEM露出の登録更新
         * @param {OemBalanceUpsertFormResponse} oemBalanceUpsertFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oemBalanceUpsert(oemBalanceUpsertFormResponse: OemBalanceUpsertFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.oemBalanceUpsert(oemBalanceUpsertFormResponse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OemBalanceApi - object-oriented interface
 * @export
 * @class OemBalanceApi
 * @extends {BaseAPI}
 */
export class OemBalanceApi extends BaseAPI {
    /**
     * OEM露出を削除する。
     * @summary OEM露出の削除
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OemBalanceApi
     */
    public oemBalanceDelete(id: number, options?: AxiosRequestConfig) {
        return OemBalanceApiFp(this.configuration).oemBalanceDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * OEM露出情報を取得する
     * @summary OEM露出情報取得
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OemBalanceApi
     */
    public oemBalanceInfo(id: number, options?: AxiosRequestConfig) {
        return OemBalanceApiFp(this.configuration).oemBalanceInfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * OEM露出一覧を取得する
     * @summary OEM露出一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OemBalanceApi
     */
    public oemBalanceList(options?: AxiosRequestConfig) {
        return OemBalanceApiFp(this.configuration).oemBalanceList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * OEM露出を登録更新する。
     * @summary OEM露出の登録更新
     * @param {OemBalanceUpsertFormResponse} oemBalanceUpsertFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OemBalanceApi
     */
    public oemBalanceUpsert(oemBalanceUpsertFormResponse: OemBalanceUpsertFormResponse, options?: AxiosRequestConfig) {
        return OemBalanceApiFp(this.configuration).oemBalanceUpsert(oemBalanceUpsertFormResponse, options).then((request) => request(this.axios, this.basePath));
    }
}
