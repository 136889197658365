import React, { useCallback, useMemo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId, debugLog } from '../../../utils/functions';
import { Button } from '../../atoms/Button';
// eslint-disable-next-line import/no-cycle
import { ApplySearchApi, ExecutiveStaffApplySearchOutputResponse, PageableResponse } from '../../../api-client';
import { Url } from '../../../constants/Url';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
import { ExecutiveStaffApplySearchForms } from '../../organisms/Forms/ExecutiveStaffApplySearchForms';
import { PaginationTable } from '../../organisms/Table/PaginationTable';
import { Title } from '../../atoms/Title';
import { getSurveyProofCorrectPageUrl } from './utils';
import { ExecutiveStaffApplySearchListOutputResponse } from '../../../api-client/domains/executive-staff-apply-search-list-output-response';
import { PaginationWithEllipsisBilling } from '../../molecules/PaginationWithEllipsisBilling';

interface FormsState {
  searchType: string;
  searchValue: number | undefined | string;
  targetStatus: number;
}
const initialForms: FormsState = {
  searchType: 'applyId',
  searchValue: undefined,
  targetStatus: 0,
};

export type State = {
  api: ApplySearchApi;
  isLoading: boolean;
  forms: FormsState;
  data: ExecutiveStaffApplySearchOutputResponse;
};

export const ExecutiveStaffApplySearchPage: React.FC = () => {
  const testid = createTestId(ExecutiveStaffApplySearchPage);
  const MAX_RECORD_PER_PAGE = 20;

  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<State>({
    api: new ApplySearchApi(),
    isLoading: false,
    forms: initialForms,
    data: {
      executiveStaffApplySearchList: [],
      totalCount: 0,
    },
  });

  const history = useHistory();

  const onSearch = useCallback(() => {
    const searchType = String($.forms.searchType);
    const searchValue =
      $.forms.searchValue === undefined || $.forms.searchValue === '' ? undefined : Number($.forms.searchValue);
    const targetStatus = Number($.forms.targetStatus);
    const applyId = searchType === 'applyId' ? searchValue : undefined;
    const contractShopId = searchType === 'contractShopId' ? searchValue : undefined;
    const clientId = searchType === 'clientId' ? searchValue : undefined;
    mergeState({ isLoading: true });
    $.api
      .executiveStaffApplySearch(targetStatus, 1, MAX_RECORD_PER_PAGE, applyId, contractShopId, clientId)
      .then((res) => {
        mergeState({ data: res.data, isLoading: false });
      })
      .catch(() => {
        history.push(Url.COMMON_ERROR);
      });
  }, [$.forms, $.api, mergeState, history]);

  const onClickPageNation = (clickPage: number) => {
    const searchType = String($.forms.searchType);
    const searchValue =
      $.forms.searchValue === undefined || $.forms.searchValue === '' ? undefined : Number($.forms.searchValue);
    const targetStatus = Number($.forms.targetStatus);
    const applyId = searchType === 'applyId' ? searchValue : undefined;
    const contractShopId = searchType === 'contractShopId' ? searchValue : undefined;
    const clientId = searchType === 'clientId' ? searchValue : undefined;
    $.api
      .executiveStaffApplySearch(targetStatus, clickPage, MAX_RECORD_PER_PAGE, applyId, contractShopId, clientId)
      .then((res) => {
        mergeState({ data: res.data, isLoading: false });
      });
  };

  const setForms = useBindSet('forms');

  return (
    <LoadingSpinner isLoading={$.isLoading}>
      <Title>事務局案件検索</Title>
      <Form>
        <Row className="mb-3">
          <ExecutiveStaffApplySearchForms setForms={setForms} />
          <Col>
            <Button data-testid={testid('search-button')} onClick={() => onSearch()}>
              検索
            </Button>
          </Col>
        </Row>
      </Form>
      <hr />
      {$.data.executiveStaffApplySearchList.length > 0 && (
        <div className="d-flex justify-content-end">検索結果が {$.data.totalCount} 件あります。</div>
      )}
      {$.data.executiveStaffApplySearchList.length > 0 ? (
        <>
          <table>
            <thead>
              <tr style={{ borderBottom: 'solid black' }}>
                <th>応募ID/当選ステータス</th>
                <th>クライアントID/クライアント名</th>
                <th>店舗ID/店舗・モニター名</th>
                <th>調査証明</th>
                <th>アンケート</th>
              </tr>
            </thead>
            <tbody>
              {$.data.executiveStaffApplySearchList.map((v) => {
                return (
                  <tr className="align-middle" key={v.applyId} style={{ borderBottom: 'solid 1px lightgray' }}>
                    <td>
                      <span>{v.applyId}</span>
                      <br />
                      <span>{v.applyStatusName}</span>
                    </td>
                    <td>
                      <span>{v.clientId}</span>
                      <br />
                      <span>{v.clientName}</span>
                    </td>
                    <td>
                      <span>{v.contractShopId}</span>
                      <br />
                      <span>{`${v.contractShopName}・${v.monitorBaseName}`}</span>
                    </td>
                    <td>
                      <Button
                        variant="link"
                        onClick={() => {
                          history.push(getSurveyProofCorrectPageUrl(v.surveyProofCorrectType, v.applyId));
                        }}
                      >
                        {v.surveyProofStatusName}
                      </Button>
                    </td>
                    <td>
                      <Button
                        variant="link"
                        onClick={() => {
                          history.push(`${Url.TENSAKU.CORRECT_ENQUETE}/${v.applyId}`);
                        }}
                      >
                        {v.enqueteStatusName}
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        <></>
      )}
      {$.data.totalCount !== undefined && (
        <div className="d-flex justify-content-center">
          <PaginationWithEllipsisBilling
            maxRecordPerPage={MAX_RECORD_PER_PAGE}
            totalDataNum={$.data.totalCount}
            handleClick={(e) => onClickPageNation(e.page)}
          />
        </div>
      )}
    </LoadingSpinner>
  );
};
