import { AxiosResponse } from 'axios';
import Dropzone from 'react-dropzone';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { faEye, faEyeSlash, faFileUpload, faGripLines, faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  Alert,
  Button,
  Card,
  Col,
  Form,
  ListGroup,
  Nav,
  OverlayTrigger,
  Popover,
  Row,
  Tab,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import {
  CommonMasterListApi,
  QuestionUpsertApi,
  CustomerAttributeQuestionInfoApi,
  CustomerAttributeQuestionUpdateApi,
  QuestionCategoryListApi,
  QuestionCategoryForEachHierarchyListApi,
  QuestionInfoApi,
  CustomerTagListApi,
  RoleListApi,
  IdNameOutputResponse,
} from '../../../../api-client';
import type {
  CommonMasterListOutputResponse,
  CustomerTagResponse,
  EnqueteInfoOutputResponse,
  ImageUpdateFormResponse,
  IncResultOutputResponse,
  MailMagazineCustomerTagOutputResponse,
  QuestionCategoryForEachHierarchListOutputResponse,
  QuestionCategoryListOutputResponse,
  QuestionInfoEnqueteListRowDataResponse,
  QuestionInfoOutputResponse,
  QuestionUpsertFormResponse,
  QuestionUpsertOutputResponse,
  SelectionDataResponse,
  VerticalSelectionDataResponse,
} from '../../../../api-client';
import { getImageSize, getPresignedUrl, s3Delete, s3Upload, s3UploadAlignment } from '../../../../utils/s3';
import { IMAGE_TYPE_ID, OBJECT_TYPE } from '../../../../Constants';
import { Url } from '../../../../constants/Url';
import { TagsInput } from '../../../molecules/TagsInput';
import { Modal } from '../../../molecules/Modal';
import { ImageCard } from '../../../molecules/ImageCard';
import { Dropzone as DropzoneComponent } from '../../../molecules/Dropzone';
import { ENQUETE_TYPE } from '../../../pages/Enquete/EnqueteListPage';

export interface Props {
  enqueteType: number;
  isModal: boolean;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setAnswerMethodModalFlg: React.Dispatch<React.SetStateAction<boolean>>;
  parentData?: EnqueteInfoOutputResponse;
  setParentData?: React.Dispatch<React.SetStateAction<EnqueteInfoOutputResponse>>;
  setQuestionId?: React.Dispatch<React.SetStateAction<number | undefined>>;
  questionId?: number;
  selectType?: 'global' | 'company';
  setModalSubmit?: React.Dispatch<React.SetStateAction<boolean>>;
  adminFlg?: boolean;
  clientIds?: number[];
}

interface Image {
  isDefault: boolean;
  id?: number;
  name?: string;
  path?: string;
  file?: File;
  contentType?: string;
  width?: number;
  height?: number;
  format?: string;
  index?: number;
}

interface ImageList {
  imageList: Image[];
  verticalImageList: Image[];
}

interface UploadImageObj {
  imageId?: number;
  index?: number;
}

export const ANSWER_METHOD = {
  TEXT: 1,
  RADIO: 2,
  PULLDOWN: 3,
  CHECKBOX: 4,
  SINGLE_MATRIX: 5,
  MULTI_MATRIX: 6,
  IMAGE: 7,
  SLIDER: 8,
  DATE: 9,
  TIME: 10,
} as const;

export const ANSWER_METHOD_SUMMARY = {
  IMAGE: 0,
  TEXT: 1,
  SELECT: 2,
} as const;

const initialData = (requiredFlg?: boolean | undefined) => {
  return {
    content: '',
    enqueteList: [],
    id: 0,
    level: undefined,
    questionAnswerMethod: 0,
    requiredFlg: true,
    selections: [],
    verticalSelections: [],
    imageUrl: '',
    questionCategoryName: '',
    selectionPointDisableFlg: false,
    managementSections: [],
    questionMultiLineFlg: undefined,
    customerTagId: undefined,
    clients: [],
  };
};

const initialUpdParam = (requiredFlg?: boolean | undefined) => {
  return {
    content: '',
    editRoute: 0,
    level: undefined,
    modifyingApplyFlg: false,
    requiredFlg,
    answerMethod: 1,
    selections: [],
    verticalSelections: [],
    managementSectionIds: [],
    enqueteType: 1,
    questionTypeId: undefined,
    maximumInput: undefined,
    minimumInput: undefined,
    selectionPointDisableFlg: false,
    multiLineFlg: undefined,
    customerTagId: undefined,
  } as QuestionUpsertFormResponse;
};

export const QuestionConfirmModal: React.VFC<Props> = ({
  enqueteType,
  questionId,
  isModal,
  setIsModal,
  setAnswerMethodModalFlg,
  parentData,
  setParentData,
  setQuestionId,
  selectType,
  setModalSubmit,
  adminFlg,
  clientIds,
}) => {
  // TODO アンケートの種別ごとにモーダルを分けて作ったほうがいい。プログラムがカオスすぎる
  const history = useHistory();

  const [requiredFlg, setRequiredFlg] = useState<boolean>();

  const [data, setData] = useState<Partial<QuestionInfoOutputResponse & ImageList>>(initialData(requiredFlg));

  // 各チェックボックスの状態 -------------------------------------------
  // 会員属性画面のみ-------------------------------------------------
  // 「表示」・「非表示」 チェックボックス
  const [showFlg, setShowFlg] = useState<boolean>(false);
  const [hideFlg, setHideFlg] = useState<boolean>(false);
  const [originalCategories, setOriginalCategories] = useState<QuestionCategoryListOutputResponse[]>([]);
  const [categories, setCategories] = useState<Partial<QuestionCategoryForEachHierarchListOutputResponse>>({});
  const [categoryIds, setCategoryIds] = useState<{ large?: number; medium?: number; small?: number }>({});
  const [managementSectionList, setManagementSectionList] = useState<CommonMasterListOutputResponse[]>([]);
  const [managementSectionTags, setManagementSectionTags] = useState<string[]>([]);
  //  アップデートParam
  const [updParam, setUpdParam] = useState<QuestionUpsertFormResponse>(initialUpdParam(requiredFlg));
  // 保存結果
  const [updResult, setUpdResult] = useState<IncResultOutputResponse>({ result: false });

  const [customerTags, setCustomerTags] = useState<CustomerTagResponse[]>();

  // 「質問文・回答方法」関連State --------------------------------------------------------------------------------------------------------------------

  // 各Tabの選択リスト
  const [radioSelectionList, setRadioSelectionList] = useState<any[]>([]);
  const [pullDownSelectionList, setPullDownSelectionList] = useState<any[]>([]);
  const [CheckSelectionList, setCheckSelectionList] = useState<any[]>([]);
  const [matrixSingleSelectionList, setMatrixSingleSelectionList] = useState<any[]>([]);
  const [matrixSingleItemList, setMatrixSingleItemList] = useState<any[]>([]);
  const [matrixMultiSelectionList, setMatrixmultiSelectionList] = useState<any[]>([]);
  const [matrixMultiItemList, setMatrixMultiItemList] = useState<any[]>([]);
  const [sliderSelectionList, setSliderSelectionList] = useState<any[]>([]);

  const [answerMethod, setAnswerMethod] = useState<number>(ANSWER_METHOD.TEXT);

  // レベル未選択フラグ
  const [levelFlg, setLevelFlg] = useState<boolean>(false);
  // 設問タイプ未選択フラグ
  const [questionTypeFlg, setQuestionTypeFlg] = useState<boolean>(false);
  // 有効選択肢フラグ
  const [choiceCheckFlg, setchoiceCheckFlg] = useState<boolean>(false);
  // 質問文未入力フラグ
  const [questionContentFlg, setQuestionContentFlg] = useState<boolean>(false);
  const [questionImage, setQuestionImage] = useState<Image>({ isDefault: false });

  // 最大文字(回答)数が最小文字(回答)数よりも小さいフラグ
  const [invalidInputNumberFlg, setInvalidInputNumberFlg] = useState<boolean>(false);

  // 会員タグ未選択フラグ
  const [customerTagFlg, setCustomerTagFlg] = useState<boolean>();

  // 配点バリデーション用
  const [pointError, setPointError] = useState<{ result: boolean; message: string }>({ result: true, message: '' });

  // --------------------------------------------------------------------------------------------------------------------------------------------

  const questionInfoApi = new QuestionInfoApi();
  const commonApi = new CommonMasterListApi();
  const questionInfoMemberApi = new CustomerAttributeQuestionInfoApi();
  const updateApi = new QuestionUpsertApi();
  const updateMemberApi = new CustomerAttributeQuestionUpdateApi();
  const questionCategoryForEachHierarchyListApi = new QuestionCategoryForEachHierarchyListApi();
  const questionCategoryListApi = new QuestionCategoryListApi();
  const customerTagListApi = new CustomerTagListApi();

  const [salesFlg, setSalesFlg] = useState<boolean>(false);
  const roleListApi = new RoleListApi();

  // もらったDateの時差を埋める関数
  const diff = (parsedate: Date) => {
    parsedate.setHours(parsedate.getHours() + 9);
    return parsedate;
  };

  const setSelectionList = (list: any[]) => {
    switch (answerMethod) {
      case ANSWER_METHOD.RADIO:
        setRadioSelectionList(list);
        break;
      case ANSWER_METHOD.PULLDOWN:
        setPullDownSelectionList(list);
        break;
      case ANSWER_METHOD.CHECKBOX:
        setCheckSelectionList(list);
        break;
      case ANSWER_METHOD.SINGLE_MATRIX:
        setMatrixSingleSelectionList(list);
        break;
      case ANSWER_METHOD.MULTI_MATRIX:
        setMatrixmultiSelectionList(list);
        break;
      case ANSWER_METHOD.SLIDER:
        setSliderSelectionList(list);
        break;
      default:
        break;
    }
  };

  const setMatrixItemList = (list: any[]) => {
    switch (answerMethod) {
      case ANSWER_METHOD.SINGLE_MATRIX:
        setMatrixSingleItemList(list);
        break;
      case ANSWER_METHOD.MULTI_MATRIX:
        setMatrixMultiItemList(list);
        break;
      default:
        break;
    }
  };

  // 画面初期表示時に一回だけロール一覧を取得する
  useEffect(() => {
    roleListApi.roleList().then((res: AxiosResponse<IdNameOutputResponse[]>) => {
      setSalesFlg(res.data.some((role) => role.name === '営業'));
    });
  }, []);

  useEffect(() => {
    if (!isModal) return;
    if (questionId) {
      const getQuestionInfo = async () => {
        await questionInfoApi.questionInfo(questionId).then((res: AxiosResponse<QuestionInfoOutputResponse>) => {
          const imageList: Image[] = [];
          const verticalImageList: Image[] = [];
          res.data.selections
            .sort((a, b) => a.selectionId! - b.selectionId!)
            .forEach((selection, i) => {
              imageList.push({
                id: selection.imageId,
                isDefault: !!selection.imageId,
                path: selection.imageUrl,
                index: i,
              });
            });
          res.data.verticalSelections
            .sort((a, b) => a.selectionId! - b.selectionId!)
            .forEach((selection, i) => {
              verticalImageList.push({
                id: selection.imageId,
                isDefault: !!selection.imageId,
                path: selection.imageUrl,
                index: i,
              });
            });
          setRequiredFlg(res.data.requiredFlg);
          setData({ ...res.data, imageList, verticalImageList });

          setQuestionImage({ isDefault: !!res.data.imageId, id: res.data.imageId, path: res.data.imageUrl });

          setCategoryIds({
            large: res.data.questionCategoryLargeId,
            medium: res.data.questionCategoryMediumId,
            small: res.data.questionCategorySmallId,
          });

          setUpdParam({
            ...updParam,
            questionCategoryId:
              res.data.questionCategorySmallId || res.data.questionCategoryMediumId || res.data.questionCategoryLargeId,
          });

          if (enqueteType === ENQUETE_TYPE.MEMBERSHIP) {
            setUpdParam({
              ...updParam,
              id: res.data.id,
              answerMethod: res.data.questionAnswerMethod,
              content: res.data.content,
              enqueteType,
              customerTagId: res.data.customerTagId,
              modifyingApplyFlg: true,
              selections: res.data.selections.map((it) => {
                return {
                  content: it.content,
                  customerTagAnswerId: it.customerTagAnswerId,
                  displayFlg: it.displayFlg,
                  loseFlg: false,
                  selectionId: it.selectionId,
                  winFlg: false,
                };
              }),
            });
          }

          const { managementSections, questionAnswerMethod, selections, verticalSelections } = res.data;
          if (managementSections) setManagementSectionTags(managementSections?.map((m) => m.name));
          setAnswerMethod(questionAnswerMethod);
          const orderedSelections = selections.sort((a, b) => a.selectionId! - b.selectionId!);
          let imageIndex = -1;
          const selectionsList = orderedSelections?.map((row, index) => {
            if (row.imageId) imageIndex += 1;
            return {
              ...row,
              imageUrl: row.imageUrl || '',
              imageIndex: row.imageId ? imageIndex : undefined,
              displayIndex: index + 1,
              customerTagAnswerId: row.customerTagAnswerId,
              customerTagAnswerName: row.customerTagAnswerName,
            };
          });
          setRadioSelectionList(selectionsList);
          setCheckSelectionList(selectionsList);
          setPullDownSelectionList(selectionsList);
          setMatrixSingleSelectionList(selectionsList);
          setMatrixmultiSelectionList(selectionsList);
          setSliderSelectionList(selectionsList);

          const verticalSelectionsList = verticalSelections?.map((row, index) => ({
            ...row,
            imageUrl: row.imageUrl || '',
            displayIndex: index + 1,
          }));
          setMatrixSingleItemList(verticalSelectionsList);
          setMatrixMultiItemList(verticalSelectionsList);
        });
      };

      getQuestionInfo();
    } else {
      const newData: any[] = [];
      const newData2: any[] = [];
      for (let index = 0; index < 7; index += 1) {
        newData.push({
          content: '',
          displayFlg: true,
          imageId: null,
          imageUrl: '',
          url: '',
          loseFlg: false,
          point: 0,
          selectionId: undefined,
          selectionIds: [],
          winFlg: false,
          fileName: '',
          displayIndex: index + 1,
        });
        newData2.push({
          content: '',
          displayFlg: true,
          imageId: null,
          imageUrl: '',
          url: '',
          fileName: '',
          displayIndex: index + 1,
        });
      }
      const defaultData = Object.assign({}, data);
      defaultData.selections = newData;
      defaultData.verticalSelections = newData2;
      if (enqueteType === ENQUETE_TYPE.MEMBERSHIP) {
        setUpdParam({ ...updParam, customerTagId: undefined });
        setRadioSelectionList([]);
        setCheckSelectionList([]);
        setPullDownSelectionList([]);
        setSliderSelectionList([]);
      } else {
        setRadioSelectionList(newData);
        setCheckSelectionList(newData);
        setPullDownSelectionList(newData);
        setSliderSelectionList(newData);
      }
      setMatrixSingleSelectionList(newData);
      setMatrixSingleItemList(newData2);
      setMatrixmultiSelectionList(newData);
      setMatrixMultiItemList(newData2);
    }
  }, [isModal, questionId]);

  useEffect(() => {
    if (
      !questionId &&
      ((enqueteType === ENQUETE_TYPE.SELECT && selectType === 'company') || enqueteType === ENQUETE_TYPE.PILEUP)
    ) {
      switch (answerMethod) {
        case ANSWER_METHOD.RADIO:
        case ANSWER_METHOD.PULLDOWN:
        case ANSWER_METHOD.CHECKBOX:
        case ANSWER_METHOD.SINGLE_MATRIX:
        case ANSWER_METHOD.MULTI_MATRIX:
        case ANSWER_METHOD.SLIDER:
          setData({ ...data, questionTypeId: 6 });
          break;
        case ANSWER_METHOD.TEXT:
        case ANSWER_METHOD.DATE:
        case ANSWER_METHOD.TIME:
          setData({ ...data, questionTypeId: 7 });
          break;
        case ANSWER_METHOD.IMAGE:
          setData({ ...data, questionTypeId: 9 });
          break;
        default:
          break;
      }
    }
  }, [isModal, questionId, answerMethod]);

  useEffect(() => {
    if (!isModal) return;
    setAnswerMethod(enqueteType === ENQUETE_TYPE.MEMBERSHIP ? ANSWER_METHOD.RADIO : ANSWER_METHOD.TEXT);
    if (enqueteType === ENQUETE_TYPE.PILEUP) {
      questionCategoryListApi
        .questionCategoryList(clientIds, false /** true */)
        .then((res: AxiosResponse<QuestionCategoryListOutputResponse[]>) => {
          setOriginalCategories(res.data);
        });
    }

    if (enqueteType === ENQUETE_TYPE.SELECT) {
      questionCategoryForEachHierarchyListApi
        .questionCategoryForEachHierarchyList()
        .then((res: AxiosResponse<QuestionCategoryForEachHierarchListOutputResponse>) => {
          setCategories(res.data);
        });
      commonApi.commonMasterList('management_section').then((res: AxiosResponse<CommonMasterListOutputResponse[]>) => {
        setManagementSectionList(res.data);
      });
    }

    if (enqueteType === ENQUETE_TYPE.MEMBERSHIP) {
      customerTagListApi.customerTagList().then((res: AxiosResponse<MailMagazineCustomerTagOutputResponse>) => {
        setCustomerTags(res.data.customerTagList);
      });
    }
  }, [isModal, enqueteType]);

  useEffect(() => {
    let questionCategoryId;
    let managementSectionIds: number[] = [];
    if (enqueteType === ENQUETE_TYPE.SELECT) {
      questionCategoryId = categoryIds?.small || categoryIds?.medium || categoryIds?.large;
      managementSectionIds =
        (managementSectionTags
          .map((name) => managementSectionList.find((m) => m.name === name)?.id)
          .filter(Boolean) as number[]) || [];
    }
    if (enqueteType === ENQUETE_TYPE.PILEUP && questionId) {
      questionInfoApi.questionInfo(questionId).then((res) => {
        setUpdParam({
          ...updParam,
          questionCategoryId:
            res.data.questionCategorySmallId || res.data.questionCategoryMediumId || res.data.questionCategoryLargeId,
        });
      });
    }

    setUpdParam({
      ...updParam,
      enqueteType,
      questionCategoryId,
      managementSectionIds,
      id: questionId,
      level: data?.level,
      content: data?.content || '',
      editRoute: 1,
      requiredFlg: data.requiredFlg || false,
      imageId: data.imageId || undefined,
      url: data.url,
      questionTypeId: data?.questionTypeId,
      selectionPointDisableFlg: data.selectionPointDisableFlg || false,
      maximumInput: data.maximumInput || undefined,
      minimumInput: data.minimumInput || undefined,
      multiLineFlg: data.questionMultiLineFlg || undefined,
      customerTagId: data.customerTagId || undefined,
    });
  }, [data, categoryIds, managementSectionTags]);

  // 各種Changeイベント
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'show':
        setShowFlg(!showFlg);
        break;
      case 'hide':
        setHideFlg(!hideFlg);
        break;
      case 'winningUser':
        setUpdParam({ ...updParam, modifyingApplyFlg: event.target.checked });
        break;
      case 'required':
        setUpdParam({ ...updParam, requiredFlg: event.target.checked });
        break;
      case 'multiLine':
        setUpdParam({ ...updParam, multiLineFlg: event.target.checked });
        break;
      default:
        break;
    }
  };

  const onDropAttached = async (files: File[]) => {
    if (files === null || files.length === 0) return;
    const file = files[0];

    const { name, type: contentType } = file;
    const path = window.URL.createObjectURL(file);
    const format = name.split('.').pop();
    const size = await getImageSize(file).catch((error) => console.error('FileReader Error: ', error));
    setQuestionImage((prev) => ({ ...prev, ...size, file, name, contentType, format, path }));
  };

  const uploadImage = async (imageInfo: Image) => {
    const { isDefault, id, path, name, contentType, file, width, height, format, index } = imageInfo;
    const returnObj: UploadImageObj = {
      imageId: id,
      index,
    };

    try {
      if (file && name && contentType && width && height && format) {
        const presignedUrl = await getPresignedUrl({ contentType, fileKey: name, objectType: OBJECT_TYPE.QUESTION });
        const uploadTime = await s3Upload(presignedUrl, contentType, file);

        const alignmentParam: ImageUpdateFormResponse = { width, height, format };
        if (isDefault) {
          alignmentParam.imageId = id;
        } else {
          alignmentParam.imageTypeId = IMAGE_TYPE_ID.QUESTION;
          const presignedPath = presignedUrl.replace(/\?.*$/, '');
          alignmentParam.path = presignedPath.substring(
            presignedPath.indexOf('amazonaws.com/') + 'amazonaws.com/'.length
          );
          alignmentParam.uploadTime = uploadTime;
        }

        await s3UploadAlignment(alignmentParam).then((res) => {
          if (!res?.result) setUpdResult(res);
          returnObj.imageId = res.id;
        });
      } else if (isDefault && !path && id) {
        await s3Delete(id).then((res) => {
          if (!res?.result) {
            setUpdResult(res);
            return;
          }
          returnObj.imageId = undefined;
        });
      }
      return returnObj;
    } catch {
      history.push(Url.COMMON_ERROR);
    }
  };

  // 保存ボタンイベント
  const onhandleKeep = async () => {
    let param = Object.assign({}, { ...updParam, answerMethod });

    if (enqueteType === ENQUETE_TYPE.SELECT && selectType === 'global') {
      setLevelFlg(!updParam?.level);
      setQuestionTypeFlg(!updParam?.questionTypeId);
      if (!updParam?.level || !updParam?.questionTypeId) return;
    }

    if (enqueteType === ENQUETE_TYPE.MEMBERSHIP) {
      setCustomerTagFlg(false);
      if (!updParam.customerTagId) {
        setCustomerTagFlg(true);
        return;
      }
    }

    // 回答選択肢
    const isEmpty = updParam.content === '';
    setQuestionContentFlg(isEmpty);
    if (isEmpty) return;

    if (
      answerMethod === ANSWER_METHOD.TEXT ||
      answerMethod === ANSWER_METHOD.IMAGE ||
      answerMethod === ANSWER_METHOD.CHECKBOX ||
      answerMethod === ANSWER_METHOD.MULTI_MATRIX
    ) {
      if (updParam.minimumInput && updParam.maximumInput) {
        const isInvalid = Number(updParam.minimumInput) > Number(updParam.maximumInput);
        setInvalidInputNumberFlg(isInvalid);
        if (isInvalid) return;
      }
    }

    if (answerMethod === ANSWER_METHOD.TEXT) {
      param = {
        ...param,
        multiLineFlg: param.multiLineFlg === undefined ? true : param.multiLineFlg,
      };
    }

    const newSelections = (list: any[]): any[] =>
      list
        ?.filter((row) => row?.content)
        .map((_) => ({
          content: _.content,
          displayFlg: _.displayFlg,
          loseFlg: _.loseFlg,
          winFlg: _.winFlg,
          imageId: _.imageId,
          point: _.point,
          selectionId: _.selectionId,
          imageIndex: _.imageIndex,
          customerTagAnswerId: _.customerTagAnswerId,
        }));

    const newVerticalSelections = (list: any[]): any[] =>
      list
        ?.filter((row) => row?.content)
        .map((_) => ({ content: _.content, displayFlg: _.displayFlg, imageId: _.imageId, imageIndex: _.imageIndex }));

    switch (answerMethod) {
      case ANSWER_METHOD.RADIO: {
        if (choicesCheckDisable(radioSelectionList)) return;
        param = { ...param, selections: newSelections(radioSelectionList) };
        break;
      }
      case ANSWER_METHOD.PULLDOWN: {
        if (choicesCheckDisable(pullDownSelectionList)) return;
        param = { ...param, selections: newSelections(pullDownSelectionList) };
        break;
      }
      case ANSWER_METHOD.CHECKBOX: {
        if (choicesCheckDisable(CheckSelectionList)) return;
        param = { ...param, selections: newSelections(CheckSelectionList) };
        break;
      }
      case ANSWER_METHOD.SLIDER: {
        if (choicesCheckDisable(sliderSelectionList)) return;
        param = { ...param, selections: newSelections(sliderSelectionList) };
        break;
      }
      case ANSWER_METHOD.SINGLE_MATRIX: {
        if (choicesCheckDisable(matrixSingleSelectionList, matrixSingleItemList)) return;
        param = {
          ...param,
          selections: newSelections(matrixSingleSelectionList),
          verticalSelections: newVerticalSelections(matrixSingleItemList),
        };
        break;
      }
      case ANSWER_METHOD.MULTI_MATRIX: {
        if (choicesCheckDisable(matrixMultiSelectionList, matrixMultiItemList)) return;
        param = {
          ...param,
          selections: newSelections(matrixMultiSelectionList),
          verticalSelections: newVerticalSelections(matrixMultiItemList),
        };
        break;
      }
      default:
        break;
    }

    try {
      let uploadImageId: number | undefined;
      await uploadImage(questionImage).then((res) => {
        uploadImageId = res ? res.imageId : undefined;
      });

      let uploadSelectionImageList: UploadImageObj[] = [];
      if (data.imageList && data.imageList.length > 0) {
        await Promise.all(
          data.imageList.map(
            (imageData): Promise<any> =>
              new Promise((resolve, reject) => {
                uploadImage(imageData)
                  .then((res) => resolve(res))
                  .catch((err) => reject(new Error(err)));
              })
          )
        ).then((res: UploadImageObj[]) => {
          uploadSelectionImageList = res;
        });
      }

      const ImageSelection: SelectionDataResponse[] = [];
      if (enqueteType !== ENQUETE_TYPE.MEMBERSHIP) {
        if (uploadSelectionImageList && uploadSelectionImageList.length > 0) {
          param.selections.forEach((selection: any, index: number) => {
            if (!selection.imageIndex && selection.imageIndex !== 0) {
              ImageSelection.push(selection);
              return;
            }
            ImageSelection.push({
              ...selection,
              imageId: uploadSelectionImageList[selection.imageIndex].imageId,
            });
          });
        }

        if (ImageSelection.length > 0) {
          param.selections = ImageSelection.map(
            ({ content, displayFlg, loseFlg, winFlg, imageId, point, selectionId }) => ({
              content,
              displayFlg,
              loseFlg,
              winFlg,
              imageId,
              point,
              selectionId,
            })
          );
        }
      }

      let uploadVerticalSelectionImageList: UploadImageObj[] = [];
      if (data.verticalImageList && data.verticalImageList.length > 0) {
        await Promise.all(
          data.verticalImageList.map(
            (imageData): Promise<any> =>
              new Promise((resolve, reject) => {
                uploadImage(imageData)
                  .then((res) => resolve(res))
                  .catch((err) => reject(new Error(err)));
              })
          )
        ).then((res: UploadImageObj[]) => {
          uploadVerticalSelectionImageList = res;
        });
      }
      const verticalImageList: VerticalSelectionDataResponse[] = [];
      if (uploadVerticalSelectionImageList && uploadVerticalSelectionImageList.length > 0) {
        param.verticalSelections.forEach((selection: any, index: number) => {
          if (!selection.imageIndex && selection.imageIndex !== 0) {
            verticalImageList.push(selection);
          }
          verticalImageList.push({
            ...selection,
            imageId: uploadVerticalSelectionImageList[selection.imageIndex].imageId,
          });
        });
      }

      if (verticalImageList.length > 0) {
        param.verticalSelections = verticalImageList.map(({ content, displayFlg, imageId }) => ({
          content,
          displayFlg,
          imageId,
        }));
      }

      param.imageId = uploadImageId;

      if (
        (enqueteType === ENQUETE_TYPE.SELECT && selectType === 'company') ||
        (enqueteType === ENQUETE_TYPE.PILEUP && !updParam.level)
      ) {
        param.level = 5;
      }
      param.enqueteType = enqueteType;
      param.questionTypeId = param.questionTypeId || undefined;
      if (enqueteType === ENQUETE_TYPE.BEFOREHAND) {
        param.modifyingApplyFlg = true;
        param.requiredFlg = true;
      }

      if (
        enqueteType === ENQUETE_TYPE.PILEUP &&
        answerMethod !== ANSWER_METHOD.TEXT &&
        answerMethod !== ANSWER_METHOD.IMAGE
      ) {
        const hasPoint = param.selections.some(({ point }) => point);

        if (!updParam.questionCategoryId && hasPoint) {
          setPointError({ result: false, message: '独自カテゴリは必須です。' });
          return;
        }
        if (updParam.questionCategoryId && !hasPoint) {
          setPointError({ result: false, message: '配点は必須です。' });
          return;
        }
      }

      await updateApi
        .questionUpsert(param as QuestionUpsertFormResponse)
        .then((res: AxiosResponse<QuestionUpsertOutputResponse>) => {
          setIsModal(false);
          if (res.data.result) {
            if (setQuestionId) setQuestionId(undefined);
            if (enqueteType !== ENQUETE_TYPE.MEMBERSHIP) {
              setData(initialData(requiredFlg));
              setUpdParam(initialUpdParam(requiredFlg));
              setCategoryIds({});
              setManagementSectionTags([]);
              setRadioSelectionList([]);
              setSliderSelectionList([]);
              setPullDownSelectionList([]);
              setCheckSelectionList([]);
              setMatrixSingleSelectionList([]);
              setMatrixSingleItemList([]);
              setMatrixmultiSelectionList([]);
              setMatrixMultiItemList([]);
              setAnswerMethod(ANSWER_METHOD.TEXT);
              setQuestionImage({ isDefault: false });
            }
            setIsModal(false);
            if (parentData && setParentData) {
              let enqueteQuestionsList: any[] = [];
              enqueteQuestionsList = [
                ...(parentData?.enqueteQuestionsList || []),
                {
                  questionId: res.data.questionId,
                  enqueteQuestionsId: 0,
                  answerMethod: res.data.answerMethod,
                  content: res.data.content,
                  level: res.data.level,
                  mailContent: undefined,
                  pointSettingDisableFlg: undefined,
                  questionCategoryId: res.data.questionCategoryId || undefined,
                  questionCategoryName: res.data.questionCategoryName || undefined,
                  // selections: res.data.selections,
                },
              ];
              setParentData({
                ...parentData,
                enqueteQuestionsList,
              });
            }
            if (setModalSubmit) setModalSubmit(true);
          } else {
            setUpdResult(res.data);
          }
        });
    } catch {
      history.push(Url.COMMON_ERROR);
    }
  };

  // ※有効選択肢があるかどうかのチェックをする関数
  // ※選択肢内容が入力されていて、かつ有効となっている選択肢
  const choicesCheckDisable = (choiceList1?: any[], choiceList2?: any[]) => {
    if (
      choiceList1?.some((c) => c.displayFlg && c.content) &&
      (!choiceList2 || choiceList2?.some((c) => c.displayFlg && c.content))
    ) {
      setchoiceCheckFlg(false);
      return false;
    }
    setchoiceCheckFlg(true);
    return true;
  };

  // -----------------------------------------------------------------------------------------------------------------------------------------------------
  // 各種変更イベント
  const onChangeText = (
    event: React.ChangeEvent<HTMLInputElement>,
    targetSelectList?: any[],
    targetNumber?: number
  ) => {
    switch (event.target.id) {
      case 'questionContent':
        setUpdParam({ ...updParam, content: event.target.value });
        break;
      case 'url':
        setUpdParam({ ...updParam, url: event.target.value });
        break;
      case 'choiceContent':
        setSelectionList(
          targetSelectList?.map((t) => (t.displayIndex === targetNumber ? { ...t, content: event.target.value } : t)) ||
            []
        );
        break;
      case 'choiceContentItem':
        setMatrixItemList(
          targetSelectList?.map((t) => (t.displayIndex === targetNumber ? { ...t, content: event.target.value } : t)) ||
            []
        );
        break;
      case 'point':
        setSelectionList(
          targetSelectList?.map((t) =>
            t.displayIndex === targetNumber
              ? {
                  ...t,
                  point: Number.isNaN(parseInt(event.target.value, 10)) ? undefined : parseInt(event.target.value, 10),
                }
              : t
          ) || []
        );
        break;
      case 'winFlg':
        setSelectionList(
          targetSelectList?.map((t) =>
            t.displayIndex === targetNumber ? { ...t, winFlg: event.target.checked } : t
          ) || []
        );
        break;
      case 'loseFlg':
        setSelectionList(
          targetSelectList?.map((t) =>
            t.displayIndex === targetNumber ? { ...t, loseFlg: event.target.checked } : t
          ) || []
        );
        break;
      default:
        break;
    }
  };

  // 選択肢画像アップロードイベント(横軸)
  const onSelectListDrop = async (acceptedFile: any, index: number, targetSelectList: any[] | undefined) => {
    if (acceptedFile.length !== 0 && acceptedFile.length === 1) {
      const file = acceptedFile[0];

      const { name, type: contentType } = file;
      const path = window.URL.createObjectURL(file);
      const format = name.split('.').pop();
      const size = await getImageSize(file).catch((error) => console.error('FileReader Error: ', error));

      const imageObj = {
        ...size,
        file,
        name,
        contentType,
        format,
        path,
        isDefault: false,
        index,
      };

      setData({
        ...data,
        imageList: data.imageList && data.imageList.length > 0 ? data.imageList.concat(imageObj) : [imageObj],
      });

      const newSelectionList =
        targetSelectList?.map((normalList, i) => {
          if (i === index) {
            return {
              ...normalList,
              imageId: undefined,
              imageUrl: path,
              imageIndex: data.imageList && data.imageList.length > 0 ? data.imageList.length : 0,
            };
          }
          return normalList;
        }) || [];

      setSelectionList(newSelectionList);
    }
  };

  // 選択肢画像アップロードイベント（縦軸）
  const onItemListDrop = async (acceptedFile: any, index: number, targetSelectList: any[] | undefined) => {
    if (acceptedFile.length !== 0 && acceptedFile.length === 1) {
      const file = acceptedFile[0];

      const { name, type: contentType } = file;
      const path = window.URL.createObjectURL(file);
      const format = name.split('.').pop();
      const size = await getImageSize(file).catch((error) => console.error('FileReader Error: ', error));

      const imageObj = {
        ...size,
        file,
        name,
        contentType,
        format,
        path,
        isDefault: false,
        index,
      };

      setData({
        ...data,
        verticalImageList:
          data.verticalImageList && data.verticalImageList.length > 0
            ? data.verticalImageList.concat(imageObj)
            : [imageObj],
      });

      const newSelectionList =
        targetSelectList?.map((normalList, i) => {
          if (i === index) {
            return {
              ...normalList,
              imageId: undefined,
              imageUrl: path,
              imageIndex: data.imageList && data.imageList.length > 0 ? data.imageList.length : 0,
            };
          }
          return normalList;
        }) || [];

      setMatrixItemList(newSelectionList);
    }
  };

  // 横軸のみのタブ選択時 追加ボタンイベント
  const onAddBesideOnly = () => {
    let copyData: any[];
    if (answerMethod === ANSWER_METHOD.RADIO) {
      copyData = Object.assign([], radioSelectionList);
    } else if (answerMethod === ANSWER_METHOD.PULLDOWN) {
      copyData = Object.assign([], pullDownSelectionList);
    } else if (answerMethod === ANSWER_METHOD.SLIDER) {
      copyData = Object.assign([], sliderSelectionList);
    } else {
      copyData = Object.assign([], CheckSelectionList);
    }

    Math.max.apply(
      null,
      copyData.map((row) => row.imageId)
    );

    copyData?.push({
      content: '',
      displayFlg: true,
      imageId: null,
      imageUrl: '',
      imageIndex: undefined,
      loseFlg: false,
      point: 0,
      selectionId: undefined,
      selectionIds: [],
      winFlg: false,
      fileName: '',
      displayIndex: copyData.length + 1,
    });
    if (answerMethod === ANSWER_METHOD.RADIO) {
      setRadioSelectionList(copyData);
    } else if (answerMethod === ANSWER_METHOD.PULLDOWN) {
      setPullDownSelectionList(copyData);
    } else if (answerMethod === ANSWER_METHOD.SLIDER) {
      setSliderSelectionList(copyData);
    } else {
      setCheckSelectionList(copyData);
    }
  };

  // 横縦両方のタブ選択時 横軸追加ボタンイベント
  const onAddBeside = () => {
    let copyData: any[];
    if (answerMethod === ANSWER_METHOD.SINGLE_MATRIX) {
      copyData = Object.assign([], matrixSingleSelectionList);
    } else {
      copyData = Object.assign([], matrixMultiSelectionList);
    }
    copyData?.push({
      content: '',
      displayFlg: true,
      imageId: null,
      imageUrl: '',
      imageIndex: undefined,
      loseFlg: false,
      point: 0,
      selectionId: undefined,
      selectionIds: [],
      winFlg: false,
      fileName: '',
      displayIndex: copyData.length + 1,
    });
    if (answerMethod === ANSWER_METHOD.SINGLE_MATRIX) {
      setMatrixSingleSelectionList(copyData);
    } else {
      setMatrixmultiSelectionList(copyData);
    }
  };

  // 横縦両方のタブ選択時 縦軸追加ボタンイベント
  const onAddVertical = () => {
    let copyData: any[];
    if (answerMethod === ANSWER_METHOD.SINGLE_MATRIX) {
      copyData = Object.assign([], matrixSingleItemList);
    } else {
      copyData = Object.assign([], matrixMultiItemList);
    }
    copyData?.push({
      content: '',
      displayFlg: true,
      imageId: null,
      imageUrl: '',
      fileName: '',
      displayIndex: copyData.length + 1,
    });
    if (answerMethod === ANSWER_METHOD.SINGLE_MATRIX) {
      setMatrixSingleItemList(copyData);
    } else {
      setMatrixMultiItemList(copyData);
    }
  };

  // 横軸選択肢のドラッグアンドドロップイベント
  const handleOnDragEndSelect = (result: any) => {
    let targetSelectList: any[] | undefined = [];

    if (result.destination === null) return;
    switch (answerMethod) {
      case ANSWER_METHOD.RADIO:
        targetSelectList = radioSelectionList;
        break;
      case ANSWER_METHOD.PULLDOWN:
        targetSelectList = pullDownSelectionList;
        break;
      case ANSWER_METHOD.CHECKBOX:
        targetSelectList = CheckSelectionList;
        break;
      case ANSWER_METHOD.SINGLE_MATRIX:
        targetSelectList = matrixSingleSelectionList;
        break;
      case ANSWER_METHOD.MULTI_MATRIX:
        targetSelectList = matrixMultiSelectionList;
        break;
      case ANSWER_METHOD.SLIDER:
        targetSelectList = sliderSelectionList;
        break;
      default:
        break;
    }

    const items = Object.assign([], targetSelectList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination!.index, 0, reorderedItem);
    setSelectionList(items);
  };

  // 縦軸選択肢のドラッグアンドドロップイベント
  const handleOnDragEndItem = (result: any) => {
    let targetItemList: any[] | undefined = [];

    if (result.destination === null) return;
    switch (answerMethod) {
      case ANSWER_METHOD.SINGLE_MATRIX:
        targetItemList = matrixSingleItemList;
        break;
      case ANSWER_METHOD.MULTI_MATRIX:
        targetItemList = matrixMultiItemList;
        break;
      default:
        break;
    }

    const items = Object.assign([], targetItemList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination!.index, 0, reorderedItem);

    setMatrixItemList(items);
  };

  const monitorPopover = (
    <Popover id="popover-basic" style={{ maxWidth: '25rem' }}>
      <p>
        このモニタールールと
        <br />
        アンケート内の特設設問を紐付ける。
        <br />
        ここで紐付けた設問をアンケート内に
        <br />
        設定すると、モニタールールセットに
        <br />
        自動でこのモニタールールが挿入される。
      </p>
    </Popover>
  );

  // 選択肢（横軸）作成関数
  const selectList = () => {
    let targetSelectList: any[] | undefined = [];

    switch (answerMethod) {
      case ANSWER_METHOD.RADIO:
        targetSelectList = radioSelectionList;
        break;
      case ANSWER_METHOD.PULLDOWN:
        targetSelectList = pullDownSelectionList;
        break;
      case ANSWER_METHOD.CHECKBOX:
        targetSelectList = CheckSelectionList;
        break;
      case ANSWER_METHOD.SINGLE_MATRIX:
        targetSelectList = matrixSingleSelectionList;
        break;
      case ANSWER_METHOD.MULTI_MATRIX:
        targetSelectList = matrixMultiSelectionList;
        break;
      case ANSWER_METHOD.SLIDER:
        targetSelectList = sliderSelectionList;
        break;
      default:
        break;
    }
    return (
      <DragDropContext onDragEnd={handleOnDragEndSelect}>
        <Droppable droppableId="characters">
          {(provided) => (
            <Row className="align-items-center gx-3 mb-3" {...provided.droppableProps} ref={provided.innerRef}>
              {targetSelectList?.map((row, index: number) => (
                <Draggable key={row.displayIndex.toString()} draggableId={row.displayIndex.toString()} index={index}>
                  {(provided2) => (
                    <Row
                      className="align-items-center gx-3 mb-3"
                      ref={provided2.innerRef}
                      {...provided2.draggableProps}
                    >
                      <Col className="flex-shrink-0 flex-grow-0">
                        <button
                          type="button"
                          className="text-secondary"
                          {...provided2.dragHandleProps}
                          data-testid="selections_LineButton"
                        >
                          <FontAwesomeIcon icon={faGripLines} fixedWidth />
                        </button>
                      </Col>
                      <Col className="flex-shrink-0 flex-grow-0">
                        <button
                          data-testid="selection_EyeButton"
                          type="button"
                          className="text-secondary"
                          onClick={() =>
                            setSelectionList(
                              targetSelectList?.map((item) =>
                                item.displayIndex === row.displayIndex
                                  ? { ...item, displayFlg: !item.displayFlg }
                                  : item
                              ) || []
                            )
                          }
                        >
                          <FontAwesomeIcon icon={row.displayFlg ? faEye : faEyeSlash} fixedWidth />
                        </button>
                      </Col>
                      <Col>
                        <Row className="gx-3">
                          <Col className={enqueteType === ENQUETE_TYPE.BEFOREHAND ? 'col-9.5' : 'col-10'}>
                            <Form.Group controlId="choiceContent">
                              <Form.Control
                                defaultValue={row.content || ''}
                                type="text"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  onChangeText(e, targetSelectList, row.displayIndex);
                                }}
                                data-testid="selections_Content"
                              />
                            </Form.Group>
                          </Col>
                          {enqueteType !== ENQUETE_TYPE.BEFOREHAND && (
                            <Col className="col-2">
                              <Form.Group controlId="point">
                                <Form.Control
                                  value={row.point !== undefined ? row.point : ''}
                                  type="number"
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    onChangeText(e, targetSelectList, row.displayIndex);
                                  }}
                                />
                              </Form.Group>
                            </Col>
                          )}
                        </Row>
                      </Col>
                      {row.imageUrl === '' && answerMethod !== ANSWER_METHOD.PULLDOWN && (
                        <Col
                          className={
                            enqueteType === ENQUETE_TYPE.BEFOREHAND
                              ? 'flex-shrink-0 flex-grow-0'
                              : 'flex-shrink-0 flex-grow-0 col-3.5'
                          }
                        >
                          <Dropzone
                            onDrop={(accepted) => onSelectListDrop(accepted, index, targetSelectList)}
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <button type="button" className="d-flex align-items-center" style={{ width: '9rem' }}>
                                  <FontAwesomeIcon
                                    icon={faFileUpload}
                                    className="text-secondary flex-shrink-0 me-1"
                                    fixedWidth
                                  />
                                  <span className="text-truncate flex-grow-0 text-start text-secondary">
                                    画像アップロード
                                  </span>
                                </button>
                              </div>
                            )}
                          </Dropzone>
                        </Col>
                      )}
                      {row.imageUrl && answerMethod !== ANSWER_METHOD.PULLDOWN && (
                        <Col
                          className={
                            enqueteType === ENQUETE_TYPE.BEFOREHAND
                              ? 'flex-shrink-0 flex-grow-0'
                              : ' flex-shrink-0 flex-grow-0 col-3.5'
                          }
                        >
                          <Col className="d-flex" style={{ width: '9rem' }}>
                            <img
                              className="m-2"
                              src={
                                (data.imageList && data.imageList?.filter((obj) => obj.index === index)[0].path) || ''
                              }
                              alt="sample"
                              width="83%"
                            />
                            <Col className="ms-2">
                              <button
                                type="button"
                                className="me-2 align-items-center"
                                onClick={() => {
                                  setSelectionList(
                                    targetSelectList?.map((normalList) =>
                                      normalList.displayIndex === row.displayIndex
                                        ? {
                                            ...normalList,
                                            imageId: null,
                                            imageUrl: '',
                                            fileName: '',
                                            imageIndex: undefined,
                                          }
                                        : normalList
                                    ) || []
                                  );
                                  const newImageList: Image[] = [];
                                  data.imageList?.forEach((obj: Image) => {
                                    if (obj.index === index) {
                                      if (obj.isDefault) {
                                        newImageList.push({
                                          isDefault: obj.isDefault,
                                          id: obj.id,
                                        });
                                      }
                                      return;
                                    }
                                    newImageList.push(obj);
                                  });
                                  setData({ ...data, imageList: newImageList });
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faTimesCircle}
                                  className="text-secondary flex-shrink-0 me-1"
                                  fixedWidth
                                />
                              </button>
                            </Col>
                          </Col>
                        </Col>
                      )}
                      {enqueteType === ENQUETE_TYPE.BEFOREHAND && (
                        <>
                          <Col
                            className={
                              answerMethod === ANSWER_METHOD.SINGLE_MATRIX ||
                              answerMethod === ANSWER_METHOD.MULTI_MATRIX
                                ? 'flex-shrink-0 flex-grow-0 col-1.0'
                                : 'flex-shrink-0 flex-grow-0'
                            }
                          >
                            <Form.Check.Input
                              data-testid="winCheckBox"
                              id="winFlg"
                              className="flex-shrink-0 me-4"
                              type="checkbox"
                              name="checkBoxGroup01"
                              checked={row.winFlg}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                onChangeText(e, targetSelectList, row.displayIndex);
                              }}
                            />
                          </Col>
                          <Col
                            className={
                              answerMethod === ANSWER_METHOD.SINGLE_MATRIX ||
                              answerMethod === ANSWER_METHOD.MULTI_MATRIX
                                ? 'flex-shrink-0 flex-grow-0'
                                : 'flex-shrink-0 flex-grow-0 col-1'
                            }
                          >
                            <Form.Check.Input
                              data-testid="loseCheckBox"
                              id="loseFlg"
                              className="flex-shrink-0"
                              type="checkbox"
                              name="checkBoxGroup01"
                              checked={row.loseFlg}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                onChangeText(e, targetSelectList, row.displayIndex);
                              }}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Row>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  // 選択肢（横軸）作成関数
  const selectList2 = () => {
    let targetSelectList: any[] | undefined = [];

    switch (answerMethod) {
      case ANSWER_METHOD.RADIO:
        targetSelectList = radioSelectionList;
        break;
      case ANSWER_METHOD.PULLDOWN:
        targetSelectList = pullDownSelectionList;
        break;
      case ANSWER_METHOD.CHECKBOX:
        targetSelectList = CheckSelectionList;
        break;
      default:
        break;
    }

    return (
      <DragDropContext onDragEnd={handleOnDragEndSelect}>
        <Droppable droppableId="characters">
          {(provided) => (
            <Row className="align-items-center gx-3 mb-3" {...provided.droppableProps} ref={provided.innerRef}>
              {targetSelectList?.map((row, index: number) => (
                <Draggable
                  key={row.customerTagAnswerId.toString()}
                  draggableId={row.displayIndex.toString()}
                  index={index}
                >
                  {(provided2) => (
                    <Row
                      className="align-items-center gx-3 mb-3"
                      ref={provided2.innerRef}
                      {...provided2.draggableProps}
                    >
                      <Col className="flex-shrink-0 flex-grow-0">
                        <button
                          type="button"
                          className="text-secondary"
                          {...provided2.dragHandleProps}
                          data-testid="selections_LineButton"
                        >
                          <FontAwesomeIcon icon={faGripLines} fixedWidth />
                        </button>
                      </Col>
                      <Col className="flex-shrink-0 flex-grow-0">
                        <button
                          data-testid="selection_EyeButton"
                          type="button"
                          className="text-secondary"
                          onClick={() =>
                            setSelectionList(
                              targetSelectList?.map((item) =>
                                item.displayIndex === row.displayIndex
                                  ? { ...item, displayFlg: !item.displayFlg }
                                  : item
                              ) || []
                            )
                          }
                        >
                          <FontAwesomeIcon icon={row.displayFlg ? faEye : faEyeSlash} fixedWidth />
                        </button>
                      </Col>
                      <Col>
                        <Row className="gx-3">
                          <Col className="col-4">
                            <Form.Group controlId="customerTagAnswer">
                              <Form.Control defaultValue={row.customerTagAnswerName || ''} type="text" disabled />
                            </Form.Group>
                          </Col>
                          <Col className="col-6">
                            <Form.Group controlId="choiceContent">
                              <Form.Control
                                defaultValue={row.content || ''}
                                type="text"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  onChangeText(e, targetSelectList, row.displayIndex);
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Row>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  // 選択肢（縦軸）作成関数
  const itemList = () => {
    let targetSelectList: any[] | undefined = [];

    switch (answerMethod) {
      case ANSWER_METHOD.SINGLE_MATRIX:
        targetSelectList = matrixSingleItemList;
        break;
      case ANSWER_METHOD.MULTI_MATRIX:
        targetSelectList = matrixMultiItemList;
        break;
      default:
        break;
    }
    return (
      <DragDropContext onDragEnd={handleOnDragEndItem}>
        <Droppable droppableId="characters">
          {(provided) => (
            <Row className="align-items-center gx-3 mb-3" {...provided.droppableProps} ref={provided.innerRef}>
              {targetSelectList?.map((row, index: number) => (
                <Draggable key={row.displayIndex.toString()} draggableId={row.displayIndex.toString()} index={index}>
                  {(provided2) => (
                    <Row
                      className="align-items-center gx-3 mb-3"
                      ref={provided2.innerRef}
                      {...provided2.draggableProps}
                    >
                      <Col className="flex-shrink-0 flex-grow-0">
                        <button
                          type="button"
                          className="text-secondary"
                          {...provided2.dragHandleProps}
                          data-testid="vertical_LineButton"
                        >
                          <FontAwesomeIcon icon={faGripLines} fixedWidth />
                        </button>
                      </Col>
                      <Col className="flex-shrink-0 flex-grow-0">
                        <button
                          data-testid="vertical_EyeButton"
                          type="button"
                          className="text-secondary"
                          onClick={() => {
                            const newSelectionList =
                              targetSelectList?.map((normalList) => {
                                if (normalList.imageId === row.imageId) {
                                  return { ...normalList, displayFlg: !normalList.displayFlg };
                                }
                                return normalList;
                              }) || [];
                            setMatrixItemList(newSelectionList);
                          }}
                        >
                          <FontAwesomeIcon icon={row.displayFlg ? faEye : faEyeSlash} fixedWidth />
                        </button>
                      </Col>
                      <Col>
                        <Row className="gx-3">
                          <Col className="col-12">
                            <Form.Group controlId="choiceContentItem">
                              <Form.Control
                                defaultValue={row.content || ''}
                                type="text"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  onChangeText(e, targetSelectList, row.displayIndex);
                                }}
                                data-testid="verticalSelection_Content"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      {row.imageUrl === '' && (
                        <Col className="flex-shrink-0 flex-grow-0">
                          <Dropzone
                            onDrop={(accepted) => onItemListDrop(accepted, index, targetSelectList)}
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <button type="button" className="d-flex align-items-center" style={{ width: '9rem' }}>
                                  <FontAwesomeIcon
                                    icon={faFileUpload}
                                    className="text-secondary flex-shrink-0 me-1"
                                    fixedWidth
                                  />
                                  <span className="text-truncate flex-grow-0 text-start text-secondary">
                                    画像アップロード
                                  </span>
                                </button>
                              </div>
                            )}
                          </Dropzone>
                        </Col>
                      )}
                      {row.imageUrl && (
                        <Col
                          className={
                            enqueteType === ENQUETE_TYPE.BEFOREHAND
                              ? 'flex-shrink-0 flex-grow-0'
                              : ' flex-shrink-0 flex-grow-0 col-3.5'
                          }
                        >
                          <Col className="d-flex" style={{ width: '9rem' }}>
                            <img
                              className="m-2"
                              src={
                                (data.verticalImageList &&
                                  data.verticalImageList?.filter((obj) => obj.index === index)[0].path) ||
                                ''
                              }
                              alt="sample"
                              width="83%"
                            />
                            <Col className="ms-2">
                              <button
                                type="button"
                                className="me-2 align-items-center"
                                onClick={() => {
                                  const newSelectionList =
                                    targetSelectList?.map((current) =>
                                      current.imageId === row.imageId
                                        ? {
                                            ...current,
                                            imageId: null,
                                            imageUrl: '',
                                            fileName: '',
                                            imageIndex: undefined,
                                          }
                                        : current
                                    ) || [];
                                  setMatrixItemList(newSelectionList);

                                  const newImageList: Image[] = [];
                                  data.verticalImageList?.forEach((obj: Image) => {
                                    if (obj.index === index) {
                                      if (obj.isDefault) {
                                        newImageList.push({
                                          isDefault: obj.isDefault,
                                          id: obj.id,
                                        });
                                      }
                                      return;
                                    }
                                    newImageList.push(obj);
                                  });
                                  setData({ ...data, verticalImageList: newImageList });
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faTimesCircle}
                                  className="text-secondary flex-shrink-0 me-1"
                                  fixedWidth
                                />
                              </button>
                            </Col>
                          </Col>
                        </Col>
                      )}
                    </Row>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Row>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  // 「マトリクス 単一選択」
  // 「マトリクス 複数選択」」選択肢タブcontents
  const matrixAnswerArea = (
    <>
      <div className="bg-light p-3 me-4">
        <div className="d-flex justify-content-start">
          <Form.Label className="fw-bold">選択肢（横軸）</Form.Label>
        </div>
        <Card className="p-3">
          <div className="d-flex justify-content-end mb-2">
            <Button
              variant="link"
              className="text-secondary"
              onClick={onAddBeside}
              disabled={!adminFlg && selectType === 'global'}
            >
              <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
              追加
            </Button>
          </div>
          <div className="d-flex justify-content-start mt-2">
            <div className="mb-1" style={{ fontWeight: 'bolder', marginLeft: '5.5rem' }}>
              選択肢
            </div>
            <div
              className="mb-1"
              style={{
                fontWeight: 'bolder',
                marginLeft: enqueteType === ENQUETE_TYPE.BEFOREHAND ? '33.1rem' : '23.8rem',
              }}
            >
              {enqueteType === ENQUETE_TYPE.BEFOREHAND ? '当選' : '得点'}
            </div>
            <div className="ms-2">
              {enqueteType !== ENQUETE_TYPE.BEFOREHAND && (
                <Form.Check
                  size={100}
                  type="switch"
                  id="searchEngineFlg"
                  checked={!updParam.selectionPointDisableFlg}
                  onChange={() => {
                    setUpdParam({ ...updParam, selectionPointDisableFlg: !updParam.selectionPointDisableFlg });
                  }}
                  disabled={!adminFlg && selectType === 'global'}
                />
              )}
            </div>
            {enqueteType === ENQUETE_TYPE.BEFOREHAND && (
              <div className="mb-1" style={{ fontWeight: 'bolder', marginLeft: '1.6rem' }}>
                落選
              </div>
            )}
          </div>
          <ListGroup variant="flush">
            <ListGroup.Item disabled={!adminFlg && selectType === 'global'}>{selectList()}</ListGroup.Item>
          </ListGroup>
        </Card>
      </div>
      <div className="bg-light p-3 me-4 mt-4">
        <div className="d-flex justify-content-start">
          <Form.Label className="fw-bold">項目（縦軸）</Form.Label>
        </div>
        <Card className="p-3">
          <div className="d-flex justify-content-end mb-2">
            <Button
              variant="link"
              className="text-secondary"
              onClick={onAddVertical}
              disabled={!adminFlg && selectType === 'global'}
            >
              <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
              追加
            </Button>
          </div>
          <div className="d-flex justify-content-start mt-2 me-4">
            <div className="mb-1" style={{ fontWeight: 'bolder', marginLeft: '5.5rem' }}>
              選択肢
            </div>
          </div>
          <ListGroup variant="flush">
            <ListGroup.Item disabled={!adminFlg && selectType === 'global'}>{itemList()}</ListGroup.Item>
          </ListGroup>
        </Card>
      </div>
    </>
  );

  // 「ラジオボタン 単一選択」
  // 「プルダウン単一選択」
  // 「チェックボックス複数選択」選択肢タブcontents
  const answerArea = (
    <>
      <div className="d-flex justify-content-end mb-2 me-3">
        <Button
          variant="link"
          className="text-secondary"
          onClick={onAddBesideOnly}
          disabled={!adminFlg && selectType === 'global'}
        >
          <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
          追加
        </Button>
      </div>
      <div
        className="d-flex justify-content-start mt-2 me-4"
        style={{ fontWeight: 'bolder', borderBottom: '1px solid rgba(0,0,0,.125)' }}
      >
        <div
          className="mb-1"
          style={{
            fontWeight: 'bolder',
            marginLeft: '5.5rem',
            marginRight:
              enqueteType === ENQUETE_TYPE.OTHER || enqueteType === ENQUETE_TYPE.ENQUETE_MONITOR ? '1rem' : '0rem',
          }}
        >
          選択肢
        </div>
        {answerMethod !== ANSWER_METHOD.PULLDOWN ? (
          <div
            className="mb-1"
            style={{
              fontWeight: 'bolder',
              marginLeft: enqueteType === ENQUETE_TYPE.BEFOREHAND ? '37.6rem' : '28.5rem',
            }}
          >
            {enqueteType === ENQUETE_TYPE.BEFOREHAND ? '当選' : '得点'}
          </div>
        ) : (
          <div
            className="mb-1"
            style={{
              fontWeight: 'bolder',
              marginLeft: '35.6rem',
            }}
          >
            {enqueteType === ENQUETE_TYPE.BEFOREHAND ? '当選' : '得点'}
          </div>
        )}
        <div className="ms-2">
          {enqueteType !== ENQUETE_TYPE.BEFOREHAND && (
            <Form.Check
              size={100}
              type="switch"
              id="searchEngineFlg"
              checked={!updParam.selectionPointDisableFlg}
              onChange={() => {
                setUpdParam({ ...updParam, selectionPointDisableFlg: !updParam.selectionPointDisableFlg });
              }}
              disabled={!adminFlg && selectType === 'global'}
            />
          )}
        </div>
        {enqueteType === ENQUETE_TYPE.BEFOREHAND && (
          <div className="mb-1" style={{ fontWeight: 'bolder', marginLeft: '1.5rem' }}>
            落選
          </div>
        )}
      </div>
      <ListGroup variant="flush">
        <ListGroup.Item disabled={!adminFlg && selectType === 'global'}>{selectList()}</ListGroup.Item>
      </ListGroup>
    </>
  );

  // 「会員属性」タブ専用
  const answerArea2 = (
    <>
      <div
        className="d-flex justify-content-start mt-2 me-4"
        style={{ fontWeight: 'bolder', borderBottom: '1px solid rgba(0,0,0,.125)' }}
      >
        <div
          className="mb-1"
          style={{
            fontWeight: 'bolder',
            marginLeft: '5.5rem',
            marginRight: '0rem',
          }}
        >
          選択肢
        </div>
      </div>
      <ListGroup variant="flush">
        <ListGroup.Item>{selectList2()}</ListGroup.Item>
      </ListGroup>
    </>
  );

  // 【積上式アンケート編集】
  // からの遷移の場合のModalContents
  const pileupContents = (
    <>
      {questionId && data.clients && data.clients.length > 0 && (
        <Alert variant="info">
          この設問は下記のクライアントで使用されています。
          {data.clients.map((d, index) => (
            <li>{d.name}</li>
          ))}
        </Alert>
      )}
      {!pointError.result && (
        <Alert className="me-2 ms-2 mb-4" variant="danger">
          {pointError.message}
        </Alert>
      )}
      <Form.Group className="mb-4">
        <Form.Label>独自カテゴリ</Form.Label>
        <Form.Select
          value={updParam.questionCategoryId || ''}
          onChange={(e) => {
            setUpdParam({ ...updParam, questionCategoryId: Number(e.target.value) });
          }}
        >
          <option value="">&nbsp;</option>
          {/* {originalCategories?.map(({ id, name, level }) => (
            <option key={id} value={id} style={{ fontWeight: level === 1 ? 'bold' : '' }}>
              {name}
            </option>
          ))} */}
        </Form.Select>
      </Form.Group>
    </>
  );

  // 【選択式アンケート編集】
  // からの遷移の場合のModalContents
  const choiceContents = (
    <>
      {questionId && data.clients && data.clients.length > 0 && (
        <Alert variant="info">
          この設問は下記のクライアントで使用されています。
          {data.clients.map((d, index) => (
            <li>{d.name}</li>
          ))}
        </Alert>
      )}
      {selectType === 'global' && (
        <>
          <Form.Group className="mb-4">
            <Form.Label>大カテゴリ</Form.Label>
            <Form.Select
              value={categoryIds?.large ?? ''}
              onChange={(e) => {
                setCategoryIds(() => ({ large: e.target.value !== '' ? Number(e.target.value) : undefined }));
              }}
              disabled={!adminFlg}
            >
              <option value="">&nbsp;</option>
              {categories?.largeQuestionCategories?.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>中カテゴリ</Form.Label>
            <Form.Select
              value={categoryIds?.medium ?? ''}
              onChange={(e) => {
                setCategoryIds(({ large }) => ({
                  large,
                  medium: e.target.value !== '' ? Number(e.target.value) : undefined,
                }));
              }}
              disabled={!adminFlg}
            >
              <option value="">&nbsp;</option>
              {categories?.mediumQuestionCategoryMap &&
                categoryIds?.large &&
                categories?.mediumQuestionCategoryMap[categoryIds?.large]?.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>小カテゴリ</Form.Label>
            <Form.Select
              value={categoryIds?.small ?? ''}
              onChange={(e) => {
                setCategoryIds((prev) => ({
                  ...prev,
                  small: e.target.value !== '' ? Number(e.target.value) : undefined,
                }));
              }}
              disabled={!adminFlg}
            >
              <option value="">&nbsp;</option>
              {categories?.smallQuestionCategoryMap &&
                categoryIds?.medium &&
                categories?.smallQuestionCategoryMap[categoryIds?.medium]?.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>レベル</Form.Label>
            <Form.Select
              value={updParam?.level ?? ''}
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  level: e.target.value !== '' ? Number(e.target.value) : undefined,
                }));
              }}
              disabled={!adminFlg}
            >
              <option value="">&nbsp;</option>
              {[...Array(5)].map((_, i) => (
                <option key={i + 1} value={i + 1}>
                  レベル{i + 1}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>設問タイプ</Form.Label>
            <Form.Select
              value={data.questionTypeId || 0}
              onChange={(event) => {
                setData({ ...data, questionTypeId: Number(event.target.value) });
                setUpdParam({ ...updParam, questionTypeId: Number(event.target.value) });
              }}
              disabled={!adminFlg}
            >
              <option value={0}>&nbsp;</option>
              {[
                'スコア',
                'スコア理由',
                '基礎設定',
                '輝いてるスタッフ名',
                '輝いてるスタッフ理由',
                '設問集計',
                'その他コメント',
                'ファンくる設問',
                '管理画面に出さない(集計対象外)',
              ].map((label, i) => (
                <option key={i + 1} value={i + 1}>
                  {label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </>
      )}

      {selectType === 'company' && (
        <Form.Group className="mb-4">
          <Form.Label>中カテゴリ</Form.Label>
          <Form.Select
            value={categoryIds?.medium ?? ''}
            onChange={(e) => {
              setCategoryIds(() => ({
                medium: e.target.value !== '' ? Number(e.target.value) : undefined,
              }));
            }}
          >
            <option value="">&nbsp;</option>
            {categories &&
              categories?.companyMediumQuestionCategories?.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
      )}

      <Form.Group className="mb-4">
        <Form.Label>区分管理</Form.Label>
        <TagsInput
          tags={managementSectionTags}
          setTags={setManagementSectionTags}
          options={managementSectionList.map(({ name }) => name)}
          pill
          disabled={!adminFlg && selectType === 'global'}
        />
      </Form.Group>
    </>
  );

  // 【会員属性アンケート】
  const memberContents = (
    <>
      <Form.Group className="mb-4">
        <Form.Label>会員タグ</Form.Label>
        <Form.Select
          value={updParam.customerTagId || ''}
          onChange={(e) => {
            setUpdParam((prev) => ({
              ...prev,
              customerTagId: e.target.value !== '' ? Number(e.target.value) : undefined,
            }));
            const tag = customerTags?.find((it) => it.tagId === Number(e.target.value));
            if (tag) {
              const newData: any[] = [];
              for (let i = 0; i < tag.customerTagAnswerList.length; i += 1) {
                const tagAnswer = tag.customerTagAnswerList[i];
                newData.push({
                  content: '',
                  displayFlg: true,
                  imageId: null,
                  imageUrl: '',
                  loseFlg: false,
                  point: 0,
                  selectionId: undefined,
                  selectionIds: [],
                  winFlg: false,
                  fileName: '',
                  displayIndex: i + 1,
                  customerTagId: tag.tagId,
                  customerTagAnswerId: tagAnswer.id,
                  customerTagAnswerName: tagAnswer.tagValue,
                });
              }
              setRadioSelectionList(newData);
              setCheckSelectionList(newData);
              setPullDownSelectionList(newData);
            }
          }}
        >
          <option value="">&nbsp;</option>
          {customerTags?.map(({ tagId, tagName }) => (
            <option key={tagId} value={tagId}>
              {tagName}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </>
  );

  const inputMessage = (inputType: string) => {
    let message = '';
    if (inputType === 'max') {
      if (answerMethod === ANSWER_METHOD.TEXT || answerMethod === ANSWER_METHOD.IMAGE) {
        message = '回答最大文字数';
      } else if (answerMethod === ANSWER_METHOD.CHECKBOX || answerMethod === ANSWER_METHOD.MULTI_MATRIX) {
        message = '最大選択数';
      }
    }
    if (inputType === 'min') {
      if (answerMethod === ANSWER_METHOD.TEXT || answerMethod === ANSWER_METHOD.IMAGE) {
        message = '回答最小文字数';
      } else if (answerMethod === ANSWER_METHOD.CHECKBOX || answerMethod === ANSWER_METHOD.MULTI_MATRIX) {
        message = '最小選択数';
      }
    }
    return message;
  };

  return (
    <Modal
      size="xl"
      isModal={isModal}
      onHide={() => {
        setIsModal(false);
        if (setQuestionId) setQuestionId(undefined);
        setPointError({ result: true, message: '' });
      }}
      scrollable
      centered
      closeButton
      body={
        <>
          <div className="d-flex justify-content-end mb-4">
            <Button
              variant="link"
              className="ms-2"
              onClick={() => {
                setIsModal(false);
                if (setQuestionId) setQuestionId(undefined);
                setPointError({ result: true, message: '' });
              }}
            >
              キャンセル
            </Button>
            {!salesFlg && (
              <Button className="ms-2" onClick={onhandleKeep} disabled={!adminFlg && selectType === 'global'}>
                保存
              </Button>
            )}
          </div>

          {levelFlg && (
            <Alert className="me-2 ms-2 mb-4" variant="danger" style={{ marginTop: 10 }}>
              レベルを選択してください
            </Alert>
          )}
          {questionTypeFlg && (
            <Alert className="me-2 ms-2 mb-4" variant="danger" style={{ marginTop: 10 }}>
              設問タイプを選択してください。
            </Alert>
          )}
          {choiceCheckFlg && (
            <Alert className="me-2 ms-2 mb-4" variant="danger" style={{ marginTop: 10 }}>
              回答選択肢を設定してください。
            </Alert>
          )}
          {questionContentFlg && (
            <Alert className="me-2 ms-2 mb-4" variant="danger" style={{ marginTop: 10 }}>
              質問文が入力されていません。
            </Alert>
          )}
          {invalidInputNumberFlg && (
            <Alert className="me-2 ms-2 mb-4" variant="danger" style={{ marginTop: 10 }}>
              {`${inputMessage('max')}は${inputMessage('min')}よりも大きい数値を入力してください`}
            </Alert>
          )}
          {customerTagFlg && (
            <Alert className="me-2 ms-2 mb-4" variant="danger" style={{ marginTop: 10 }}>
              会員タグを選択してください
            </Alert>
          )}
          {updResult?.errorCode && updResult?.errorMessage && (
            <Alert
              className="me-2 ms-2 mb-4"
              variant="danger"
            >{`${updResult.errorMessage} (エラーコード：${updResult.errorCode})`}</Alert>
          )}

          {enqueteType === ENQUETE_TYPE.SELECT && choiceContents}
          {enqueteType === ENQUETE_TYPE.PILEUP && pileupContents}
          {enqueteType === ENQUETE_TYPE.MEMBERSHIP && memberContents}

          {questionId &&
            data?.enqueteList?.length !== 0 &&
            (enqueteType === ENQUETE_TYPE.SELECT || enqueteType === ENQUETE_TYPE.PILEUP) && (
              <Accordion className="bg-light mb-4" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>この設問を利用しているアンケート</Accordion.Header>
                  <Accordion.Body>
                    {data?.enqueteList?.map((row: QuestionInfoEnqueteListRowDataResponse, ind: number) => (
                      <Button
                        key={ind}
                        variant="link"
                        className="ms-2"
                        onClick={() => {
                          history.push(`./enquete/${row?.enqueteId}`);
                        }}
                        disabled={!adminFlg && selectType === 'global'}
                      >
                        {`${row.enqueteId} ${row.enqueteName}`}
                      </Button>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}

          {enqueteType !== ENQUETE_TYPE.BEFOREHAND && enqueteType !== ENQUETE_TYPE.MEMBERSHIP && (
            <>
              <div className="mb-4 p-3 bg-light">
                <Form.Check id="winningUser" className="d-flex">
                  <Form.Check.Input
                    data-testid="winCheckBox"
                    className="flex-shrink-0 me-2"
                    type="checkbox"
                    name="checkBoxGroup01"
                    onChange={onChange}
                    disabled={!adminFlg && selectType === 'global'}
                  />
                  <Form.Check.Label>当選済みユーザーへの修正反映（管理者権限）</Form.Check.Label>
                </Form.Check>
              </div>
              <div className="mb-4 p-3 bg-light">
                <Form.Check id="required" className="d-flex">
                  <Form.Check.Input
                    data-testid="requiredCheckBox"
                    className="flex-shrink-0 me-2"
                    type="checkbox"
                    name="checkBoxGroup01"
                    onChange={onChange}
                    checked={updParam.requiredFlg}
                    disabled={!adminFlg && selectType === 'global'}
                  />
                  <Form.Check.Label>必須項目</Form.Check.Label>
                </Form.Check>
              </div>
            </>
          )}

          {enqueteType !== ENQUETE_TYPE.MEMBERSHIP && (
            <Tab.Container id="left-tabs-example" activeKey={answerMethod}>
              <Row className="ms-2">
                <Col sm="auto">
                  <Nav variant="pills" className="flex-column" onSelect={(key) => setAnswerMethod(Number(key))}>
                    {[
                      'テキスト入力',
                      'ラジオボタン\n単一選択',
                      'プルダウン\n単一選択',
                      'チェックボックス\n複数選択',
                      'マトリクス\n単一選択',
                      'マトリクス\n複数選択',
                      '画像アップロード',
                      'スライダー',
                      '日付選択',
                      '時間選択',
                    ].map((label, i) => (
                      <Nav.Item key={label}>
                        <Nav.Link eventKey={i + 1} disabled={!adminFlg && selectType === 'global'}>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="flex-grow-1" style={{ whiteSpace: 'pre-wrap' }}>
                              {label}
                            </div>
                            <div>
                              <OverlayTrigger placement="top" overlay={monitorPopover}>
                                <Button variant="link" className="text-secondary">
                                  <FontAwesomeIcon icon={faQuestionCircle} fixedWidth />
                                </Button>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content className="me-4">
                    <div style={{ width: '110%' }}>
                      <Form.Group className="mb-4" controlId="questionContent">
                        <Form.Label className="fw-bold">質問文</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={onChangeText}
                          value={updParam?.content || ''}
                          data-testid="content"
                          disabled={!adminFlg && selectType === 'global'}
                        />
                      </Form.Group>

                      {(answerMethod === ANSWER_METHOD.TEXT ||
                        answerMethod === ANSWER_METHOD.IMAGE ||
                        answerMethod === ANSWER_METHOD.CHECKBOX ||
                        answerMethod === ANSWER_METHOD.MULTI_MATRIX) && (
                        <Form.Group className="mb-4 d-flex">
                          <Row>
                            <Col>
                              <Form.Label className="fw-bold">{inputMessage('min') || ''}</Form.Label>
                              <Form.Control
                                type="number"
                                value={updParam?.minimumInput || ''}
                                min="1"
                                onChange={(event) => {
                                  const { value } = event.target;
                                  const numericValue = value == null || value === '' ? undefined : Number(value);
                                  setUpdParam({ ...updParam, minimumInput: numericValue });
                                }}
                                data-testid="minimumInput"
                                disabled={!adminFlg && selectType === 'global'}
                              />
                            </Col>
                            <Col>
                              <Form.Label className="fw-bold">{inputMessage('max') || ''}</Form.Label>
                              <Form.Control
                                type="number"
                                value={updParam?.maximumInput || ''}
                                min="1"
                                onChange={(event) => {
                                  const { value } = event.target;
                                  const numericValue = value == null || value === '' ? undefined : Number(value);
                                  setUpdParam({ ...updParam, maximumInput: numericValue });
                                }}
                                data-testid="maximumInput"
                                disabled={!adminFlg && selectType === 'global'}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      )}

                      {answerMethod === ANSWER_METHOD.TEXT && (
                        <Form.Group className="mb-4">
                          <Form.Label className="fw-bold">回答形式</Form.Label>
                          <Form.Check id="multiLine" className="d-flex">
                            <Form.Check.Input
                              data-testid="requiredCheckBox"
                              className="flex-shrink-0 me-2"
                              type="checkbox"
                              name="checkBoxGroup01"
                              onChange={onChange}
                              defaultChecked={updParam?.multiLineFlg || true}
                              disabled={!adminFlg && selectType === 'global'}
                            />
                            <Form.Check.Label>複数行での回答を許可する</Form.Check.Label>
                          </Form.Check>
                        </Form.Group>
                      )}

                      <Form.Group className="mb-4">
                        <Form.Label className="fw-bold">画像</Form.Label>
                        {questionImage?.path ? (
                          <>
                            {!adminFlg && selectType === 'global' ? (
                              <ImageCard url={questionImage?.path} />
                            ) : (
                              <ImageCard
                                url={questionImage?.path}
                                onDelete={() => {
                                  setQuestionImage({ isDefault: false });
                                }}
                              />
                            )}
                          </>
                        ) : (
                          <DropzoneComponent
                            onDrop={onDropAttached}
                            type="image"
                            disabled={!adminFlg && selectType === 'global'}
                          />
                        )}
                      </Form.Group>

                      <Form.Group className="mb-4" controlId="url">
                        <Form.Label className="fw-bold">URL</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={onChangeText}
                          value={updParam?.url || ''}
                          data-testid="url"
                          disabled={!adminFlg && selectType === 'global'}
                        />
                      </Form.Group>

                      {(answerMethod === ANSWER_METHOD.RADIO ||
                        answerMethod === ANSWER_METHOD.PULLDOWN ||
                        answerMethod === ANSWER_METHOD.CHECKBOX ||
                        answerMethod === ANSWER_METHOD.SLIDER) &&
                        answerArea}

                      {(answerMethod === ANSWER_METHOD.SINGLE_MATRIX || answerMethod === ANSWER_METHOD.MULTI_MATRIX) &&
                        matrixAnswerArea}
                    </div>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          )}

          {enqueteType === ENQUETE_TYPE.MEMBERSHIP && (
            <Tab.Container id="left-tabs-example" activeKey={answerMethod}>
              <Row className="ms-2">
                <Col sm="auto">
                  <Nav variant="pills" className="flex-column" onSelect={(key) => setAnswerMethod(Number(key))}>
                    {['ラジオボタン\n単一選択', 'プルダウン\n単一選択', 'チェックボックス\n複数選択'].map(
                      (label, i) => (
                        <Nav.Item key={label}>
                          <Nav.Link eventKey={i + 2}>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="flex-grow-1" style={{ whiteSpace: 'pre-wrap' }}>
                                {label}
                              </div>
                              <div>
                                <OverlayTrigger placement="top" overlay={monitorPopover}>
                                  <Button variant="link" className="text-secondary">
                                    <FontAwesomeIcon icon={faQuestionCircle} fixedWidth />
                                  </Button>
                                </OverlayTrigger>
                              </div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )
                    )}
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content className="me-4">
                    <div style={{ width: '110%' }}>
                      <Form.Group className="mb-4" controlId="questionContent">
                        <Form.Label className="fw-bold">質問文</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={onChangeText}
                          value={updParam?.content || ''}
                          data-testid="content"
                        />
                      </Form.Group>
                      {(answerMethod === ANSWER_METHOD.RADIO ||
                        answerMethod === ANSWER_METHOD.PULLDOWN ||
                        answerMethod === ANSWER_METHOD.CHECKBOX) &&
                        answerArea2}
                    </div>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          )}
        </>
      }
    />
  );
};
