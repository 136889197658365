export const TITLE = {
  KEISAI: {
    ABOUT_OPENING: '営業時間文言設定',
    APPLICATION: '申込書一覧画面',
    APPLICATION_MODIFY: '申込書作成/編集画面',
    CLIENT_OF_REQUIRED_NUMBER: 'クライアント派遣マスト数一覧画面',
    CLIENT_OF_REQUIRED_NUMBER_TARGET: 'クライアント派遣マスト数作成/編集画面',
    FLOATING_REWARD: '変動謝礼設定画面',
    FLOATING_REWARD_CONFIRM: '変動謝礼設定確認画面',
    FLOATING_REWARD_FILE_UPLOAD: '変動謝礼設定アップロード画面',
    BRAND: 'ブランド一覧画面',
    BRAND_DETAIL: 'ブランド詳細画面',
    BRAND_MODIFY: 'ブランド作成/編集画面',
    BRAND_MODIFY_CONFIRM: 'ブランド作成/編集確認画面',
    BRAND_RICH: 'ブランドリッチ化設定画面',
    BRAND_OEM: 'OEM表示先設定(ブランド)画面',
    BRAND_OEM_CONFIRM: 'OEM表示先設定(ブランド)確認画面',
    CAMPAIGN: 'キャンペーン一覧画面',
    CAMPAIGN_DETAIL: 'キャンペーン詳細画面',
    CAMPAIGN_MODIFY: 'キャンペーン作成/編集画面',
    CAMPAIGN_MODIFY_CONFIRM: 'キャンペーン作成/編集確認画面',
    CAMPAIGN_RICH: 'キャンペーンリッチ化設定画面',
    CAMPAIGN_OEM: 'OEM表示先設定(キャンペーン)画面',
    CAMPAIGN_OEM_CONFIRM: 'OEM表示先設定(キャンペーン)確認画面',
    COMMENT: '申し送り事項確認画面',
    CONTRACT_RESERVATION: '契約予約画面',
    FORCE_WINNING: '強制当選画面',
    FORCE_UNSEATED: '強制落選画面',
    MONITOR_RULE_SET: 'モニタールールセット一覧画面',
    MONITOR_RULE_SET_MODIFY: 'モニタールールセット編集画面',
    MONITOR_RULE_SET_DETAIL: 'モニタールールセット詳細画面',
    MONITOR_RULE_SET_TEMPLATE: 'モニタールールセットテンプレート一覧画面',
    MONITOR_RULE_SET_TEMPLATE_MODIFY: 'モニタールールセットテンプレート編集画面',
    MONITOR_RULE_QUESTION: '設問とモニタールール紐付け画面',
    MONITOR_BASE_MODIFY: 'モニター作成_編集画面',
    MONITOR_MODIFY: '月次モニター編集画面',
    MONITOR_LIST: 'モニター一覧画面',
    MONITOR_APPLY_HISTORY_LIST: 'モニター応募履歴一覧画面',
    MANAGEMENT_ANNOUNCE: '運営お知らせ一覧画面',
    MANAGEMENT_ANNOUNCE_MODIFY: '運営お知らせ作成/編集画面',
    MANAGEMENT_ANNOUNCE_MODIFY_CONFIRM: '運営お知らせ作成/編集確認画面',
    ANNOUNCE: 'お知らせ一覧画面',
    ANNOUNCE_MODIFY: 'お知らせ作成/編集画面',
    ANNOUNCE_MODIFY_CONFIRM: 'お知らせ作成/編集確認画面',
    BANNER_LIST: 'バナー広告設定一覧画面',
    BANNER_ITEM_MODIFY: '追加/編集画面',
    BANNER_ITEM_MODIFY_CONFIRM: '追加/編集確認画面',
    BANNER_INSITE: 'インサイト画面',
    BANNER_PRIORITY_MODIFY: '優先順位設定画面',
    BANNER_COMMON: '共通設定画面',
    POSTING_REQUEST_LIST: '掲載依頼一覧画面',
    POSTING_REQUEST: '掲載依頼画面',
    POSTING_RESERVATION_LIST: '掲載予約一覧画面',
    POSTING_RESERVATION: '掲載予約画面',
    CLIENT_ACCOUNT_SETTING: 'クライアントアカウント設定画面',
    CLIENT_ORGANIZATION_SETTING: 'クライアント組織設定画面',
    CLIENT: 'クライアント一覧画面',
    CLIENT_DETAIL: 'クライアント詳細画面',
    PRIOR_ENQUETE_FILE: '事前アンケートダウンロード画面',
    POST_ENQUETE_FILE: '事後アンケートダウンロード画面',
    RECEIPT_FILE: 'レシートダウンロード画面',
    ENQUETE_FILE: 'アンケート画像ダウンロード画面',
    BULK_STATUS_RESERVATION: '一括ステータス予約',
    BULK_STATUS_RESERVATION_CONFIRM: '一括ステータス予約登録確認',
    MONITOR_APICLIENTS_SETTING: 'OEM表示範囲設定画面',
    OEM_BALANCE: 'OEM露出調整（掲載日ずらし）',
  },
} as const;
