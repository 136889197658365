import { BASE_PATH } from './api-client/base';

export const BILLING_FLOW_STATUS = {
  WAITING_COMPLETE_APPLY: 0, // "派遣完了待ち"
  WAITING_CREATE_STOCK: 1, // "STOCK作成待ち"
  SALES_DPT_UNCOMMITTED: 2, // "営業確認待ち"
  SALES_DPT_MANAGER_UNCOMMITTED: 3, // "営業上長確認待ち"
  MANAGE_DPT_UNCOMMITTED: 4, // "管理部確認待ち"
  UNPUBLISHED: 5, // "発行待ち"
  PUBLISHED: 6, // "発行済み"
  ALL: '',
};

export const BILLING_STATUS = {
  BILLING: 0, // "請求"
  UN_BILLING: 1, // "非請求"
  CARRY_OVER: 2, // "翌月繰越"
  UN_BILLING_APPLYING: 3, // "非請求申請中"
};

export const BILLING_DIVISION = {
  ES: 'ES',
  SPOT: 'SPOT',
  STOCK: 'STOCK',
  OTHER: 'その他',
  OPTION: 'オプション',
  APPLICATION: '派遣',
};

export const BILLING_FEE_RATE_TYPE = {
  FIXED: 'FIXED',
  RATIO: 'RATIO',
};

export const STR_PERCENT = '%';

export const BILLING_DEFAULT_FADE_TIME = 2000;

export const BILLING_RECREATE_BATCH_URL_YMD = 'YYYYMMDD';
export const BILLING_RECREATE_BATCH_URL_BILLINGDESTINATIONID = 'BILLINGDESTINATIONID';

export const BILLING_RECREATE_BATCH_URL_BILLINGHEADERID = 'BILLINGHEADERID';

export const BILLING_RECREATE_BATCH_URL = `${BASE_PATH}/api/job/reCreateBillingDataBatch?p1=${BILLING_RECREATE_BATCH_URL_YMD}&p2=${BILLING_RECREATE_BATCH_URL_BILLINGDESTINATIONID}&p3=${BILLING_RECREATE_BATCH_URL_BILLINGHEADERID}`;
export const BILLING_INVOICE_PDF_TYPE = {
  Item: 'ITEM', // 商品別明細
  Shop: 'SHOP', // 店舗別明細
  Monitor: 'MONITOR', // 調査別明細
  Apply: 'APPLY', // 応募別明細
};

export const DATAURI_REG_EXP = /data:.*\/.*;base64,/;

export const FEE_PAYMENT_CONDITION = {
  IN_TERM: 0, // "期間内"
  SHOW_MONITOR: 1, // "モニター実施"
  HAS_APPS: 2, // "完了あり"
  HAS_APPS_ONLY_FIRST: 3, // "完了あり初月のみ"
};

export const FEE_PAYMENT_CONDITION_SELECTION = [
  { value: FEE_PAYMENT_CONDITION.IN_TERM, name: '契約期間内' },
  { value: FEE_PAYMENT_CONDITION.SHOW_MONITOR, name: '調査実施' },
  { value: FEE_PAYMENT_CONDITION.HAS_APPS, name: '完了あり' },
  { value: FEE_PAYMENT_CONDITION.HAS_APPS_ONLY_FIRST, name: '完了あり初月のみ' },
];

export const BILLING_PUBLISHMENT_TYPE = {
  PUBLISH_ALL: 0, // 全て
  PUBLISH_BILLING_SHOP_APP: 1, // 請求書+店舗別明細+派遣別明細
  PUBLISH_BILLING_ITEM_MONITOR: 2, // 請求書+調査別明細
  PUBLISH_BILLING_ITEM_APP: 3, // 請求書+派遣別明細
  PUBLISH_BILLING_ITEM_SHOP: 4, // 請求書+店舗別明細
  PUBLISH_BILLING_ITEM: 5, // 請求書
  PUBLISH_BILLING_ITEM_SHOP_MONITOR: 6, // 請求書+店舗別明細+調査別明細
  PUBLISH_BILLING_ITEM_MONITOR_APP: 7, // 請求書+調査別明細+派遣別明細
  PUBLISH_NOTHING: 8, // なし
};

export const UNSAVED_ID = 0;

export const BILLING_MAIL_TYPE = {
  TO: 0,
  CC: 1,
};

export const BILLING_APPROVAL_TYPE = {
  UUCOMI: 0, // ユーコミ
  SALES: 1, // 営業
};

export const BILLING_APPROVAL_STATUS = {
  WAITING_APPROVAL: 0, // 上長承認待ち
  SYSTEM_COMMITTED: 1, // システム反映済み
  REJECTED: 2, // 非承認
  BILLING_STARTED: 3, // 請求業務が進んでいる
};

export const DEFAULT_TAX_RATE = 10;

export const BILLING_CARRY_OVER_TYPE = {
  ALL: 0, // 帳票ごと
  CONTRACT_SHOP: 1, // 店舗指定
};

/**
 * どの単位の商品か
 */
export const BILLING_ITEM_RELATION_TYPE = {
  CONTRACT: 0, // 契約
  CONTRACT_SHOP: 1, // 契約店舗
  MONITOR: 2, // 調査
  APPLY: 3, // 応募
};

// フローステータス名
export const FLOW_STATUS_STRING = new Map<number, string>([
  [0, '派遣完了待ち'],
  [1, 'STOCK作成待ち'],
  [2, '営業確認待ち'],
  [3, '営業上長確認待ち'],
  [4, '管理部確認待ち'],
  [5, '発行待ち'],
  [6, '発行済み'],
]);

export const BILLING_FLOW_STATUS_FOR_SELECTION: { label: string; value: number }[] = [
  {
    label: FLOW_STATUS_STRING.get(BILLING_FLOW_STATUS.WAITING_COMPLETE_APPLY)!,
    value: BILLING_FLOW_STATUS.WAITING_COMPLETE_APPLY,
  }, // "派遣完了待ち"
  {
    label: FLOW_STATUS_STRING.get(BILLING_FLOW_STATUS.SALES_DPT_UNCOMMITTED)!,
    value: BILLING_FLOW_STATUS.SALES_DPT_UNCOMMITTED,
  }, // "営業確認待ち"
  {
    label: FLOW_STATUS_STRING.get(BILLING_FLOW_STATUS.SALES_DPT_MANAGER_UNCOMMITTED)!,
    value: BILLING_FLOW_STATUS.SALES_DPT_MANAGER_UNCOMMITTED,
  }, // "上長確認待ち"
  {
    label: FLOW_STATUS_STRING.get(BILLING_FLOW_STATUS.MANAGE_DPT_UNCOMMITTED)!,
    value: BILLING_FLOW_STATUS.MANAGE_DPT_UNCOMMITTED,
  }, // "管理部確認待ち"
  {
    label: FLOW_STATUS_STRING.get(BILLING_FLOW_STATUS.UNPUBLISHED)!,
    value: BILLING_FLOW_STATUS.UNPUBLISHED,
  }, // "発行待ち"
  {
    label: FLOW_STATUS_STRING.get(BILLING_FLOW_STATUS.PUBLISHED)!,
    value: BILLING_FLOW_STATUS.PUBLISHED,
  }, // "発行済み"
];

export const BILLING_PDF_SETTINGS = {
  ITEM_DETAIL_ITEM_NAME: 9,
  ITEM_DETAIL_UNIT_PRICE: 2.5,
  ITEM_DETAIL_QUANTITY: 1.5,
  ITEM_DETAIL_UNIT_NAME: 1.5,
  ITEM_DETAIL_AMOUNT: 2.5,
  ITEM_DETAIL_TAX: 1.45,
  SHOP_DETAIL_PADDINGHORIZONTAL: 29, // 店舗別明細_幅
  SHOP_DETAIL_NO: 0.75, // "店舗別明細_No"
  SHOP_DETAIL_SHOP_NAME: 5.75, // "店舗別明細_店舗名"
  SHOP_DETAIL_REMARKS: 4.25, // "店舗別明細_備考"
  SHOP_DETAIL_REPORT_COUNT: 1, // "店舗別明細_レポート件数"
  SHOP_DETAIL_OTHERS: 1.75, // "店舗別明細_上記以外"
  MONITOR_DETAIL_PADDINGHORIZONTAL: 27, // 店舗別明細_幅
  MONITOR_DETAIL_NO: 1, // "店舗別明細_No"
  MONITOR_DETAIL_SHOP_NAME: 5.5, // "店舗別明細_店舗名"
  MONITOR_DETAIL_CONTRACT_NAME: 2.5, // "店舗別明細_店舗名"
  MONITOR_DETAIL_MONITOR_NAME: 3.0, // "店舗別明細_店舗名"
  MONITOR_DETAIL_REMARKS: 3.15, // "店舗別明細_備考"
  MONITOR_DETAIL_REPORT_COUNT: 1, // "調査別明細_レポート件数"
  MONITOR_DETAIL_OTHERS: 1.75, // "店舗別明細_上記以外"
  APPLICATION_DETAIL_PADDINGHORIZONTAL: 16, // 店舗別明細_幅
  APPLICATION_DETAIL_NO: 1, // "店舗別明細_No"
  APPLICATION_DETAIL_SHOP_NAME: 6.5, // "店舗別明細_店舗名"
  APPLICATION_DETAIL_CONTRACT_NAME: 2.5, // "店舗別明細_店舗名"
  APPLICATION_DETAIL_MONITOR_NAME: 3.0, // "店舗別明細_店舗名"
  APPLICATION_DETAIL_REMARKS: 4, // "店舗別明細_備考"
  APPLICATION_DETAIL_OTHERS: 1.75, // "店舗別明細_上記以外"
  MAX_LINE_DEFAULT: 3, // 1行の最大幅
  MAX_LINE_NUM_DEFAULT: 29, // 1ページの最大行数
};

export const EXCEL_MONEY_FORMAT = '¥#,##0;¥-#,##0';
export const EXCEL_PERCENT_FORMAT = '0%';

export const SAMA = '様';

/**
 * 帳票アップロードに使う帳票の種別
 */
export const BILLING_FILE_TYPE = {
  ITEM: 0, // 商品別明細
  SHOP: 1, // 店舗別明細
  MONITOR: 2, // 調査別明細
  APPLY: 3, // 派遣別明細
  ALL: 4, // エクセル用
};

/**
 * 帳票アップロードに使う帳票のファイルの拡張子
 */
export const BILLING_FILE_EXTENSION = {
  PDF: 0, // pdf
  XLSX: 1, // エクセル
};

export const BILLING_JOB_LIST = {
  SEND_BILLING_MAIL_JOB: 'sendBillingMailJob', // メール送信ジョブ
  GET_HOLIDAY_JOB: 'getHolidayJob', // 祝祭日管理
  RECREATE_BILLING_DATA_BATCH: 'reCreateBillingDataBatch', // 請求書再作成
};

export const BILLING_APPROVER_ROLE_NAME_ARRAY = ['営業上長'];
