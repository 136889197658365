import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { Url } from '../../../constants/Url';
import { Badge } from '../../atoms/Badge';
import type { BannerType } from '../../pages/Banner/BannerListPage';
import { when } from '../../../utils/functions';

export interface Props {
  listPerPage: any[];
  setListPerPage: any;
  transitionParam: { bannerType?: BannerType; categoryId?: number };
}

export const BannerListTable: React.VFC<Props> = ({ listPerPage, setListPerPage, transitionParam }) => {
  const history = useHistory();
  return (
    <div style={{ width: 'calc(100vw - 21.5rem)', overflowX: 'scroll' }} className="mb-4">
      <Table>
        <thead>
          <tr className="text-nowrap">
            <th> </th>
            <th>ID</th>
            <th className="text-center" style={{ width: '130px' }}>
              バナー表示状態
            </th>
            <th>広告名称</th>
            <th>表示種類</th>
            <th>表示ID／URL</th>
            <th>表示テキスト</th>
            <th>画像</th>
            <th>表示期間</th>
            <th>エリア設定</th>
            <th>性別設定</th>
            <th>年齢設定</th>
            <th>距離設定</th>
            <th>有効／無効</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {listPerPage.map((item, i) => (
            <tr key={item.id} className="text-nowrap align-middle">
              <td>
                <Form.Check id={item.id} className="d-flex">
                  <Form.Check.Input
                    className="flex-shrink-0 me-2"
                    type="checkbox"
                    onChange={() =>
                      setListPerPage(listPerPage.map((c, ci) => (i === ci ? { ...c, checked: !item.checked } : c)))
                    }
                    checked={item.checked}
                  />
                </Form.Check>
              </td>
              <td>{item.id}</td>
              <td>
                {item.bannerViewStatus === '表示中' ? (
                  <Badge bg="success" pill style={{ fontSize: '0.875rem', width: '100%' }}>
                    {item.bannerViewStatus}
                  </Badge>
                ) : (
                  item.bannerViewStatus
                )}
              </td>
              <td>{item.title}</td>
              <td>{item.type}</td>
              <td>
                {Number.isNaN(Number(item.idURL)) ? (
                  <a href={item.idURL} target="_blank" rel="noreferrer">
                    {item.idURL}
                  </a>
                ) : (
                  <span>{item.idURL}</span>
                )}
              </td>
              <td>{item.text}</td>
              <td>{when(item.imageURL, <img src={item.imageURL} style={{ width: '120px' }} alt="banner" />)}</td>
              <td>{item.period}</td>
              <td>{item.erea}</td>
              <td>{item.gender}</td>
              <td>{item.age}</td>
              <td>{item.distance}</td>
              <td>{item.validFlg ? '有効' : '無効'}</td>
              <td>
                <div>
                  <OverlayTrigger
                    placement="top-end"
                    overlay={
                      <Popover id="popover-basic">
                        <Popover.Body className="p-1">編集</Popover.Body>
                      </Popover>
                    }
                  >
                    <a
                      href={`${Url.KEISAI.BANNER_ITEM_MODIFY}/${item.id}/${transitionParam.bannerType?.id}/${
                        transitionParam.categoryId ?? ''
                      }`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faPen} className="text-secondary flex-shrink-0 me-1" fixedWidth />
                    </a>
                  </OverlayTrigger>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
