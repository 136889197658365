import React, { useCallback } from 'react';
import {
  BulkRejectApi,
  DisplayPointOutputResponse,
  EnqueteAnswerImageListFormResponse,
  EnqueteAnswerListFormResponse,
  ImageInfoForCorrectOutputResponse,
} from '../../../../../../../api-client';
import { ImageLens } from '../../../../../ImageLens';
import { DuplicateImages } from '../parts/DuplicateImages';
import { useSafeContext } from '../../../../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../../../../store/correctionStore';

export const ImageQuestion: React.FC<{
  imageUrlList: ImageInfoForCorrectOutputResponse[];
  displayPoint: DisplayPointOutputResponse;
  answerId?: number;
}> = ({ imageUrlList, displayPoint, answerId }) => {
  const {
    largeState: { state: $, mergeState },
  } = useSafeContext(CorrectionContext);

  /**
   * 画像が回転した場合
   */
  const handleChangeRotate = useCallback(
    (imageId: number, rotate: number) => {
      if (answerId === undefined) return; // ここは通らない
      if ($.imageForms?.editImageIds === undefined) {
        mergeState({
          imageForms: {
            editImageIds: [...[], { answerId, enqueteAnswerImageListForm: [{ imageId, rotate }] }],
          },
        });
      } else {
        const sameAnswer = $.imageForms.editImageIds.find((v) => v.answerId === answerId);
        if (sameAnswer === undefined) {
          mergeState({
            imageForms: {
              editImageIds: [
                ...$.imageForms?.editImageIds,
                { answerId, enqueteAnswerImageListForm: [{ imageId, rotate }] },
              ],
            },
          });
        } else {
          const sameImage = sameAnswer.enqueteAnswerImageListForm?.find((v) => v.imageId === imageId);
          if (sameImage === undefined) {
            mergeState({
              imageForms: {
                editImageIds: $.imageForms.editImageIds.map((v) => {
                  return {
                    ...v,
                    enqueteAnswerImageListForm: [...v!.enqueteAnswerImageListForm!, { imageId, rotate }],
                  };
                }),
              },
            });
          } else {
            // 同一画像が回転した
            mergeState({
              imageForms: {
                editImageIds: $.imageForms.editImageIds.map((v) => {
                  return {
                    ...v,
                    enqueteAnswerImageListForm: v.enqueteAnswerImageListForm?.map((vv) => {
                      if (vv.imageId === imageId) {
                        return { imageId, rotate };
                      }
                      return vv;
                    }),
                  };
                }),
              },
            });
          }
        }
      }
    },
    [imageUrlList]
  );

  if (imageUrlList.length === 0) return <strong style={{ color: 'red' }}>画像提出なし</strong>;
  const list: {
    imageId: number;
    exifInfo?: string;
    imageUrl: string;
    rotate: number;
    make?: string;
    model?: string;
    software?: string;
    distance?: number;
    takenTimestamp?: string;
  }[] = [];
  imageUrlList.map((v) => {
    list.push({
      imageId: v.imageId,
      exifInfo: undefined,
      imageUrl: v.url,
      rotate: v.rotate,
      make: v.make,
      model: v.model,
      software: v.software,
      distance: v.distance,
      takenTimestamp: v.takenTimestamp,
    });
  });

  return (
    <>
      <ImageLens
        list={list}
        linkType="PAGE"
        handleChangeRotate={handleChangeRotate}
        changedRotateState={$.imageForms?.editImageIds}
      />
      <DuplicateImages displayPoint={displayPoint} />
    </>
  );
};
