import { AxiosError } from 'axios';
import { History } from 'history';
import React, { useCallback } from 'react';
import type {
  NgAndOtherHistoryDetailOutputResponse,
  ApplyReceiptCheckOutputResponse,
  CorrectionNGListOutputResponse,
  DisplayPointOutputResponse,
  EnqueteDisplayOutputResponse,
  EscalationInfoDisplayOutputResponse,
  IncResultOutputResponse,
  MonitorRuleForCorrectionPageDtoResponse,
  NormalCorrectApplyDistributeOutputResponse,
  NoteListOutputResponse,
  ReceiptInfoDisplayOutputResponse,
  SelectionInfoForCorrectOutputResponse,
} from '../../../api-client';
import { Url } from '../../../constants/Url';
import {
  CommentFormState,
  CorrectionPageState,
  CorrectionPageState as State,
  ReceiptOkForm,
} from '../../../store/correctionStore';
import { debugLog, deepEqual } from '../../../utils/functions';
import {
  CanCorrectType,
  CorrectionTargetType,
  DisplayPointType,
  MONITOR_RULE_TYPES,
  TemplateFormBodyState,
  TemplateFormMode,
} from './constants';
import {
  EnqueteCorrectDisplayControlOutputResponse,
  ReceiptCorrectDisplayControlOutputResponse,
} from '../../../api-client/domains';

/**
 * チェック項目の表示用番号を出力する
 * @param idno receiptCheckItemId または questionNo
 * @returns 表示用の番号
 */
const checkNo = (idno: number): number => idno + 1;

/** チェック項目・設問の表記を取得 */
export const getCheckListSectionlabel = (
  isEnquete: boolean,
  no?: number,
  title?: string,
  selections?: SelectionInfoForCorrectOutputResponse[]
): string =>
  `${isEnquete ? '設問No' : ''}${no !== undefined && no !== null ? `${checkNo(no)}. ` : ''}${title ?? ''}${
    selections !== undefined && selections.length > 0 ? `(${selections.length}段階)` : ''
  }`;

/** モニタールールの名称を取得 */
export const getMonitorRuleName = (monitorRule?: MonitorRuleForCorrectionPageDtoResponse): string =>
  monitorRule
    ? Object.values(MONITOR_RULE_TYPES).find(({ code }) => code === monitorRule?.type)?.displayName ?? ''
    : '';

/** チェック項目・設問・モニタールールにあるNG添削・事務所へ質問フォームの内容取得 */
export const getCommonCommentFormList = <
  T extends {
    name: string | undefined;
    tempId: number;
    content: string;
    dp: Partial<DisplayPointOutputResponse>;
  }
>(
  mode: TemplateFormMode.NG | TemplateFormMode.ESCALE,
  state: State
): T[] => {
  const applyReceiptCheckList = state.res_correctionCheckDisplay?.applyReceiptCheckList ?? [];
  const enqueteDisplay = state.res_enqueteDisplay ?? [];
  const monitorRuleList = state.res_correctionCheckDisplay?.monitorRuleList ?? [];
  const forms = mode === TemplateFormMode.NG ? state.commentForms_ng : state.commentForms_escale;
  return (forms ?? [])
    .map(({ dp, tempId, content }) => {
      if (dp.type === DisplayPointType.APPLY_RECEIPT_CHECK_POINT) {
        const data = applyReceiptCheckList.find((x) => x.receiptCheckItemId === dp.targetId);
        return {
          name: getCheckListSectionlabel(false, data?.receiptCheckItemId ?? NaN, data?.checkItemContents),
          tempId,
          content,
          dp,
        };
      }
      // commentFormsはANSWERに対して送信する仕様のため、QUESTIONは不要。設問番号を表示したいのでquestionNoを渡している
      if (dp.type === DisplayPointType.ANSWER) {
        const data = enqueteDisplay.find((x) => x.answerId === dp.targetId);
        return {
          name: getCheckListSectionlabel(true, data?.questionNo ?? NaN, data?.content),
          tempId,
          content,
          dp,
        };
      }
      if (dp.type === DisplayPointType.MONITOR_RULE) {
        return {
          name: getMonitorRuleName(monitorRuleList.find((x) => x.monitorRuleId === dp.targetId)),
          tempId,
          content,
          dp,
        };
      }
      return undefined;
    })
    .filter(Boolean) as T[];
};

/** `window.alert`ラッパー */
export const nativeAlert = (error: unknown): void => {
  if (typeof error === 'string') {
    const e = error as string;
    // eslint-disable-next-line no-alert
    window.alert(e);
    return;
  }
  const e = error as AxiosError<IncResultOutputResponse>;
  // eslint-disable-next-line no-alert
  window.alert(`エラーが発生しました。エラーコード :${e.response?.data.errorCode ?? e.code}`);
};

/** `window.alert`ラッパー */
export const nativeAlertWithErrorMessage = (error: unknown): void => {
  if (typeof error === 'string') {
    const e = error as string;
    // eslint-disable-next-line no-alert
    window.alert(e);
    return;
  }
  const e = error as AxiosError<IncResultOutputResponse>;
  // eslint-disable-next-line no-alert
  window.alert(
    `エラーが発生しました。\nエラーコード :${e.response?.data.errorCode ?? e.code}\nエラーメッセージ：${
      e.response?.data.errorMessage ?? e.message
    }`
  );
};

/** `window.confirm`ラッパー */
export const nativeConfirm = (text: string): boolean => {
  // eslint-disable-next-line no-alert
  return window.confirm(text);
};

/** 画面遷移制御 */
export const jumpTo = (
  history: History<unknown>,
  applyDistribute: NormalCorrectApplyDistributeOutputResponse
): void | never => {
  const { applyId, correctionType } = applyDistribute;
  let url = '';
  switch (correctionType) {
    case CorrectionTargetType.RECEIPT:
      url = `${Url.TENSAKU.CORRECT_RECEIPT}/${applyId}`;
      break;
    case CorrectionTargetType.DELIVERY_SLIP:
      url = `${Url.TENSAKU.CORRECT_DELIVERY_SLIP}/${applyId}`;
      break;
    case CorrectionTargetType.ENQUETE:
      url = `${Url.TENSAKU.CORRECT_ENQUETE}/${applyId}`;
      break;
    case CorrectionTargetType.RESUBMIT_ENQUETE:
      url = `${Url.TENSAKU.RESUBMIT_ENQUETE}/${applyId}`;
      break;
    case CorrectionTargetType.RECEIPT_DATA_INPUT:
      url = `${Url.TENSAKU.CORRECT_DATA_WCHECK}/${applyId}`;
      break;
    default:
      break;
  }
  if (url) {
    if (url === window.location.pathname) {
      debugLog('reload...');
      return document.location.reload();
    }
    debugLog('jump...');
    const prevPathname = window.location.pathname;
    history.push(url);
    if (window.location.pathname !== prevPathname) {
      return document.location.reload();
    }
  }
  debugLog('jump error');
  throw new Error('無効なcorrectionType');
};

/** エラーページに遷移 */
export const jumpToError = (history?: History<unknown>): void => {
  if (history?.push) {
    history.push(Url.COMMON_ERROR);
    return;
  }
  document.location.href = Url.COMMON_ERROR;
};

export const noCorrectionTarget = (history?: History<unknown>): void => {
  nativeConfirm('対象添削物がありません。お疲れ様でした');
  if (history?.push) {
    history.push(Url.MY);
  }
};

/** 送信確認ダイアログを挟んで通信 */
export const questionPreSend = async <T>(
  preload: () => T | Promise<T>,
  process: (res: T) => Promise<void>,
  question = '送信しますか？',
  setLoading?: (e: boolean) => void
): Promise<boolean> => {
  try {
    const res = await preload();
    if (!nativeConfirm(question)) {
      return false;
    }
    setLoading?.(true);
    await process(res);
    setLoading?.(false);
    return true;
  } catch (reason) {
    const text = '送信を中断しました';
    nativeAlert(text);
    console.error(text, { reason });
    setLoading?.(false);
    return false;
  }
};

/** CommentFormStateのDisplayPointが同値かどうか調べる */
export const equalDp =
  (dp: Partial<DisplayPointOutputResponse> | undefined) =>
  (f: CommentFormState): boolean =>
    deepEqual(f.dp, dp ?? {});

/** CommentEditor系の入力結果を配列としてmergeStateするためのhooks */
export const useMergeStateOfCommentEditors = <N extends Extract<keyof CorrectionPageState, `commentForms_${string}`>>(
  dp: Partial<DisplayPointOutputResponse> | undefined,
  setState: React.Dispatch<React.SetStateAction<CorrectionPageState>>
): ((state: TemplateFormBodyState | undefined, arrayName: N) => void) => {
  return useCallback(
    (state: TemplateFormBodyState | undefined, arrayName: N) => {
      setState((prev: CorrectionPageState): CorrectionPageState => {
        if (!dp) return prev;
        const forms = prev[arrayName] ?? [];
        const form = forms.find(equalDp(dp));
        if (!state) {
          const index = forms.findIndex(equalDp(dp));
          if (index === -1) return prev;
          return {
            ...prev,
            [arrayName]: [...forms.slice(0, index), ...forms.slice(index + 1)],
          };
        }

        const content = state?.content ?? '';
        const tempId = state?.templateId ?? NaN;
        const shopReviewDisplayFlg = state?.shopReviewDisplayFlg;
        if (form) {
          if (shopReviewDisplayFlg === undefined) {
            // 回答保存されたとき
            form.content = content;
            form.tempId = tempId;
          } else {
            // 店舗レビューの表示非表示が切り替わった場合
            form.shopReviewDisplayFlg = shopReviewDisplayFlg;
          }
          return {
            ...prev,
            [arrayName]: forms,
          };
        }
        return {
          ...prev,
          [arrayName]: [
            ...forms,
            {
              dp,
              content,
              tempId,
              shopReviewDisplayFlg,
            },
          ],
        };
      });
    },
    [dp, setState]
  );
};

/** DisplayPointから各データを取得 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const dpToData = (targetDp: Partial<DisplayPointOutputResponse> | undefined) => {
  const filteringFromDp = <
    D extends Partial<DisplayPointOutputResponse>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    L extends { displayPoint?: D; dp?: D; [key: string]: any }
  >(
    list: L[] | undefined
  ) =>
    (list ?? []).filter((el) => {
      const eldp = el.displayPoint ?? el.dp;
      if (!eldp) return false;
      if (targetDp?.type === DisplayPointType.FOR_ALL) {
        return eldp.type === DisplayPointType.FOR_ALL;
      }
      return eldp.type === targetDp?.type && eldp.targetId === targetDp?.targetId;
    });
  return {
    toMeta: <
      R extends ApplyReceiptCheckOutputResponse,
      E extends EnqueteDisplayOutputResponse,
      C extends Partial<R & E>
    >(
      checkList?: (R | E)[]
    ) => {
      const list = (checkList ?? []) as unknown as C[];
      const el = list.find((x) => (x.receiptCheckItemId ?? x.questionId) === targetDp?.targetId);
      return {
        id: el?.receiptCheckItemId ?? el?.questionId,
        // ラベルのナンバリング用
        no: el?.receiptCheckItemId ?? el?.questionNo ?? NaN,
        title: el?.checkItemContents ?? el?.content,
        supplementUrl: el?.supplementUrl ?? [],
      };
    },
    toMonitorRule: (monitorRuleList?: MonitorRuleForCorrectionPageDtoResponse[]) => {
      const monitorRule = (monitorRuleList ?? []).find((x) => x.monitorRuleId === targetDp?.targetId);
      return {
        ...monitorRule,
        name: getMonitorRuleName(monitorRule),
      };
    },
    toNote: (noteList?: NoteListOutputResponse[]) => filteringFromDp(noteList),
    toNGHistories: (ngList?: CorrectionNGListOutputResponse[]) => filteringFromDp(ngList),
    toNGHistoryDetails: (detailList?: NgAndOtherHistoryDetailOutputResponse[]) => filteringFromDp(detailList),
    toCommentForm: (commentForms?: CommentFormState[]) => filteringFromDp(commentForms),
    toEscale: (escaleList?: EscalationInfoDisplayOutputResponse[]) => filteringFromDp(escaleList),
  };
};

/** レシート添削OKのフォーム用初期値を取得 */
export const createReceiptOkInitialForm = (
  receiptInfoDisplay: ReceiptInfoDisplayOutputResponse | undefined,
  checkResults: {
    tell: CorrectionPageState['receiptForm_tellCheck'];
    date: CorrectionPageState['receiptForm_dateCheck'];
    receipt: CorrectionPageState['receiptForm_receiptCheck'];
  }
): ReceiptOkForm => {
  const {
    // tell,
    date,
    receipt,
  } = checkResults;
  if (!receiptInfoDisplay) return {};
  const [, initPurchaseDate, initPurchaseTime] =
    receiptInfoDisplay?.purchaseAt?.match(/(\d+-\d+-\d+) (\d+:\d+):\d+/) ?? [];
  const [purchaseDate, noPurchaseTimeFlag, purchaseTime] = [
    date?.date ?? initPurchaseDate,
    !!date?.checkbox,
    date?.time ?? initPurchaseTime,
  ];
  return {
    ...receiptInfoDisplay,
    purchaseDate,
    noPurchaseTimeFlag,
    purchaseTime,
    ...(receipt
      ? {
          noReceiptNoFlag: receipt.checkbox,
          receiptNo: receipt.receiptNo,
        }
      : {}),
  };
};

/** 日付文字列に変換 */
export const toAt = (date?: string, timeFlag?: boolean, time?: string): string | undefined =>
  date ? new Date(`${date}${!timeFlag && time ? ` ${time}` : ' 00:00:00'}` ?? '').toISOString() : undefined;

/**
 * 強制添削の判定処理
 */
export const isForceCorrect = (
  res_correctReceiptDisplayControl?: ReceiptCorrectDisplayControlOutputResponse,
  res_correctEnqueteDisplayControl?: EnqueteCorrectDisplayControlOutputResponse
): boolean => {
  const correrctControll = res_correctReceiptDisplayControl || res_correctEnqueteDisplayControl;
  // 普通の添削の場合はfalse
  if (correrctControll!.correctionExec === CanCorrectType.CORRECTING) return false;

  // 強制添削ができない状態でここまで来た場合（ないはず）、エラーとする
  if (!correrctControll!.canForceCorrect) throw new Error('強制添削権限が不可能なため');

  // 強制添削を選択した場合はtrueを返す
  if (nativeConfirm('強制添削をしてもよろしいですか？')) return true;
  // 強制添削を選択しなかった場合はエラーを返す
  throw new Error('強制添削しかできないステータスです');
};

export const arrayEqual = (a: [], b: []): boolean => {
  if (a.length !== b.length) return false;
  a.forEach((value, index) => {
    if (value !== b[index]) return false;
  });
  return true;
};
